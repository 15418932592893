import { TAlertInput } from '../../generated/graphql';
import { TGiftType } from '../../entities/gifts/queries';
import _ from 'lodash';
import { TShipmentType } from '../../entities/shipments/queries';
import style from './AlertDismissButton.module.scss';
import clsx from 'clsx';
import { Icon } from '@chocolate-soup-inc/cs-frontend-components';
import { toast } from 'react-toastify';

type AlertDismissButtonProps = {
  entity: TGiftType | TShipmentType;
  message: string;
  userEmail: string;
  updateMutation: any;
};

export const AlertDismissButton = ({ entity, message, userEmail, updateMutation }: AlertDismissButtonProps) => {
  if (message === 'Recipient was removed.') return <></>;

  const parsedAlerts: TAlertInput[] = _.compact(entity?.alerts).reduce((acc: TAlertInput[], alert: TAlertInput) => {
    const newAlert = _.omit(alert, ['__typename', 'createdAt']) as TAlertInput;

    if (alert.message === message && (!alert.acknowledgedAt || !alert.acknowledgedBy))
      acc.push({
        ...newAlert,
        acknowledgedBy: userEmail,
        acknowledgedAt: new Date().toISOString(),
      });
    else acc.push(newAlert);

    return acc;
  }, []);

  const dismissHandler = async () => {
    try {
      await updateMutation({
        variables: {
          id: entity.id,
          companyId: entity.companyId,
          version: entity._version,
          input: {
            alerts: parsedAlerts,
          },
        },
      });
      toast.success(`Alert "${message}" dismissed.`, { autoClose: 3000 });
    } catch (e) {
      toast.error(`Alert "${message}" dismissal failed.`, { autoClose: 3000 });
      console.warn(`Alert "${message}" dismissal failed.`, e);
    }
  };

  return (
    <Icon
      icon='close'
      opticalSize={20}
      weight={200}
      className={clsx(style.alertDismissButton)}
      onClick={dismissHandler}
    />
  );
};
