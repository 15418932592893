import {
  ModalForm,
  TControlledFormComponentProps,
  TControlledFormProps,
} from '@chocolate-soup-inc/cs-frontend-components';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import _ from 'lodash';
import { useMemo, useCallback } from 'react';
import { toast } from 'react-toastify';
import { serializeError } from 'serialize-error';
import { AWSDateSchema } from '../../../entities/shared/utils';
import { TPrintShipmentLabelMutationInput, usePrintShipmentLabelMutation } from '../../../generated/graphql';

import styles from './PrintLabelModal.module.scss';
import { TShipmentType } from '../../../entities/shipments/queries';
import { getDefaultCustoms } from './customs';

export type TPrintLabelModalProps = {
  shipments: TShipmentType[];
  onCancel: () => void;
  onSuccess: () => void;
};

type TTrailingTextProps = {
  text: string;
};

const TrailingText = (props: TTrailingTextProps) => {
  const { text } = props;

  return <span className={styles.trailingText}>{text}</span>;
};

export const PrintLabelModal = (props: TPrintLabelModalProps) => {
  const { shipments, onCancel, onSuccess } = props;

  const isInternational = useMemo(() => {
    return (
      _.compact(shipments.map((shipment) => shipment.address?.country?.toLowerCase())).findIndex((c) => c !== 'can') >
      -1
    );
  }, [shipments]);

  const defaultCustoms = useMemo(() => {
    if (shipments && shipments.length > 0) {
      return getDefaultCustoms(shipments);
    }
  }, [shipments]);

  const formFields: TControlledFormComponentProps[] = useMemo(() => {
    const fields: TControlledFormComponentProps[] = [
      {
        type: 'datePicker',
        label: 'Shipping Date',
        name: 'actualShippingDate',
      },
    ];

    if (isInternational) {
      fields.push({
        type: 'fieldArray',
        name: 'customsData',
        addLabel: 'Add Customs Item',
        fields: [
          {
            type: 'textField',
            label: 'Value',
            name: 'value',
            inputOptions: {
              autoComplete: 'off',
              className: styles.numberInput,
              multiline: false,
              trailingIcon: <TrailingText text='CAD' />,
              type: 'number',
            },
          },
          {
            type: 'textField',
            label: 'HS Code',
            name: 'hsCode',
            inputOptions: {
              autoComplete: 'off',
              mask: ['0000{.}00'],
              multiline: false,
            },
          },
          {
            type: 'textField',
            label: 'Quantity',
            name: 'quantity',
            inputOptions: {
              autoComplete: 'off',
              className: styles.numberInput,
              multiline: false,
              type: 'number',
            },
          },
          {
            type: 'textField',
            label: 'Description',
            name: 'description',
            inputOptions: {
              multiline: false,
              autoComplete: 'off',
            },
          },
          {
            type: 'countrySelect',
            label: 'Country',
            name: 'country',
            inputOptions: {
              autoComplete: 'off',
              variant: 'outlined',
            },
          },
        ],
        label: 'Customs Item',
        removeLabel: 'Remove Customs Item',
      });
    }

    return fields;
  }, [isInternational]);

  const schema = useMemo(() => {
    return Joi.object()
      .unknown(false)
      .keys({
        actualShippingDate: AWSDateSchema.required(),
        customsData: !isInternational
          ? Joi.forbidden()
          : Joi.array()
              .items(
                Joi.object()
                  .unknown(false)
                  .keys({
                    value: Joi.number().required(),
                    hsCode: Joi.string()
                      .custom((value, helpers) => {
                        const regex = /^\d{4}\.\d{2}$/;

                        if (!regex.test(value)) {
                          return helpers.message({ custom: 'HS Code must be 0000.00' });
                        }
                        return value;
                      })
                      .required(),
                    quantity: Joi.number().required(),
                    description: Joi.string().required(),
                    country: Joi.string().required(),
                  }),
              )
              .required()
              .min(1),
      });
  }, [isInternational]);

  const headline = useMemo(() => {
    return `Print Label for ${shipments.length} Shipment${shipments.length === 1 ? '' : 's'}`;
  }, [shipments.length]);

  const supportingText = useMemo(() => {
    return `Set the actual shipping date ${isInternational ? 'and customs data' : ''} for the ${
      shipments.length
    } shipment${shipments.length === 1 ? '' : 's'}.`;
  }, [isInternational, shipments.length]);

  const [printLabel] = usePrintShipmentLabelMutation();

  const onSubmit = useCallback<TControlledFormProps<TPrintShipmentLabelMutationInput>['onValidSubmit']>(
    (data) => {
      return Promise.all(
        shipments.map((shipment) => {
          return printLabel({
            variables: {
              id: shipment.id,
              companyId: shipment.companyId,
              version: shipment._version,
              input: data,
            },
          });
        }),
      )
        .then(() => {
          if (onSuccess) onSuccess();
        })
        .catch((error) => {
          console.error(serializeError(error));
          toast.error('There was an error printing the shipment labels.');
        });
    },
    [onSuccess, printLabel, shipments],
  );

  const defaultValues = useMemo(() => {
    if (defaultCustoms && isInternational) {
      return {
        customsData: defaultCustoms,
      };
    }
  }, [defaultCustoms, isInternational]);

  return (
    <ModalForm
      closeModal={onCancel}
      confirmLabel='Print Label'
      contentClassName={styles.setAsReadyToShipModal}
      controlledFormProps={{
        className: styles.form,
        fields: formFields as TControlledFormComponentProps<TPrintShipmentLabelMutationInput>[],
        formProps: {
          defaultValues,
          resolver: joiResolver(schema, {
            convert: true,
            abortEarly: false,
            stripUnknown: false,
          }),
        },
        onValidSubmit: onSubmit,
        onInvalidSubmit: (errors) => {
          console.error(errors);
          for (const { message } of Object.values(errors)) {
            if (message) {
              toast.error(message);
            }
          }
        },
      }}
      headline={headline}
      onCancelClick={onCancel}
      size='large'
      supportingText={supportingText}
    />
  );
};
