import { useCallback, useMemo, useState } from 'react';
import { DatePicker, Loading, Modal, Select, TextField } from '@chocolate-soup-inc/cs-frontend-components';
import styles from './Form.module.scss';
import { ApolloError } from '@apollo/client/errors';
import { toast } from 'react-toastify';
import { generatePath, useNavigate } from 'react-router-dom';
import { EVENTS_PATH, ONE_OFF_EVENTS_PATH, UPCOMING_PATH } from '../../../../routes/paths';
import { TEventTypes, useCreateEventMutation } from '../../../../generated/graphql';
import { getEventDefaultValues } from '../../../../entities/events/schema';
import { useQueryAllCompanies } from '../../../../entities/companies/queries';
import { useQueryAllEmployees } from '../../../../entities/employees/queries';
import format from 'date-fns/format';
import { useGetRecipientAddressId } from '../../../../entities/recipients/shared';
import { ONE_OFF_EVENT_TYPES } from '../../../../entities/events/shared';

export const EventCreateForm = () => {
  const [selectedCompanyId, setSelectedCompanyId] = useState<string | undefined>();
  const [selectedEmployeeId, setSelectedEmployeeId] = useState<string | undefined>();
  const [typeSelect, setTypeSelect] = useState<TEventTypes | undefined>();
  const [typeText, setTypeText] = useState<string | undefined>();
  const [selectedEventDate, setSelectedEventDate] = useState<Date | undefined>(new Date());

  const navigate = useNavigate();
  const [createEvent] = useCreateEventMutation();

  const { data: companies, loading: companiesLoading } = useQueryAllCompanies();
  const { data: employees, loading: employeesLoading } = useQueryAllEmployees();

  const companiesList = useMemo(
    () =>
      companies
        ?.filter((company) => {
          const hasReachedEndDate =
            company.endDate && new Date(company.endDate as string).getTime() < new Date().getTime();
          return company.active && company.isLive && !hasReachedEndDate;
        })
        .map((company) => ({ label: company?.name, value: company?.id })),
    [companies],
  );
  const employeesList = useMemo(
    () =>
      employees
        ?.filter((employee) => employee?.companyId === selectedCompanyId && employee.active)
        ?.map((employee) => ({ label: employee?.fullName as string, value: employee?.id as string })),
    [employees, selectedCompanyId],
  );
  const fullSelectedCompany = useMemo(
    () => companies?.find((company) => company.id === selectedCompanyId),
    [companies, selectedCompanyId],
  );
  const fullSelectedEmployee = useMemo(
    () => employees?.find((employee) => employee.id === selectedEmployeeId),
    [employees, selectedEmployeeId],
  );

  const selectedAddressId = useGetRecipientAddressId({
    deliveryMethod: fullSelectedCompany?.deliveryMethod,
    companyId: fullSelectedCompany?.id ?? '',
    employeeId: fullSelectedEmployee?.id ?? '',
    officeId: fullSelectedEmployee?.officeId as string,
  });

  const closeModal = useCallback(() => {
    navigate(generatePath(`${EVENTS_PATH}/${UPCOMING_PATH}/${ONE_OFF_EVENTS_PATH}`));
  }, [navigate]);

  const onSubmit = useCallback(async () => {
    if (!selectedCompanyId || !selectedEmployeeId || !selectedEventDate || !typeSelect) return;

    const input = {
      ...getEventDefaultValues(),
      companyId: selectedCompanyId,
      employeeId: selectedEmployeeId,
      eventDate: format(selectedEventDate, 'yyyy-MM-dd'),
      type: typeSelect,
      customType: typeText,
      addressId: selectedAddressId,
      recipientId: `${process.env.REACT_APP_EMPLOYEES_TABLE_NAME}|${selectedEmployeeId}`,
      referenceEventId: null,
    };

    await createEvent({
      variables: {
        input,
      },
    })
      .then(() => {
        toast.success('Successfully created the event.');
        closeModal();
      })
      .catch((error: ApolloError) => {
        toast.error('There was an error creating the event.');
        error.graphQLErrors.forEach((err) => toast.error(err.message));
      });
  }, [
    closeModal,
    createEvent,
    selectedEmployeeId,
    typeSelect,
    typeText,
    selectedCompanyId,
    selectedEventDate,
    selectedAddressId,
  ]);

  if (companiesLoading || employeesLoading) return <Loading />;

  return (
    <Modal
      closeModal={closeModal}
      confirmLabel='Create'
      headline='New One-Off Event'
      onConfirmClick={onSubmit}
      confirmDisabled={
        !selectedEmployeeId || !typeSelect || (typeSelect === TEventTypes.Other && !typeText) || !selectedEventDate
      }
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      showConfirmButton={true}
      size='large'
    >
      <p className={styles.supportText}>Select the Employee whose event is going to be created.</p>
      <div className={styles.filters}>
        <Select
          className={styles.filter}
          label='Company'
          multiple={false}
          name='companyId'
          onChange={(v) => {
            setSelectedCompanyId(v as string);
            setSelectedEmployeeId(undefined);
          }}
          options={companiesList}
          value={selectedCompanyId}
          variant='outlined'
        />
        <Select
          className={styles.filter}
          label='Employee'
          multiple={false}
          disabled={!selectedCompanyId}
          name='employeeId'
          onChange={(v) => {
            setSelectedEmployeeId(v as string);
          }}
          options={employeesList}
          value={selectedEmployeeId}
          variant='outlined'
        />
      </div>
      <div className={styles.filters}>
        <Select
          className={styles.filter}
          label='Event Type'
          multiple={false}
          name='type'
          onChange={(v) => {
            setTypeSelect(v as TEventTypes);
            setTypeText(undefined);
          }}
          options={Object.entries(ONE_OFF_EVENT_TYPES).map(([key, value]) => ({ label: value, value: key }))}
          value={typeSelect}
          variant='outlined'
        />
        {typeSelect === TEventTypes.Other && (
          <TextField
            className={styles.filter}
            disabled={typeSelect !== TEventTypes.Other}
            label='Custom Event Type'
            multiline={false}
            name='typeCustom'
            onChange={(v) => {
              setTypeText(v as string);
            }}
            value={typeText}
            variant='outlined'
          />
        )}
      </div>
      <div className={styles.filters}>
        <DatePicker
          label='Event Date'
          className={styles.filter}
          name='eventDate'
          onChange={(v) => {
            if (v !== null) setSelectedEventDate(v);
          }}
          value={selectedEventDate}
          variant='outlined'
        />
      </div>
    </Modal>
  );
};
