import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSDate: string;
  AWSDateTime: string;
  AWSEmail: string;
  AWSIPAddress: string;
  AWSJSON: string;
  AWSPhone: string;
  AWSTime: string;
  AWSTimestamp: number;
  AWSURL: string;
};

export type TAddress = {
  __typename?: 'Address';
  _deleted?: Maybe<Scalars['Boolean']>;
  _lastChangedAt?: Maybe<Scalars['AWSTimestamp']>;
  _version: Scalars['Int'];
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  addressFromGoogle?: Maybe<TAddressFromGoogle>;
  alert?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  company?: Maybe<TCompany>;
  companyId: Scalars['ID'];
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  employee?: Maybe<TEmployee>;
  googleValidated?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  invalid?: Maybe<Scalars['Boolean']>;
  missingInfo?: Maybe<Scalars['Boolean']>;
  office?: Maybe<TOffice>;
  parentType: TAddressParentTypes;
  state?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
  useGoogleAddress?: Maybe<Scalars['Boolean']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type TAddressFromGoogle = {
  __typename?: 'AddressFromGoogle';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export enum TAddressParentTypes {
  Company = 'company',
  Employee = 'employee',
  Office = 'office',
}

export type TAddressesConnection = {
  __typename?: 'AddressesConnection';
  items: Array<Maybe<TAddress>>;
  nextToken?: Maybe<Scalars['String']>;
  scannedCount?: Maybe<Scalars['Int']>;
};

export type TAlert = {
  __typename?: 'Alert';
  acknowledgedAt?: Maybe<Scalars['AWSDateTime']>;
  acknowledgedBy?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  message: Scalars['String'];
  newItem?: Maybe<Scalars['AWSJSON']>;
  oldItem?: Maybe<Scalars['AWSJSON']>;
};

export type TAlertInput = {
  acknowledgedAt?: InputMaybe<Scalars['AWSDateTime']>;
  acknowledgedBy?: InputMaybe<Scalars['String']>;
  message: Scalars['String'];
  newItem?: InputMaybe<Scalars['AWSJSON']>;
  oldItem?: InputMaybe<Scalars['AWSJSON']>;
};

export enum TCarriers {
  CanadaPost = 'canada_post',
  DhlExpressCanada = 'dhl_express_canada',
  PurolatorCa = 'purolator_ca',
  Ups = 'ups',
}

export type TCompaniesConnection = {
  __typename?: 'CompaniesConnection';
  items: Array<Maybe<TCompany>>;
  nextToken?: Maybe<Scalars['String']>;
  scannedCount?: Maybe<Scalars['Int']>;
};

export type TCompany = {
  __typename?: 'Company';
  _deleted?: Maybe<Scalars['Boolean']>;
  _lastChangedAt?: Maybe<Scalars['AWSTimestamp']>;
  _version: Scalars['Int'];
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<TAddress>;
  bambooHRAPIKey?: Maybe<Scalars['String']>;
  bambooHRPetsTableName?: Maybe<Scalars['String']>;
  bambooHRSubdomain?: Maybe<Scalars['String']>;
  childBirthdayActivated: Scalars['Boolean'];
  companyWideLegoCustomization?: Maybe<Scalars['String']>;
  configuration?: Maybe<TConfigurationType>;
  contact?: Maybe<TContact>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  customLego?: Maybe<Scalars['Boolean']>;
  customWorkAnniversaryNotes?: Maybe<Scalars['String']>;
  deliveryMethod: TDeliveryMethod;
  emailDomains: Array<Scalars['String']>;
  employeeBirthdayActivated: Scalars['Boolean'];
  employeeNewHireKitActivated: Scalars['Boolean'];
  employeeWorkAnniversaryActivated: Scalars['Boolean'];
  employees: TEmployeesConnection;
  endDate?: Maybe<Scalars['AWSDate']>;
  fullyConfigured?: Maybe<Scalars['Boolean']>;
  googleLoginEnabled?: Maybe<Scalars['Boolean']>;
  hasCustomOnboardBrick?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  importFromBambooHR: Scalars['Boolean'];
  isLive?: Maybe<Scalars['Boolean']>;
  legoActivated?: Maybe<Scalars['Boolean']>;
  legoColor?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['AWSURL']>;
  name: Scalars['String'];
  normalizedName?: Maybe<Scalars['String']>;
  offices: TOfficesConnection;
  oldSystemId?: Maybe<Scalars['Int']>;
  passwordLoginEnabled?: Maybe<Scalars['Boolean']>;
  petBirthdayActivated: Scalars['Boolean'];
  rosterId?: Maybe<Scalars['String']>;
  signAs?: Maybe<Scalars['String']>;
  significantOtherBirthdayActivated: Scalars['Boolean'];
  specialNote?: Maybe<Scalars['String']>;
  spreadsheetId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['AWSDate']>;
  subdomain: Scalars['String'];
  subscriptionPriceCurrency?: Maybe<Scalars['String']>;
  subscriptionPricePerUser?: Maybe<Scalars['Float']>;
  swag?: Maybe<Scalars['String']>;
  tissueColor: Scalars['String'];
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
  userPoolId?: Maybe<Scalars['String']>;
  users: TUsersConnection;
  workAnniversaryGiftVariation?: Maybe<TWorkAnniversaryGiftVariations>;
};

export type TCompanyEmployeesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TCompanyOfficesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TCompanyUsersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TConfigurationType = {
  __typename?: 'ConfigurationType';
  authCloudfrontDomain?: Maybe<Scalars['String']>;
  authUserPoolClientId?: Maybe<Scalars['String']>;
  authUserPoolClientName?: Maybe<Scalars['String']>;
  authUserPoolDomainURL?: Maybe<Scalars['String']>;
  authUserPoolId?: Maybe<Scalars['String']>;
  authUserPoolName?: Maybe<Scalars['String']>;
  frontendCertificateArn?: Maybe<Scalars['String']>;
  frontendDistributionArn?: Maybe<Scalars['String']>;
  frontendDistributionDomainName?: Maybe<Scalars['String']>;
  frontendDistributionId?: Maybe<Scalars['String']>;
};

export type TContact = {
  __typename?: 'Contact';
  email?: Maybe<Scalars['AWSEmail']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type TCreateAddressMutationInput = {
  address1?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['ID']>;
  country?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  useGoogleAddress?: InputMaybe<Scalars['Boolean']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type TCreateCompanyMutationInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  address?: InputMaybe<TCreateAddressMutationInput>;
  bambooHRAPIKey?: InputMaybe<Scalars['String']>;
  bambooHRPetsTableName?: InputMaybe<Scalars['String']>;
  bambooHRSubdomain?: InputMaybe<Scalars['String']>;
  childBirthdayActivated?: InputMaybe<Scalars['Boolean']>;
  companyWideLegoCustomization?: InputMaybe<Scalars['String']>;
  contact?: InputMaybe<TCreateContactMutationInput>;
  customLego?: InputMaybe<Scalars['Boolean']>;
  customWorkAnniversaryNotes?: InputMaybe<Scalars['String']>;
  deliveryMethod: TDeliveryMethod;
  emailDomains: Array<Scalars['String']>;
  employeeBirthdayActivated?: InputMaybe<Scalars['Boolean']>;
  employeeNewHireKitActivated?: InputMaybe<Scalars['Boolean']>;
  employeeWorkAnniversaryActivated?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['AWSDate']>;
  googleLoginEnabled?: InputMaybe<Scalars['Boolean']>;
  hasCustomOnboardBrick?: InputMaybe<Scalars['Boolean']>;
  importFromBambooHR?: InputMaybe<Scalars['Boolean']>;
  isLive?: InputMaybe<Scalars['Boolean']>;
  legoActivated?: InputMaybe<Scalars['Boolean']>;
  legoColor?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['AWSURL']>;
  name: Scalars['String'];
  oldSystemId?: InputMaybe<Scalars['Int']>;
  passwordLoginEnabled?: InputMaybe<Scalars['Boolean']>;
  petBirthdayActivated?: InputMaybe<Scalars['Boolean']>;
  rosterId?: InputMaybe<Scalars['String']>;
  signAs?: InputMaybe<Scalars['String']>;
  significantOtherBirthdayActivated?: InputMaybe<Scalars['Boolean']>;
  specialNote?: InputMaybe<Scalars['String']>;
  spreadsheetId?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['AWSDate']>;
  subdomain: Scalars['String'];
  subscriptionPriceCurrency?: InputMaybe<Scalars['String']>;
  subscriptionPricePerUser?: InputMaybe<Scalars['Float']>;
  swag?: InputMaybe<Scalars['String']>;
  tissueColor: Scalars['String'];
  workAnniversaryGiftVariation?: InputMaybe<TWorkAnniversaryGiftVariations>;
};

export type TCreateContactMutationInput = {
  email?: InputMaybe<Scalars['AWSEmail']>;
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type TCreateDependantMutationInput = {
  birthDate?: InputMaybe<Scalars['AWSDate']>;
  companyId?: InputMaybe<Scalars['ID']>;
  donateBirthdayGift?: InputMaybe<Scalars['Boolean']>;
  employeeId?: InputMaybe<Scalars['ID']>;
  firstName?: InputMaybe<Scalars['String']>;
  foodPreferences?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  oldSystemId?: InputMaybe<Scalars['Int']>;
  petType?: InputMaybe<Scalars['String']>;
  preferredFirstName?: InputMaybe<Scalars['String']>;
  type: TDependantType;
};

export type TCreateEmployeeMutationInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  address?: InputMaybe<TCreateAddressMutationInput>;
  birthDate?: InputMaybe<Scalars['AWSDate']>;
  companyId: Scalars['ID'];
  customOnboardBrick?: InputMaybe<Scalars['String']>;
  deliveryMethod?: InputMaybe<TDeliveryMethod>;
  dependants?: InputMaybe<Array<InputMaybe<TCreateDependantMutationInput>>>;
  donateBirthdayGift?: InputMaybe<Scalars['Boolean']>;
  donateWorkAnniversaryGift?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['AWSEmail']>;
  externalId?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  foodPreferences?: InputMaybe<Scalars['String']>;
  giftOnHoldFrom?: InputMaybe<Scalars['AWSDate']>;
  giftOnHoldTo?: InputMaybe<Scalars['AWSDate']>;
  giftPausedFrom?: InputMaybe<Scalars['AWSDate']>;
  giftPausedTo?: InputMaybe<Scalars['AWSDate']>;
  hireDate?: InputMaybe<Scalars['AWSDate']>;
  lastName?: InputMaybe<Scalars['String']>;
  officeId?: InputMaybe<Scalars['ID']>;
  oldSystemId?: InputMaybe<Scalars['Int']>;
  organization?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  preferredFirstName?: InputMaybe<Scalars['String']>;
  sweaterSize?: InputMaybe<Scalars['String']>;
  tShirtSize?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type TCreateEventGiftResponse = {
  __typename?: 'CreateEventGiftResponse';
  companyId: Scalars['ID'];
  event?: Maybe<TEvent>;
  gift?: Maybe<TGift>;
};

export type TCreateEventMutationInput = {
  addressId?: InputMaybe<Scalars['ID']>;
  age: Scalars['Int'];
  companyId: Scalars['ID'];
  customType?: InputMaybe<Scalars['String']>;
  deliveredAt?: InputMaybe<Scalars['AWSDateTime']>;
  employeeId: Scalars['ID'];
  eventDate: Scalars['AWSDate'];
  giftId?: InputMaybe<Scalars['ID']>;
  groupedToEventId?: InputMaybe<Scalars['ID']>;
  isPaused?: InputMaybe<Scalars['Boolean']>;
  recipientId: Scalars['ID'];
  referenceEventId?: InputMaybe<Scalars['ID']>;
  skippedAt?: InputMaybe<Scalars['AWSDateTime']>;
  type: TEventTypes;
  wasPaused?: InputMaybe<Scalars['Boolean']>;
};

export type TCreateGiftMutationInput = {
  addressId?: InputMaybe<Scalars['ID']>;
  companyId: Scalars['ID'];
  customType?: InputMaybe<Scalars['String']>;
  doneAt?: InputMaybe<Scalars['AWSDateTime']>;
  employeeId: Scalars['ID'];
  eventDate: Scalars['AWSDate'];
  eventId: Scalars['ID'];
  notes?: InputMaybe<Scalars['String']>;
  oldSystemId?: InputMaybe<Scalars['Int']>;
  rack?: InputMaybe<Scalars['String']>;
  recipientId: Scalars['ID'];
  recreatedFromId?: InputMaybe<Scalars['ID']>;
  shipmentId?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<TGiftStatuses>;
  type: TGiftTypes;
};

export type TCreateOfficeMutationInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  address?: InputMaybe<TCreateAddressMutationInput>;
  companyId: Scalars['ID'];
  contact?: InputMaybe<TCreateContactMutationInput>;
  legalName?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  oldSystemId?: InputMaybe<Scalars['Int']>;
};

export type TCreateReferenceEventMutationInput = {
  addressId?: InputMaybe<Scalars['ID']>;
  companyId: Scalars['ID'];
  employeeId: Scalars['ID'];
  fullDate: Scalars['AWSDate'];
  recipientId: Scalars['ID'];
  type: TReferenceEventTypes;
};

export type TCreateRosterFileInput = {
  changelog?: InputMaybe<Scalars['AWSJSON']>;
  changelogFile?: InputMaybe<TCreateRosterFileS3ObjectInput>;
  companyId: Scalars['ID'];
  file?: InputMaybe<TCreateRosterFileS3ObjectInput>;
  headerMapping?: InputMaybe<Scalars['AWSJSON']>;
  mapping: Scalars['AWSJSON'];
  officeMapping?: InputMaybe<Scalars['AWSJSON']>;
};

export type TCreateRosterFileS3ObjectInput = {
  bucket: Scalars['String'];
  key: Scalars['String'];
  region: Scalars['String'];
  version?: InputMaybe<Scalars['String']>;
};

export type TCreateShipmentMutationInput = {
  addressId: Scalars['ID'];
  companyId: Scalars['ID'];
  customsData?: InputMaybe<Array<InputMaybe<TCustomsDataInput>>>;
  label?: InputMaybe<TShipmentLabelInput>;
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  oldSystemId?: InputMaybe<Scalars['Int']>;
  packageInfo?: InputMaybe<TPackageInfoInput>;
  rack?: InputMaybe<Scalars['String']>;
  shippingDate?: InputMaybe<Scalars['AWSDate']>;
  status: TShipmentStatuses;
  trackingNumber?: InputMaybe<Scalars['String']>;
};

export type TCreateShipmentWithGiftsMutationInput = {
  addressId: Scalars['ID'];
  companyId: Scalars['ID'];
  customsData?: InputMaybe<Array<InputMaybe<TCustomsDataInput>>>;
  giftsIds: Array<Scalars['ID']>;
  label?: InputMaybe<TShipmentLabelInput>;
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  oldSystemId?: InputMaybe<Scalars['Int']>;
  packageInfo?: InputMaybe<TPackageInfoInput>;
  rack?: InputMaybe<Scalars['String']>;
  shippingDate?: InputMaybe<Scalars['AWSDate']>;
  status?: InputMaybe<TShipmentStatuses>;
  trackingNumber?: InputMaybe<Scalars['String']>;
};

export type TCreateUserMutationInput = {
  companyId: Scalars['ID'];
  email: Scalars['AWSEmail'];
  name: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
  type: TUserType;
};

export type TCustomsData = {
  __typename?: 'CustomsData';
  country: Scalars['String'];
  description: Scalars['String'];
  hsCode: Scalars['String'];
  quantity: Scalars['Int'];
  value: Scalars['Float'];
};

export type TCustomsDataInput = {
  country: Scalars['String'];
  description: Scalars['String'];
  hsCode: Scalars['String'];
  quantity: Scalars['Int'];
  value: Scalars['Float'];
};

export enum TDeliveryMethod {
  Default = 'default',
  Home = 'home',
  Hybrid = 'hybrid',
  Office = 'office',
}

export type TDependant = TRecipient & {
  __typename?: 'Dependant';
  _deleted?: Maybe<Scalars['Boolean']>;
  _lastChangedAt?: Maybe<Scalars['AWSTimestamp']>;
  _version: Scalars['Int'];
  address?: Maybe<TAddress>;
  birthDate?: Maybe<Scalars['AWSDate']>;
  company?: Maybe<TCompany>;
  companyId: Scalars['ID'];
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  donateBirthdayGift?: Maybe<Scalars['Boolean']>;
  employee?: Maybe<TEmployee>;
  employeeId: Scalars['ID'];
  events: TEventsConnection;
  firstName?: Maybe<Scalars['String']>;
  foodPreferences?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  gifts: TGiftsConnection;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  oldSystemId?: Maybe<Scalars['Int']>;
  petType?: Maybe<Scalars['String']>;
  preferredFirstName?: Maybe<Scalars['String']>;
  type: TDependantType;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
};

export type TDependantEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TDependantGiftsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<TOrderDirection>;
};

export enum TDependantType {
  Child = 'child',
  Pet = 'pet',
  SignificantOther = 'significantOther',
}

export type TDependantsConnection = {
  __typename?: 'DependantsConnection';
  items: Array<Maybe<TDependant>>;
  nextToken?: Maybe<Scalars['String']>;
  scannedCount?: Maybe<Scalars['Int']>;
};

export type TEmployee = TRecipient & {
  __typename?: 'Employee';
  _deleted?: Maybe<Scalars['Boolean']>;
  _lastChangedAt?: Maybe<Scalars['AWSTimestamp']>;
  _version: Scalars['Int'];
  active: Scalars['Boolean'];
  address?: Maybe<TAddress>;
  birthDate?: Maybe<Scalars['AWSDate']>;
  company?: Maybe<TCompany>;
  companyId: Scalars['ID'];
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  customOnboardBrick?: Maybe<Scalars['String']>;
  deliveryMethod?: Maybe<TDeliveryMethod>;
  dependants: TDependantsConnection;
  donateBirthdayGift?: Maybe<Scalars['Boolean']>;
  donateWorkAnniversaryGift?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['AWSEmail']>;
  events: TEventsConnection;
  externalId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  foodPreferences?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  gifts: TGiftsConnection;
  hireDate?: Maybe<Scalars['AWSDate']>;
  id: Scalars['ID'];
  isPauseEnabled?: Maybe<Scalars['Boolean']>;
  isPaused?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  office?: Maybe<TOffice>;
  officeId?: Maybe<Scalars['ID']>;
  oldSystemId?: Maybe<Scalars['Int']>;
  oldSystemIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization?: Maybe<Scalars['String']>;
  pausePeriod?: Maybe<TPausePeriod>;
  pausePeriods?: Maybe<Array<Maybe<TPausePeriod>>>;
  phoneNumber?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferredFirstName?: Maybe<Scalars['String']>;
  sweaterSize?: Maybe<Scalars['String']>;
  tShirtSize?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
};

export type TEmployeeDependantsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TEmployeeEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TEmployeeGiftsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TEmployeesConnection = {
  __typename?: 'EmployeesConnection';
  items: Array<Maybe<TEmployee>>;
  nextToken?: Maybe<Scalars['String']>;
  scannedCount?: Maybe<Scalars['Int']>;
};

export type TEvent = {
  __typename?: 'Event';
  _deleted?: Maybe<Scalars['Boolean']>;
  _lastChangedAt?: Maybe<Scalars['AWSTimestamp']>;
  _version: Scalars['Int'];
  address?: Maybe<TAddress>;
  addressId?: Maybe<Scalars['ID']>;
  age?: Maybe<Scalars['Int']>;
  company?: Maybe<TCompany>;
  companyId: Scalars['ID'];
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  customType?: Maybe<Scalars['String']>;
  deliveredAt?: Maybe<Scalars['AWSDateTime']>;
  employee?: Maybe<TEmployee>;
  employeeId: Scalars['ID'];
  eventDate: Scalars['AWSDate'];
  gift?: Maybe<TGift>;
  giftId?: Maybe<Scalars['ID']>;
  groupedToEvent?: Maybe<TEvent>;
  groupedToEventId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  isPaused?: Maybe<Scalars['Boolean']>;
  recipient?: Maybe<TRecipient>;
  recipientId: Scalars['ID'];
  referenceEvent?: Maybe<TReferenceEvent>;
  referenceEventId?: Maybe<Scalars['ID']>;
  skippedAt?: Maybe<Scalars['AWSDateTime']>;
  type: TEventTypes;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
  wasPaused?: Maybe<Scalars['Boolean']>;
};

export enum TEventTypes {
  BabyGift = 'babyGift',
  Birthday = 'birthday',
  CarePackage = 'carePackage',
  CustomBricks = 'customBricks',
  NewHire = 'newHire',
  Other = 'other',
  ReplacementEmployeeBirthday = 'replacementEmployeeBirthday',
  ReplacementWorkAnniversary = 'replacementWorkAnniversary',
  Wedding = 'wedding',
  WorkAnniversary = 'workAnniversary',
}

export type TEventsConnection = {
  __typename?: 'EventsConnection';
  items: Array<Maybe<TEvent>>;
  nextToken?: Maybe<Scalars['String']>;
  scannedCount?: Maybe<Scalars['Int']>;
};

export type TGift = {
  __typename?: 'Gift';
  _deleted?: Maybe<Scalars['Boolean']>;
  _lastChangedAt?: Maybe<Scalars['AWSTimestamp']>;
  _version: Scalars['Int'];
  address?: Maybe<TAddress>;
  addressId?: Maybe<Scalars['ID']>;
  alerts?: Maybe<Array<Maybe<TAlert>>>;
  company?: Maybe<TCompany>;
  companyId: Scalars['ID'];
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  customType?: Maybe<Scalars['String']>;
  doneAt?: Maybe<Scalars['AWSDateTime']>;
  employee?: Maybe<TEmployee>;
  employeeId: Scalars['ID'];
  event?: Maybe<TEvent>;
  eventDate: Scalars['AWSDate'];
  eventId: Scalars['ID'];
  group?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isDone?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  oldSystemId?: Maybe<Scalars['Int']>;
  rack?: Maybe<Scalars['String']>;
  recipient?: Maybe<TRecipient>;
  recipientData?: Maybe<Scalars['AWSJSON']>;
  recipientId: Scalars['ID'];
  recreatedFromId?: Maybe<Scalars['ID']>;
  shipment?: Maybe<TShipment>;
  shipmentId?: Maybe<Scalars['ID']>;
  status: TGiftStatuses;
  type: TGiftTypes;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
};

export enum TGiftStatuses {
  Assembling = 'assembling',
  Done = 'done',
  Qa = 'qa',
  Todo = 'todo',
}

export enum TGiftTypes {
  BabyGift = 'babyGift',
  Birthday = 'birthday',
  CarePackage = 'carePackage',
  CustomBricks = 'customBricks',
  NewHire = 'newHire',
  Other = 'other',
  ReplacementEmployeeBirthday = 'replacementEmployeeBirthday',
  ReplacementWorkAnniversary = 'replacementWorkAnniversary',
  Wedding = 'wedding',
  WorkAnniversary = 'workAnniversary',
}

export type TGiftsConnection = {
  __typename?: 'GiftsConnection';
  items: Array<Maybe<TGift>>;
  nextToken?: Maybe<Scalars['String']>;
  scannedCount?: Maybe<Scalars['Int']>;
};

export type TLog = {
  __typename?: 'Log';
  arguments?: Maybe<Scalars['AWSJSON']>;
  companyId?: Maybe<Scalars['ID']>;
  fieldName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  identity?: Maybe<Scalars['AWSJSON']>;
  occurredAt?: Maybe<Scalars['AWSDateTime']>;
  parentTypeName?: Maybe<Scalars['String']>;
  resourceId?: Maybe<Scalars['ID']>;
  response?: Maybe<Scalars['AWSJSON']>;
  selectionSetGraphQL?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
  userType?: Maybe<TLogUserTypes>;
  variables?: Maybe<Scalars['AWSJSON']>;
};

export enum TLogUserTypes {
  Client = 'client',
  Internal = 'internal',
  System = 'system',
}

export type TLogsConnection = {
  __typename?: 'LogsConnection';
  items: Array<Maybe<TLog>>;
  nextToken?: Maybe<Scalars['String']>;
  scannedCount?: Maybe<Scalars['Int']>;
};

export type TMoveGiftMutationInput = {
  status: TGiftStatuses;
};

export type TMoveShipmentMutationInput = {
  status: TShipmentStatuses;
};

export type TMutation = {
  __typename?: 'Mutation';
  addAlertToGift?: Maybe<TGift>;
  addAlertToShipment?: Maybe<TShipment>;
  addGiftsToShipment?: Maybe<TShipment>;
  cancelShipmentForPickup?: Maybe<TShipment>;
  createCSUser?: Maybe<TUser>;
  createCompany?: Maybe<TCompany>;
  createDependant?: Maybe<TDependant>;
  createEmployee?: Maybe<TEmployee>;
  createEvent?: Maybe<TEvent>;
  createEventGift?: Maybe<TCreateEventGiftResponse>;
  createGift?: Maybe<TGift>;
  createOffice?: Maybe<TOffice>;
  createReferenceEvent?: Maybe<TReferenceEvent>;
  createRosterFile?: Maybe<TRosterFile>;
  createShipment?: Maybe<TShipment>;
  createShipmentWithGifts?: Maybe<TShipment>;
  createUser?: Maybe<TUser>;
  deleteCompany?: Maybe<TCompany>;
  deleteDependant?: Maybe<TDependant>;
  deleteEmployee?: Maybe<TEmployee>;
  deleteEvent?: Maybe<TEvent>;
  deleteGift?: Maybe<TGift>;
  deleteOffice?: Maybe<TOffice>;
  deleteReferenceEvent?: Maybe<TReferenceEvent>;
  deleteShipment?: Maybe<TShipment>;
  deleteUser?: Maybe<TUser>;
  moveGift?: Maybe<TGift>;
  moveShipment?: Maybe<TShipment>;
  notifyNewHires?: Maybe<Scalars['Boolean']>;
  pauseEmployeeGifts?: Maybe<TEmployee>;
  printShipmentLabel?: Maybe<TShipment>;
  recreateShipment?: Maybe<TShipmentRecreatedResponse>;
  removeGiftFromGroup?: Maybe<TRemovedGiftFromGroupResponse>;
  reshipShipment?: Maybe<TShipment>;
  resyncShipmentWithShipStation?: Maybe<TShipment>;
  setShipmentAsDelivered?: Maybe<TShipment>;
  setShipmentAsPickedUp?: Maybe<TShipment>;
  setShipmentAsReadyToShip?: Maybe<TShipment>;
  setShipmentAsReturned?: Maybe<TShipment>;
  setShipmentAsShipped?: Maybe<TShipment>;
  setShipmentForPickup?: Maybe<TShipment>;
  shipmentLabelPrinted?: Maybe<TShipment>;
  skipAddressAlert?: Maybe<TAddress>;
  skipEvent?: Maybe<TEvent>;
  transferOwnership?: Maybe<Array<Maybe<TUser>>>;
  unpauseEmployeeGifts?: Maybe<TEmployee>;
  unskipEvent?: Maybe<TEvent>;
  updateCompany?: Maybe<TCompany>;
  updateCompanyConfiguration?: Maybe<TCompany>;
  updateDependant?: Maybe<TDependant>;
  updateEmployee?: Maybe<TEmployee>;
  updateEvent?: Maybe<TEvent>;
  updateGift?: Maybe<TGift>;
  updateOffice?: Maybe<TOffice>;
  updateReferenceEvent?: Maybe<TReferenceEvent>;
  updateRosterFile?: Maybe<TRosterFile>;
  updateShipment?: Maybe<TShipment>;
  updateUser?: Maybe<TUser>;
};

export type TMutationAddAlertToGiftArgs = {
  _version: Scalars['Int'];
  companyId: Scalars['ID'];
  id: Scalars['ID'];
  input: TAlertInput;
};

export type TMutationAddAlertToShipmentArgs = {
  _version: Scalars['Int'];
  companyId: Scalars['ID'];
  id: Scalars['ID'];
  input: TAlertInput;
};

export type TMutationAddGiftsToShipmentArgs = {
  companyId: Scalars['ID'];
  giftsIds: Array<Scalars['ID']>;
  shipmentId: Scalars['ID'];
};

export type TMutationCancelShipmentForPickupArgs = {
  _version: Scalars['Int'];
  companyId: Scalars['ID'];
  id: Scalars['ID'];
};

export type TMutationCreateCsUserArgs = {
  companyId: Scalars['ID'];
};

export type TMutationCreateCompanyArgs = {
  input: TCreateCompanyMutationInput;
};

export type TMutationCreateDependantArgs = {
  input: TCreateDependantMutationInput;
};

export type TMutationCreateEmployeeArgs = {
  input: TCreateEmployeeMutationInput;
};

export type TMutationCreateEventArgs = {
  input: TCreateEventMutationInput;
};

export type TMutationCreateEventGiftArgs = {
  _eventVersion: Scalars['Int'];
  companyId: Scalars['ID'];
  eventId: Scalars['ID'];
  group?: InputMaybe<Scalars['String']>;
};

export type TMutationCreateGiftArgs = {
  input: TCreateGiftMutationInput;
};

export type TMutationCreateOfficeArgs = {
  input: TCreateOfficeMutationInput;
};

export type TMutationCreateReferenceEventArgs = {
  input: TCreateReferenceEventMutationInput;
};

export type TMutationCreateRosterFileArgs = {
  input: TCreateRosterFileInput;
};

export type TMutationCreateShipmentArgs = {
  input: TCreateShipmentMutationInput;
};

export type TMutationCreateShipmentWithGiftsArgs = {
  input: TCreateShipmentWithGiftsMutationInput;
};

export type TMutationCreateUserArgs = {
  input: TCreateUserMutationInput;
};

export type TMutationDeleteCompanyArgs = {
  _version: Scalars['Int'];
  id: Scalars['ID'];
};

export type TMutationDeleteDependantArgs = {
  _version: Scalars['Int'];
  companyId: Scalars['ID'];
  id: Scalars['ID'];
};

export type TMutationDeleteEmployeeArgs = {
  _version: Scalars['Int'];
  companyId: Scalars['ID'];
  id: Scalars['ID'];
};

export type TMutationDeleteEventArgs = {
  _version: Scalars['Int'];
  companyId: Scalars['ID'];
  id: Scalars['ID'];
};

export type TMutationDeleteGiftArgs = {
  _version: Scalars['Int'];
  companyId: Scalars['ID'];
  id: Scalars['ID'];
};

export type TMutationDeleteOfficeArgs = {
  _version: Scalars['Int'];
  companyId: Scalars['ID'];
  id: Scalars['ID'];
};

export type TMutationDeleteReferenceEventArgs = {
  _version: Scalars['Int'];
  companyId: Scalars['ID'];
  id: Scalars['ID'];
};

export type TMutationDeleteShipmentArgs = {
  _version: Scalars['Int'];
  companyId: Scalars['ID'];
  id: Scalars['ID'];
};

export type TMutationDeleteUserArgs = {
  _version: Scalars['Int'];
  companyId: Scalars['ID'];
  email: Scalars['AWSEmail'];
};

export type TMutationMoveGiftArgs = {
  _version: Scalars['Int'];
  companyId: Scalars['ID'];
  id: Scalars['ID'];
  input: TMoveGiftMutationInput;
};

export type TMutationMoveShipmentArgs = {
  _version: Scalars['Int'];
  companyId: Scalars['ID'];
  id: Scalars['ID'];
  input: TMoveShipmentMutationInput;
};

export type TMutationNotifyNewHiresArgs = {
  events: Array<InputMaybe<TNotifyNewHireMutationInput>>;
};

export type TMutationPauseEmployeeGiftsArgs = {
  _version: Scalars['Int'];
  companyId: Scalars['ID'];
  fromDate?: InputMaybe<Scalars['AWSDate']>;
  id: Scalars['ID'];
  toDate?: InputMaybe<Scalars['AWSDate']>;
};

export type TMutationPrintShipmentLabelArgs = {
  _version: Scalars['Int'];
  companyId: Scalars['ID'];
  id: Scalars['ID'];
  input: TPrintShipmentLabelMutationInput;
};

export type TMutationRecreateShipmentArgs = {
  companyId: Scalars['ID'];
  id: Scalars['ID'];
};

export type TMutationRemoveGiftFromGroupArgs = {
  _version: Scalars['Int'];
  companyId: Scalars['ID'];
  id: Scalars['ID'];
};

export type TMutationReshipShipmentArgs = {
  _version: Scalars['Int'];
  companyId: Scalars['ID'];
  id: Scalars['ID'];
};

export type TMutationResyncShipmentWithShipStationArgs = {
  _version: Scalars['Int'];
  companyId: Scalars['ID'];
  id: Scalars['ID'];
};

export type TMutationSetShipmentAsDeliveredArgs = {
  _version: Scalars['Int'];
  companyId: Scalars['ID'];
  id: Scalars['ID'];
};

export type TMutationSetShipmentAsPickedUpArgs = {
  _version: Scalars['Int'];
  companyId: Scalars['ID'];
  id: Scalars['ID'];
};

export type TMutationSetShipmentAsReadyToShipArgs = {
  _version: Scalars['Int'];
  companyId: Scalars['ID'];
  id: Scalars['ID'];
  input: TSetShipmentAsReadyToShipMutationInput;
};

export type TMutationSetShipmentAsReturnedArgs = {
  _version: Scalars['Int'];
  companyId: Scalars['ID'];
  id: Scalars['ID'];
};

export type TMutationSetShipmentAsShippedArgs = {
  _version: Scalars['Int'];
  companyId: Scalars['ID'];
  id: Scalars['ID'];
};

export type TMutationSetShipmentForPickupArgs = {
  _version: Scalars['Int'];
  companyId: Scalars['ID'];
  id: Scalars['ID'];
};

export type TMutationShipmentLabelPrintedArgs = {
  _version: Scalars['Int'];
  companyId: Scalars['ID'];
  id: Scalars['ID'];
  input: TShipmentLabelPrintedMutationInput;
};

export type TMutationSkipAddressAlertArgs = {
  _version: Scalars['Int'];
  companyId: Scalars['ID'];
  id: Scalars['ID'];
};

export type TMutationSkipEventArgs = {
  _version: Scalars['Int'];
  companyId: Scalars['ID'];
  id: Scalars['ID'];
};

export type TMutationTransferOwnershipArgs = {
  _oldOwnerVersion: Scalars['Int'];
  companyId: Scalars['ID'];
  newOwnerEmail: Scalars['AWSEmail'];
  oldOwnerEmail: Scalars['AWSEmail'];
};

export type TMutationUnpauseEmployeeGiftsArgs = {
  _version: Scalars['Int'];
  companyId: Scalars['ID'];
  id: Scalars['ID'];
};

export type TMutationUnskipEventArgs = {
  _version: Scalars['Int'];
  companyId: Scalars['ID'];
  id: Scalars['ID'];
};

export type TMutationUpdateCompanyArgs = {
  _version: Scalars['Int'];
  id: Scalars['ID'];
  input: TUpdateCompanyMutationInput;
};

export type TMutationUpdateCompanyConfigurationArgs = {
  _version: Scalars['Int'];
  id: Scalars['ID'];
  input: TUpdateCompanyConfigurationMutationInput;
};

export type TMutationUpdateDependantArgs = {
  _version: Scalars['Int'];
  companyId: Scalars['ID'];
  id: Scalars['ID'];
  input: TUpdateDependantMutationInput;
};

export type TMutationUpdateEmployeeArgs = {
  _version: Scalars['Int'];
  companyId: Scalars['ID'];
  id: Scalars['ID'];
  input: TUpdateEmployeeMutationInput;
};

export type TMutationUpdateEventArgs = {
  _version: Scalars['Int'];
  companyId: Scalars['ID'];
  id: Scalars['ID'];
  input: TUpdateEventMutationInput;
};

export type TMutationUpdateGiftArgs = {
  _version: Scalars['Int'];
  companyId: Scalars['ID'];
  id: Scalars['ID'];
  input: TUpdateGiftMutationInput;
};

export type TMutationUpdateOfficeArgs = {
  _version: Scalars['Int'];
  companyId: Scalars['ID'];
  id: Scalars['ID'];
  input: TUpdateOfficeMutationInput;
};

export type TMutationUpdateReferenceEventArgs = {
  _version: Scalars['Int'];
  companyId: Scalars['ID'];
  id: Scalars['ID'];
  input: TUpdateReferenceEventMutationInput;
};

export type TMutationUpdateRosterFileArgs = {
  _version: Scalars['Int'];
  companyId: Scalars['ID'];
  id: Scalars['ID'];
  input: TUpdateRosterFileInput;
};

export type TMutationUpdateShipmentArgs = {
  _version: Scalars['Int'];
  companyId: Scalars['ID'];
  id: Scalars['ID'];
  input: TUpdateShipmentMutationInput;
};

export type TMutationUpdateUserArgs = {
  _version: Scalars['Int'];
  companyId: Scalars['ID'];
  email: Scalars['AWSEmail'];
  input: TUpdateUserMutationInput;
};

export type TNotifyNewHireMutationInput = {
  companyId?: InputMaybe<Scalars['ID']>;
  companyName?: InputMaybe<Scalars['String']>;
  employeeName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type TOffice = {
  __typename?: 'Office';
  _deleted?: Maybe<Scalars['Boolean']>;
  _lastChangedAt?: Maybe<Scalars['AWSTimestamp']>;
  _version: Scalars['Int'];
  active: Scalars['Boolean'];
  address?: Maybe<TAddress>;
  company?: Maybe<TCompany>;
  companyId: Scalars['ID'];
  contact?: Maybe<TContact>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  employees: TEmployeesConnection;
  id: Scalars['ID'];
  legalName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  normalizedName?: Maybe<Scalars['String']>;
  oldSystemId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
};

export type TOfficeEmployeesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TOfficesConnection = {
  __typename?: 'OfficesConnection';
  items: Array<Maybe<TOffice>>;
  nextToken?: Maybe<Scalars['String']>;
  scannedCount?: Maybe<Scalars['Int']>;
};

export enum TOrderDirection {
  Asc = 'asc',
  Desc = 'desc',
}

export type TPackageInfo = {
  __typename?: 'PackageInfo';
  height: Scalars['Float'];
  length: Scalars['Float'];
  weight: Scalars['Float'];
  width: Scalars['Float'];
};

export type TPackageInfoInput = {
  height: Scalars['Float'];
  length: Scalars['Float'];
  weight: Scalars['Float'];
  width: Scalars['Float'];
};

export type TPausePeriod = {
  __typename?: 'PausePeriod';
  fromDate: Scalars['AWSDate'];
  toDate?: Maybe<Scalars['AWSDate']>;
  unpausedAt?: Maybe<Scalars['AWSDateTime']>;
};

export type TPrintShipmentLabelMutationInput = {
  actualShippingDate: Scalars['AWSDate'];
  customsData?: InputMaybe<Array<InputMaybe<TCustomsDataInput>>>;
};

export type TQuery = {
  __typename?: 'Query';
  getAddress?: Maybe<TAddress>;
  getAddressById?: Maybe<TAddress>;
  getCompany?: Maybe<TCompany>;
  getCompanyByName?: Maybe<TCompany>;
  getCompanyByOldSystemId?: Maybe<TCompany>;
  getCompanyByRosterId?: Maybe<TCompany>;
  getCompanyBySubdomain?: Maybe<TCompany>;
  getCompanyByUserPoolId?: Maybe<TCompany>;
  getDependant?: Maybe<TDependant>;
  getDependantByOldSystemId?: Maybe<TDependant>;
  getEmployee?: Maybe<TEmployee>;
  getEmployeeByExternalId?: Maybe<TEmployee>;
  getEmployeeByOldSystemId?: Maybe<TEmployee>;
  getEvent?: Maybe<TEvent>;
  getGift?: Maybe<TGift>;
  getGiftByOldSystemId?: Maybe<TGift>;
  getMyCompany?: Maybe<TCompany>;
  getOffice?: Maybe<TOffice>;
  getOfficeByName?: Maybe<TOffice>;
  getOfficeByOldSystemId?: Maybe<TOffice>;
  getRecipient?: Maybe<TRecipient>;
  getReferenceEvent?: Maybe<TReferenceEvent>;
  getRosterFile?: Maybe<TRosterFile>;
  getShipment?: Maybe<TShipment>;
  getShipmentByOldSystemId?: Maybe<TShipment>;
  getShipmentByTrackingNumber?: Maybe<TShipment>;
  getUser?: Maybe<TUser>;
  listAddresses: TAddressesConnection;
  listAdminCognitoUsers?: Maybe<Scalars['AWSJSON']>;
  listAllLogs: TLogsConnection;
  listAllOffices: TOfficesConnection;
  listClosedShipments: TShipmentsConnection;
  listCompanies: TCompaniesConnection;
  listCompanyDependants: TDependantsConnection;
  listCompanyEmployees: TEmployeesConnection;
  listCompanyReferenceEvents: TReferenceEventsConnection;
  listCompanyShipments: TShipmentsConnection;
  listCompanyUpcomingEvents: TEventsConnection;
  listDependants: TDependantsConnection;
  listEmployeeAndDependantsNotSkippedWithoutGiftEvents: TEventsConnection;
  listEmployeeAndDependatsGifts: TGiftsConnection;
  listEmployeeDependants: TDependantsConnection;
  listEmployeeReferenceEvents: TReferenceEventsConnection;
  listEmployees: TEmployeesConnection;
  listGiftsByGroup: TGiftsConnection;
  listNotDoneGifts: TGiftsConnection;
  listNotSkippedEventsWithoutGift: TEventsConnection;
  listOffices: TOfficesConnection;
  listOpenShipments: TShipmentsConnection;
  listRecentSetForPickupShipments?: Maybe<TShipmentsConnection>;
  listRecentShippedShipments?: Maybe<TShipmentsConnection>;
  listRecentlyDoneGifts: TGiftsConnection;
  listRecipientGifts: TGiftsConnection;
  listRecipientReferenceEvents: TReferenceEventsConnection;
  listReferenceEventEvents: TEventsConnection;
  listReferenceEventEventsWithGift: TEventsConnection;
  listReferenceEventEventsWithoutGift: TEventsConnection;
  listReferenceEventOnProgressEvents: TEventsConnection;
  listReferenceEventUpcomingEvents: TEventsConnection;
  listReferenceEventsByDate: TReferenceEventsConnection;
  listRosterFiles: TRosterFilesConnection;
  listRosterFilesByCompanyId: TRosterFilesConnection;
  listSetForPickupShipments?: Maybe<TShipmentsConnection>;
  listShipmentGifts: TGiftsConnection;
  listShipmentsByAddress: TShipmentsConnection;
  listShipmentsByAddressAndStatus: TShipmentsConnection;
  listShippedShipments?: Maybe<TShipmentsConnection>;
  listSkippedEvents: TEventsConnection;
  listTodayReferenceEvents: TReferenceEventsConnection;
  listUsers: TUsersConnection;
  listUsersIncludingCSUsers: TUsersConnection;
};

export type TQueryGetAddressArgs = {
  companyId: Scalars['ID'];
  id: Scalars['ID'];
};

export type TQueryGetAddressByIdArgs = {
  id: Scalars['ID'];
};

export type TQueryGetCompanyArgs = {
  id: Scalars['ID'];
};

export type TQueryGetCompanyByNameArgs = {
  name: Scalars['String'];
};

export type TQueryGetCompanyByOldSystemIdArgs = {
  oldSystemId: Scalars['Int'];
};

export type TQueryGetCompanyByRosterIdArgs = {
  rosterId: Scalars['String'];
};

export type TQueryGetCompanyBySubdomainArgs = {
  subdomain: Scalars['String'];
};

export type TQueryGetCompanyByUserPoolIdArgs = {
  userPoolId: Scalars['String'];
};

export type TQueryGetDependantArgs = {
  companyId: Scalars['ID'];
  id: Scalars['ID'];
};

export type TQueryGetDependantByOldSystemIdArgs = {
  oldSystemId: Scalars['Int'];
};

export type TQueryGetEmployeeArgs = {
  companyId: Scalars['ID'];
  id: Scalars['ID'];
};

export type TQueryGetEmployeeByExternalIdArgs = {
  externalId: Scalars['String'];
};

export type TQueryGetEmployeeByOldSystemIdArgs = {
  oldSystemId: Scalars['Int'];
};

export type TQueryGetEventArgs = {
  companyId: Scalars['ID'];
  id: Scalars['ID'];
};

export type TQueryGetGiftArgs = {
  companyId: Scalars['ID'];
  id: Scalars['ID'];
};

export type TQueryGetGiftByOldSystemIdArgs = {
  oldSystemId: Scalars['Int'];
};

export type TQueryGetOfficeArgs = {
  companyId: Scalars['ID'];
  id: Scalars['ID'];
};

export type TQueryGetOfficeByNameArgs = {
  companyId: Scalars['ID'];
  name: Scalars['String'];
};

export type TQueryGetOfficeByOldSystemIdArgs = {
  oldSystemId: Scalars['Int'];
};

export type TQueryGetRecipientArgs = {
  companyId: Scalars['ID'];
  id: Scalars['ID'];
};

export type TQueryGetReferenceEventArgs = {
  companyId: Scalars['ID'];
  id: Scalars['ID'];
};

export type TQueryGetRosterFileArgs = {
  companyId: Scalars['ID'];
  id: Scalars['ID'];
};

export type TQueryGetShipmentArgs = {
  companyId: Scalars['ID'];
  id: Scalars['ID'];
};

export type TQueryGetShipmentByOldSystemIdArgs = {
  oldSystemId: Scalars['Int'];
};

export type TQueryGetShipmentByTrackingNumberArgs = {
  trackingNumber: Scalars['ID'];
};

export type TQueryGetUserArgs = {
  companyId: Scalars['ID'];
  email: Scalars['AWSEmail'];
};

export type TQueryListAddressesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TQueryListAdminCognitoUsersArgs = {
  userPoolId: Scalars['String'];
};

export type TQueryListAllLogsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<TOrderDirection>;
  userType: TLogUserTypes;
};

export type TQueryListAllOfficesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TQueryListClosedShipmentsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TQueryListCompaniesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TQueryListCompanyDependantsArgs = {
  companyId: Scalars['ID'];
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TQueryListCompanyEmployeesArgs = {
  companyId: Scalars['ID'];
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TQueryListCompanyReferenceEventsArgs = {
  companyId: Scalars['ID'];
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TQueryListCompanyShipmentsArgs = {
  companyId: Scalars['ID'];
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TQueryListCompanyUpcomingEventsArgs = {
  companyId: Scalars['ID'];
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TQueryListDependantsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TQueryListEmployeeAndDependantsNotSkippedWithoutGiftEventsArgs = {
  employeeId: Scalars['ID'];
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TQueryListEmployeeAndDependatsGiftsArgs = {
  companyId: Scalars['ID'];
  employeeId: Scalars['ID'];
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TQueryListEmployeeDependantsArgs = {
  companyId: Scalars['ID'];
  employeeId: Scalars['ID'];
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TQueryListEmployeeReferenceEventsArgs = {
  companyId: Scalars['ID'];
  employeeId: Scalars['ID'];
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TQueryListEmployeesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TQueryListGiftsByGroupArgs = {
  companyId: Scalars['String'];
  group: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  ordedr?: InputMaybe<TOrderDirection>;
};

export type TQueryListNotDoneGiftsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TQueryListNotSkippedEventsWithoutGiftArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TQueryListOfficesArgs = {
  companyId: Scalars['ID'];
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TQueryListOpenShipmentsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TQueryListRecentSetForPickupShipmentsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TQueryListRecentShippedShipmentsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TQueryListRecentlyDoneGiftsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TQueryListRecipientGiftsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<TOrderDirection>;
  recipientId: Scalars['ID'];
};

export type TQueryListRecipientReferenceEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<TOrderDirection>;
  recipientId: Scalars['ID'];
};

export type TQueryListReferenceEventEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<TOrderDirection>;
  referenceEventId: Scalars['ID'];
};

export type TQueryListReferenceEventEventsWithGiftArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<TOrderDirection>;
  referenceEventId: Scalars['ID'];
};

export type TQueryListReferenceEventEventsWithoutGiftArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<TOrderDirection>;
  referenceEventId: Scalars['ID'];
};

export type TQueryListReferenceEventOnProgressEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<TOrderDirection>;
  referenceEventId: Scalars['ID'];
};

export type TQueryListReferenceEventUpcomingEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<TOrderDirection>;
  referenceEventId: Scalars['ID'];
};

export type TQueryListReferenceEventsByDateArgs = {
  date: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TQueryListRosterFilesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TQueryListRosterFilesByCompanyIdArgs = {
  companyId: Scalars['ID'];
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TQueryListSetForPickupShipmentsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TQueryListShipmentGiftsArgs = {
  companyId: Scalars['ID'];
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<TOrderDirection>;
  shipmentId: Scalars['ID'];
};

export type TQueryListShipmentsByAddressArgs = {
  addressId: Scalars['ID'];
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TQueryListShipmentsByAddressAndStatusArgs = {
  addressId: Scalars['ID'];
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<TOrderDirection>;
  status: TShipmentStatuses;
};

export type TQueryListShippedShipmentsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TQueryListSkippedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TQueryListTodayReferenceEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TQueryListUsersArgs = {
  companyId: Scalars['ID'];
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TQueryListUsersIncludingCsUsersArgs = {
  companyId: Scalars['ID'];
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TRecipient = {
  _deleted?: Maybe<Scalars['Boolean']>;
  _lastChangedAt?: Maybe<Scalars['AWSTimestamp']>;
  _version: Scalars['Int'];
  address?: Maybe<TAddress>;
  birthDate?: Maybe<Scalars['AWSDate']>;
  companyId: Scalars['ID'];
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  donateBirthdayGift?: Maybe<Scalars['Boolean']>;
  firstName?: Maybe<Scalars['String']>;
  foodPreferences?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  preferredFirstName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
};

export type TReferenceEvent = {
  __typename?: 'ReferenceEvent';
  _deleted?: Maybe<Scalars['Boolean']>;
  _lastChangedAt?: Maybe<Scalars['AWSTimestamp']>;
  _version: Scalars['Int'];
  address?: Maybe<TAddress>;
  addressId?: Maybe<Scalars['ID']>;
  company?: Maybe<TCompany>;
  companyId: Scalars['ID'];
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  date: Scalars['String'];
  employee?: Maybe<TEmployee>;
  employeeId: Scalars['ID'];
  fullDate: Scalars['AWSDate'];
  id: Scalars['ID'];
  recipient?: Maybe<TRecipient>;
  recipientId: Scalars['ID'];
  type: TReferenceEventTypes;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
};

export enum TReferenceEventTypes {
  Birthday = 'birthday',
  WorkAnniversary = 'workAnniversary',
}

export type TReferenceEventsConnection = {
  __typename?: 'ReferenceEventsConnection';
  items: Array<Maybe<TReferenceEvent>>;
  nextToken?: Maybe<Scalars['String']>;
  scannedCount?: Maybe<Scalars['Int']>;
};

export type TRemovedGiftFromGroupResponse = {
  __typename?: 'RemovedGiftFromGroupResponse';
  companyId: Scalars['ID'];
  items: Array<Maybe<TGift>>;
};

export type TRosterFile = {
  __typename?: 'RosterFile';
  _deleted?: Maybe<Scalars['Boolean']>;
  _lastChangedAt?: Maybe<Scalars['AWSTimestamp']>;
  _version: Scalars['Int'];
  changelog?: Maybe<Scalars['AWSJSON']>;
  changelogFile?: Maybe<TRosterFileS3Object>;
  company?: Maybe<TCompany>;
  companyId: Scalars['ID'];
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  createdBy?: Maybe<TRosterFileCreatedBy>;
  file: TRosterFileS3Object;
  headerMapping?: Maybe<Scalars['AWSJSON']>;
  id: Scalars['ID'];
  impoortEndedAt?: Maybe<Scalars['AWSDateTime']>;
  importErrors?: Maybe<Scalars['AWSJSON']>;
  importStartedAt?: Maybe<Scalars['AWSDateTime']>;
  mapping: Scalars['AWSJSON'];
  officeMapping?: Maybe<Scalars['AWSJSON']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
};

export type TRosterFileCreatedBy = {
  __typename?: 'RosterFileCreatedBy';
  sub?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type TRosterFileS3Object = {
  __typename?: 'RosterFileS3Object';
  bucket: Scalars['String'];
  key: Scalars['String'];
  region: Scalars['String'];
  version?: Maybe<Scalars['String']>;
};

export type TRosterFilesConnection = {
  __typename?: 'RosterFilesConnection';
  items: Array<Maybe<TRosterFile>>;
  nextToken?: Maybe<Scalars['String']>;
  scannedCount?: Maybe<Scalars['Int']>;
};

export type TS3Object = {
  __typename?: 'S3Object';
  bucket: Scalars['String'];
  key: Scalars['String'];
  region: Scalars['String'];
  version?: Maybe<Scalars['String']>;
};

export type TS3ObjectInput = {
  bucket: Scalars['String'];
  key: Scalars['String'];
  region: Scalars['String'];
  version?: InputMaybe<Scalars['String']>;
};

export type TSetShipmentAsReadyToShipMutationInput = {
  packageInfo: TPackageInfoInput;
};

export enum TShipStationAddressVerified {
  Failed = 'failed',
  Pending = 'pending',
  Success = 'success',
  Warning = 'warning',
}

export type TShipment = {
  __typename?: 'Shipment';
  _deleted?: Maybe<Scalars['Boolean']>;
  _lastChangedAt?: Maybe<Scalars['AWSTimestamp']>;
  _version: Scalars['Int'];
  actualShippingDate?: Maybe<Scalars['AWSDate']>;
  address?: Maybe<TAddress>;
  addressId: Scalars['ID'];
  addressVerified?: Maybe<TShipStationAddressVerified>;
  alerts?: Maybe<Array<Maybe<TAlert>>>;
  company?: Maybe<TCompany>;
  companyId: Scalars['ID'];
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  customsData?: Maybe<Array<Maybe<TCustomsData>>>;
  deliveredAt?: Maybe<Scalars['AWSDateTime']>;
  gifts?: Maybe<TGiftsConnection>;
  history?: Maybe<Array<Maybe<TShipmentHistory>>>;
  id: Scalars['ID'];
  label?: Maybe<TShipmentLabel>;
  lastShipStationResyncStartedAt?: Maybe<Scalars['AWSDateTime']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  oldSystemId?: Maybe<Scalars['Int']>;
  packageInfo?: Maybe<TPackageInfo>;
  pickedUpAt?: Maybe<Scalars['AWSDateTime']>;
  rack?: Maybe<Scalars['String']>;
  resyncWithShipStation?: Maybe<Scalars['Boolean']>;
  returnedAt?: Maybe<Scalars['AWSDateTime']>;
  setForPickupAt?: Maybe<Scalars['AWSDateTime']>;
  shipStationError?: Maybe<Scalars['AWSJSON']>;
  shipStationOrderId?: Maybe<Scalars['Int']>;
  shipStationResyncCount?: Maybe<Scalars['Int']>;
  shipStationShipmentId?: Maybe<Scalars['Int']>;
  shippedAt?: Maybe<Scalars['AWSDateTime']>;
  shippingDate?: Maybe<Scalars['AWSDate']>;
  status: TShipmentStatuses;
  trackingNumber?: Maybe<Scalars['String']>;
  trackingURL?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
  useGoogleAddress?: Maybe<Scalars['Boolean']>;
};

export type TShipmentHistory = {
  __typename?: 'ShipmentHistory';
  actualShippingDate?: Maybe<Scalars['AWSDate']>;
  addressVerified?: Maybe<TShipStationAddressVerified>;
  alerts?: Maybe<Array<Maybe<TAlert>>>;
  customsData?: Maybe<Array<Maybe<TCustomsData>>>;
  deliveredAt?: Maybe<Scalars['AWSDateTime']>;
  label?: Maybe<TShipmentLabel>;
  notes?: Maybe<Scalars['String']>;
  packageInfo?: Maybe<TPackageInfo>;
  pickedUpAt?: Maybe<Scalars['AWSDateTime']>;
  rack?: Maybe<Scalars['String']>;
  returnedAt?: Maybe<Scalars['AWSDateTime']>;
  setForPickupAt?: Maybe<Scalars['AWSDateTime']>;
  shipStationOrderId?: Maybe<Scalars['Int']>;
  shipStationShipmentId?: Maybe<Scalars['Int']>;
  shippedAt?: Maybe<Scalars['AWSDateTime']>;
  trackingNumber?: Maybe<Scalars['String']>;
  trackingURL?: Maybe<Scalars['String']>;
};

export type TShipmentLabel = {
  __typename?: 'ShipmentLabel';
  addressData?: Maybe<TAddress>;
  carrier: Scalars['String'];
  cost: Scalars['Float'];
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  labelFile?: Maybe<TS3Object>;
};

export type TShipmentLabelInput = {
  addressData?: InputMaybe<Scalars['AWSJSON']>;
  carrier: Scalars['String'];
  cost?: InputMaybe<Scalars['Float']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']>;
  labelFile?: InputMaybe<TS3ObjectInput>;
};

export type TShipmentLabelPrintedMutationInput = {
  label: TShipmentLabelInput;
  trackingNumber: Scalars['String'];
};

export type TShipmentRecreatedResponse = {
  __typename?: 'ShipmentRecreatedResponse';
  companyId: Scalars['ID'];
  items: Array<Maybe<TGift>>;
};

export enum TShipmentStatuses {
  Attention = 'attention',
  Canceled = 'canceled',
  Delivered = 'delivered',
  Error = 'error',
  LabelPrinted = 'labelPrinted',
  Packaging = 'packaging',
  PickedUp = 'pickedUp',
  PrintingLabel = 'printingLabel',
  ReadyForPickup = 'readyForPickup',
  ReadyToShip = 'readyToShip',
  Returned = 'returned',
  Shipped = 'shipped',
  Unavailable = 'unavailable',
}

export type TShipmentsConnection = {
  __typename?: 'ShipmentsConnection';
  items: Array<Maybe<TShipment>>;
  nextToken?: Maybe<Scalars['String']>;
  scannedCount?: Maybe<Scalars['Int']>;
};

export type TSubscription = {
  __typename?: 'Subscription';
  onAnyAddressChanged?: Maybe<TAddress>;
  onAnyCompanyChanged?: Maybe<TCompany>;
  onAnyDependantChanged?: Maybe<TDependant>;
  onAnyEmployeeChanged?: Maybe<TEmployee>;
  onAnyEventChanged?: Maybe<TEvent>;
  onAnyEventGiftCreated?: Maybe<TCreateEventGiftResponse>;
  onAnyGiftChanged?: Maybe<TGift>;
  onAnyGiftRemovedFromGroup?: Maybe<TRemovedGiftFromGroupResponse>;
  onAnyOfficeChanged?: Maybe<TOffice>;
  onAnyOwnershipTransfered?: Maybe<Array<Maybe<TUser>>>;
  onAnyRosterFileChanged?: Maybe<TRosterFile>;
  onAnyShipmentChanged?: Maybe<TShipment>;
  onAnyShipmentRecreated?: Maybe<TShipmentRecreatedResponse>;
  onAnyUserChanged?: Maybe<TUser>;
  onCompanyAddressChanged?: Maybe<TAddress>;
  onCompanyChanged?: Maybe<TCompany>;
  onCompanyDependantChanged?: Maybe<TDependant>;
  onCompanyEmployeeChanged?: Maybe<TEmployee>;
  onCompanyEventChanged?: Maybe<TEvent>;
  onCompanyEventGiftCreated?: Maybe<TCreateEventGiftResponse>;
  onCompanyGiftChanged?: Maybe<TGift>;
  onCompanyGiftRemovedFromGroup?: Maybe<TRemovedGiftFromGroupResponse>;
  onCompanyOfficeChanged?: Maybe<TOffice>;
  onCompanyOwnershipTransfered?: Maybe<Array<Maybe<TUser>>>;
  onCompanyRosterFileChanged?: Maybe<TRosterFile>;
  onCompanyShipmentChanged?: Maybe<TShipment>;
  onCompanyShipmentRecreated?: Maybe<TShipmentRecreatedResponse>;
  onCompanyUserChanged?: Maybe<TUser>;
  onEmployeeDependantChanged?: Maybe<TDependant>;
  onEmployeeGiftChanged?: Maybe<TGift>;
};

export type TSubscriptionOnCompanyAddressChangedArgs = {
  companyId: Scalars['ID'];
};

export type TSubscriptionOnCompanyChangedArgs = {
  id: Scalars['ID'];
};

export type TSubscriptionOnCompanyDependantChangedArgs = {
  companyId: Scalars['ID'];
};

export type TSubscriptionOnCompanyEmployeeChangedArgs = {
  companyId: Scalars['ID'];
};

export type TSubscriptionOnCompanyEventChangedArgs = {
  companyId: Scalars['ID'];
};

export type TSubscriptionOnCompanyEventGiftCreatedArgs = {
  companyId: Scalars['ID'];
};

export type TSubscriptionOnCompanyGiftChangedArgs = {
  companyId: Scalars['ID'];
};

export type TSubscriptionOnCompanyGiftRemovedFromGroupArgs = {
  companyId: Scalars['ID'];
};

export type TSubscriptionOnCompanyOfficeChangedArgs = {
  companyId: Scalars['ID'];
};

export type TSubscriptionOnCompanyOwnershipTransferedArgs = {
  companyId: Scalars['ID'];
};

export type TSubscriptionOnCompanyRosterFileChangedArgs = {
  companyId: Scalars['ID'];
};

export type TSubscriptionOnCompanyShipmentChangedArgs = {
  companyId: Scalars['ID'];
};

export type TSubscriptionOnCompanyShipmentRecreatedArgs = {
  companyId: Scalars['ID'];
};

export type TSubscriptionOnCompanyUserChangedArgs = {
  companyId: Scalars['ID'];
};

export type TSubscriptionOnEmployeeDependantChangedArgs = {
  companyId: Scalars['ID'];
  employeeId: Scalars['ID'];
};

export type TSubscriptionOnEmployeeGiftChangedArgs = {
  companyId: Scalars['ID'];
  employeeId: Scalars['ID'];
};

export type TUpdateAddressMutationInput = {
  address1?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  useGoogleAddress?: InputMaybe<Scalars['Boolean']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type TUpdateCompanyConfigurationMutationInput = {
  authCloudfrontDomain?: InputMaybe<Scalars['String']>;
  authUserPoolClientId?: InputMaybe<Scalars['String']>;
  authUserPoolClientName?: InputMaybe<Scalars['String']>;
  authUserPoolDomainURL?: InputMaybe<Scalars['String']>;
  authUserPoolId?: InputMaybe<Scalars['String']>;
  authUserPoolName?: InputMaybe<Scalars['String']>;
  frontendCertificateArn?: InputMaybe<Scalars['String']>;
  frontendDistributionArn?: InputMaybe<Scalars['String']>;
  frontendDistributionDomainName?: InputMaybe<Scalars['String']>;
  frontendDistributionId?: InputMaybe<Scalars['String']>;
};

export type TUpdateCompanyMutationInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  address?: InputMaybe<TUpdateAddressMutationInput>;
  bambooHRAPIKey?: InputMaybe<Scalars['String']>;
  bambooHRPetsTableName?: InputMaybe<Scalars['String']>;
  bambooHRSubdomain?: InputMaybe<Scalars['String']>;
  childBirthdayActivated?: InputMaybe<Scalars['Boolean']>;
  companyWideLegoCustomization?: InputMaybe<Scalars['String']>;
  contact?: InputMaybe<TUpdateContactMutationInput>;
  customLego?: InputMaybe<Scalars['Boolean']>;
  customWorkAnniversaryNotes?: InputMaybe<Scalars['String']>;
  deliveryMethod?: InputMaybe<TDeliveryMethod>;
  emailDomains?: InputMaybe<Array<Scalars['String']>>;
  employeeBirthdayActivated?: InputMaybe<Scalars['Boolean']>;
  employeeNewHireKitActivated?: InputMaybe<Scalars['Boolean']>;
  employeeWorkAnniversaryActivated?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['AWSDate']>;
  googleLoginEnabled?: InputMaybe<Scalars['Boolean']>;
  hasCustomOnboardBrick?: InputMaybe<Scalars['Boolean']>;
  importFromBambooHR?: InputMaybe<Scalars['Boolean']>;
  isLive?: InputMaybe<Scalars['Boolean']>;
  legoActivated?: InputMaybe<Scalars['Boolean']>;
  legoColor?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['AWSURL']>;
  name?: InputMaybe<Scalars['String']>;
  oldSystemId?: InputMaybe<Scalars['Int']>;
  passwordLoginEnabled?: InputMaybe<Scalars['Boolean']>;
  petBirthdayActivated?: InputMaybe<Scalars['Boolean']>;
  rosterId?: InputMaybe<Scalars['String']>;
  signAs?: InputMaybe<Scalars['String']>;
  significantOtherBirthdayActivated?: InputMaybe<Scalars['Boolean']>;
  specialNote?: InputMaybe<Scalars['String']>;
  spreadsheetId?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['AWSDate']>;
  subdomain?: InputMaybe<Scalars['String']>;
  subscriptionPriceCurrency?: InputMaybe<Scalars['String']>;
  subscriptionPricePerUser?: InputMaybe<Scalars['Float']>;
  swag?: InputMaybe<Scalars['String']>;
  tissueColor?: InputMaybe<Scalars['String']>;
  workAnniversaryGiftVariation?: InputMaybe<TWorkAnniversaryGiftVariations>;
};

export type TUpdateContactMutationInput = {
  email?: InputMaybe<Scalars['AWSEmail']>;
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type TUpdateDependantMutationInput = {
  birthDate?: InputMaybe<Scalars['AWSDate']>;
  donateBirthdayGift?: InputMaybe<Scalars['Boolean']>;
  firstName?: InputMaybe<Scalars['String']>;
  foodPreferences?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  oldSystemId?: InputMaybe<Scalars['Int']>;
  petType?: InputMaybe<Scalars['String']>;
  preferredFirstName?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<TDependantType>;
};

export type TUpdateEmployeeMutationInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  address?: InputMaybe<TUpdateAddressMutationInput>;
  birthDate?: InputMaybe<Scalars['AWSDate']>;
  customOnboardBrick?: InputMaybe<Scalars['String']>;
  deliveryMethod?: InputMaybe<TDeliveryMethod>;
  donateBirthdayGift?: InputMaybe<Scalars['Boolean']>;
  donateWorkAnniversaryGift?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['AWSEmail']>;
  externalId?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  foodPreferences?: InputMaybe<Scalars['String']>;
  giftOnHoldFrom?: InputMaybe<Scalars['AWSDate']>;
  giftOnHoldTo?: InputMaybe<Scalars['AWSDate']>;
  giftPausedFrom?: InputMaybe<Scalars['AWSDate']>;
  giftPausedTo?: InputMaybe<Scalars['AWSDate']>;
  hireDate?: InputMaybe<Scalars['AWSDate']>;
  lastName?: InputMaybe<Scalars['String']>;
  officeId?: InputMaybe<Scalars['ID']>;
  oldSystemId?: InputMaybe<Scalars['Int']>;
  organization?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  preferredFirstName?: InputMaybe<Scalars['String']>;
  sweaterSize?: InputMaybe<Scalars['String']>;
  tShirtSize?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type TUpdateEventMutationInput = {
  addressId?: InputMaybe<Scalars['ID']>;
  age?: InputMaybe<Scalars['Int']>;
  deliveredAt?: InputMaybe<Scalars['AWSDateTime']>;
  eventDate?: InputMaybe<Scalars['AWSDate']>;
  giftId?: InputMaybe<Scalars['ID']>;
  groupedToEventId?: InputMaybe<Scalars['ID']>;
  isPaused?: InputMaybe<Scalars['Boolean']>;
  wasPaused?: InputMaybe<Scalars['Boolean']>;
};

export type TUpdateGiftEventDataInput = {
  age?: InputMaybe<Scalars['Int']>;
  eventDate: Scalars['AWSDate'];
  groupedToEventId?: InputMaybe<Scalars['ID']>;
  type: TEventTypes;
};

export type TUpdateGiftMutationInput = {
  addressId?: InputMaybe<Scalars['ID']>;
  alerts?: InputMaybe<Array<InputMaybe<TAlertInput>>>;
  eventData?: InputMaybe<Scalars['AWSJSON']>;
  eventDate?: InputMaybe<Scalars['AWSDate']>;
  group?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  rack?: InputMaybe<Scalars['String']>;
  recipientData?: InputMaybe<Scalars['AWSJSON']>;
  shipmentId?: InputMaybe<Scalars['ID']>;
};

export type TUpdateOfficeMutationInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  address?: InputMaybe<TUpdateAddressMutationInput>;
  contact?: InputMaybe<TUpdateContactMutationInput>;
  legalName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  oldSystemId?: InputMaybe<Scalars['Int']>;
};

export type TUpdateReferenceEventMutationInput = {
  addressId?: InputMaybe<Scalars['ID']>;
  fullDate?: InputMaybe<Scalars['AWSDate']>;
};

export type TUpdateRosterFileInput = {
  impoortEndedAt?: InputMaybe<Scalars['AWSDateTime']>;
  importErrors?: InputMaybe<Scalars['AWSJSON']>;
  importStartedAt?: InputMaybe<Scalars['AWSDateTime']>;
};

export type TUpdateShipmentMutationInput = {
  actualShippingDate?: InputMaybe<Scalars['AWSDate']>;
  addressId?: InputMaybe<Scalars['ID']>;
  addressVerified?: InputMaybe<TShipStationAddressVerified>;
  alerts?: InputMaybe<Array<InputMaybe<TAlertInput>>>;
  label?: InputMaybe<TShipmentLabelInput>;
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  rack?: InputMaybe<Scalars['String']>;
  shipStationError?: InputMaybe<Scalars['AWSJSON']>;
  shipStationOrderId?: InputMaybe<Scalars['Int']>;
  shipStationShipmentId?: InputMaybe<Scalars['Int']>;
  trackingNumber?: InputMaybe<Scalars['String']>;
  useGoogleAddress?: InputMaybe<Scalars['Boolean']>;
};

export type TUpdateUserMutationInput = {
  email?: InputMaybe<Scalars['AWSEmail']>;
  googleUserName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<TUserType>;
  userId?: InputMaybe<Scalars['String']>;
};

export type TUser = {
  __typename?: 'User';
  _deleted?: Maybe<Scalars['Boolean']>;
  _lastChangedAt?: Maybe<Scalars['AWSTimestamp']>;
  _version: Scalars['Int'];
  company?: Maybe<TCompany>;
  companyId: Scalars['ID'];
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  email: Scalars['AWSEmail'];
  googleUserName?: Maybe<Scalars['String']>;
  isCompanyOwner: Scalars['Boolean'];
  name: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  type: TUserType;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export enum TUserType {
  Admin = 'admin',
  TeamLeader = 'teamLeader',
}

export type TUsersConnection = {
  __typename?: 'UsersConnection';
  items: Array<Maybe<TUser>>;
  nextToken?: Maybe<Scalars['String']>;
  scannedCount?: Maybe<Scalars['Int']>;
};

export enum TWorkAnniversaryGiftVariations {
  BrickOnly = 'brickOnly',
  Custom = 'custom',
  Deluxe = 'deluxe',
  Mini = 'mini',
}

export type TAddressFieldsFragment = {
  __typename?: 'Address';
  id: string;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  country?: string | null;
  zipCode?: string | null;
  parentType: TAddressParentTypes;
  useGoogleAddress?: boolean | null;
  _version: number;
};

export type TAddressFromGoogleFieldsFragment = {
  __typename?: 'AddressFromGoogle';
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  country?: string | null;
  zipCode?: string | null;
};

export type TAddressFieldsWithGoogleFieldsFragment = {
  __typename?: 'Address';
  id: string;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  country?: string | null;
  zipCode?: string | null;
  parentType: TAddressParentTypes;
  useGoogleAddress?: boolean | null;
  _version: number;
  addressFromGoogle?: {
    __typename?: 'AddressFromGoogle';
    address1?: string | null;
    address2?: string | null;
    city?: string | null;
    state?: string | null;
    country?: string | null;
    zipCode?: string | null;
  } | null;
};

export type TListAllAddressesQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<TOrderDirection>;
}>;

export type TListAllAddressesQuery = {
  __typename?: 'Query';
  listAddresses: {
    __typename?: 'AddressesConnection';
    nextToken?: string | null;
    items: Array<{
      __typename?: 'Address';
      id: string;
      address1?: string | null;
      address2?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      zipCode?: string | null;
      parentType: TAddressParentTypes;
      useGoogleAddress?: boolean | null;
      _version: number;
      addressFromGoogle?: {
        __typename?: 'AddressFromGoogle';
        address1?: string | null;
        address2?: string | null;
        city?: string | null;
        state?: string | null;
        country?: string | null;
        zipCode?: string | null;
      } | null;
    } | null>;
  };
};

export type TGetAddressByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type TGetAddressByIdQuery = {
  __typename?: 'Query';
  getAddressById?: {
    __typename?: 'Address';
    id: string;
    address1?: string | null;
    address2?: string | null;
    city?: string | null;
    state?: string | null;
    country?: string | null;
    zipCode?: string | null;
    parentType: TAddressParentTypes;
    useGoogleAddress?: boolean | null;
    _version: number;
  } | null;
};

export type TListCompanyFieldsFragment = {
  __typename?: 'Company';
  id: string;
  active?: boolean | null;
  isLive?: boolean | null;
  deliveryMethod: TDeliveryMethod;
  name: string;
  fullyConfigured?: boolean | null;
  employeeBirthdayActivated: boolean;
  employeeWorkAnniversaryActivated: boolean;
  employeeNewHireKitActivated: boolean;
  significantOtherBirthdayActivated: boolean;
  childBirthdayActivated: boolean;
  petBirthdayActivated: boolean;
  workAnniversaryGiftVariation?: TWorkAnniversaryGiftVariations | null;
  customWorkAnniversaryNotes?: string | null;
  hasCustomOnboardBrick?: boolean | null;
  tissueColor: string;
  legoColor?: string | null;
  signAs?: string | null;
  specialNote?: string | null;
  swag?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  subscriptionPricePerUser?: number | null;
  subscriptionPriceCurrency?: string | null;
  contact?: { __typename?: 'Contact'; name?: string | null; email?: string | null; phoneNumber?: string | null } | null;
};

export type TCompanyFieldsFragment = {
  __typename?: 'Company';
  rosterId?: string | null;
  subdomain: string;
  emailDomains: Array<string>;
  googleLoginEnabled?: boolean | null;
  logo?: string | null;
  importFromBambooHR: boolean;
  bambooHRSubdomain?: string | null;
  bambooHRAPIKey?: string | null;
  bambooHRPetsTableName?: string | null;
  _version: number;
  _deleted?: boolean | null;
  id: string;
  active?: boolean | null;
  isLive?: boolean | null;
  deliveryMethod: TDeliveryMethod;
  name: string;
  fullyConfigured?: boolean | null;
  employeeBirthdayActivated: boolean;
  employeeWorkAnniversaryActivated: boolean;
  employeeNewHireKitActivated: boolean;
  significantOtherBirthdayActivated: boolean;
  childBirthdayActivated: boolean;
  petBirthdayActivated: boolean;
  workAnniversaryGiftVariation?: TWorkAnniversaryGiftVariations | null;
  customWorkAnniversaryNotes?: string | null;
  hasCustomOnboardBrick?: boolean | null;
  tissueColor: string;
  legoColor?: string | null;
  signAs?: string | null;
  specialNote?: string | null;
  swag?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  subscriptionPricePerUser?: number | null;
  subscriptionPriceCurrency?: string | null;
  contact?: { __typename?: 'Contact'; name?: string | null; email?: string | null; phoneNumber?: string | null } | null;
};

export type TCompanyWithAddressFieldsFragment = {
  __typename?: 'Company';
  rosterId?: string | null;
  subdomain: string;
  emailDomains: Array<string>;
  googleLoginEnabled?: boolean | null;
  logo?: string | null;
  importFromBambooHR: boolean;
  bambooHRSubdomain?: string | null;
  bambooHRAPIKey?: string | null;
  bambooHRPetsTableName?: string | null;
  _version: number;
  _deleted?: boolean | null;
  id: string;
  active?: boolean | null;
  isLive?: boolean | null;
  deliveryMethod: TDeliveryMethod;
  name: string;
  fullyConfigured?: boolean | null;
  employeeBirthdayActivated: boolean;
  employeeWorkAnniversaryActivated: boolean;
  employeeNewHireKitActivated: boolean;
  significantOtherBirthdayActivated: boolean;
  childBirthdayActivated: boolean;
  petBirthdayActivated: boolean;
  workAnniversaryGiftVariation?: TWorkAnniversaryGiftVariations | null;
  customWorkAnniversaryNotes?: string | null;
  hasCustomOnboardBrick?: boolean | null;
  tissueColor: string;
  legoColor?: string | null;
  signAs?: string | null;
  specialNote?: string | null;
  swag?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  subscriptionPricePerUser?: number | null;
  subscriptionPriceCurrency?: string | null;
  address?: {
    __typename?: 'Address';
    id: string;
    address1?: string | null;
    address2?: string | null;
    city?: string | null;
    state?: string | null;
    country?: string | null;
    zipCode?: string | null;
    parentType: TAddressParentTypes;
    useGoogleAddress?: boolean | null;
    _version: number;
    addressFromGoogle?: {
      __typename?: 'AddressFromGoogle';
      address1?: string | null;
      address2?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      zipCode?: string | null;
    } | null;
  } | null;
  contact?: { __typename?: 'Contact'; name?: string | null; email?: string | null; phoneNumber?: string | null } | null;
};

export type TListCompaniesQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<TOrderDirection>;
}>;

export type TListCompaniesQuery = {
  __typename?: 'Query';
  listCompanies: {
    __typename?: 'CompaniesConnection';
    nextToken?: string | null;
    items: Array<{
      __typename?: 'Company';
      id: string;
      active?: boolean | null;
      isLive?: boolean | null;
      deliveryMethod: TDeliveryMethod;
      name: string;
      fullyConfigured?: boolean | null;
      employeeBirthdayActivated: boolean;
      employeeWorkAnniversaryActivated: boolean;
      employeeNewHireKitActivated: boolean;
      significantOtherBirthdayActivated: boolean;
      childBirthdayActivated: boolean;
      petBirthdayActivated: boolean;
      workAnniversaryGiftVariation?: TWorkAnniversaryGiftVariations | null;
      customWorkAnniversaryNotes?: string | null;
      hasCustomOnboardBrick?: boolean | null;
      tissueColor: string;
      legoColor?: string | null;
      signAs?: string | null;
      specialNote?: string | null;
      swag?: string | null;
      startDate?: string | null;
      endDate?: string | null;
      subscriptionPricePerUser?: number | null;
      subscriptionPriceCurrency?: string | null;
      contact?: {
        __typename?: 'Contact';
        name?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
      } | null;
    } | null>;
  };
};

export type TGetCompanyQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type TGetCompanyQuery = {
  __typename?: 'Query';
  getCompany?: {
    __typename?: 'Company';
    rosterId?: string | null;
    subdomain: string;
    emailDomains: Array<string>;
    googleLoginEnabled?: boolean | null;
    logo?: string | null;
    importFromBambooHR: boolean;
    bambooHRSubdomain?: string | null;
    bambooHRAPIKey?: string | null;
    bambooHRPetsTableName?: string | null;
    _version: number;
    _deleted?: boolean | null;
    id: string;
    active?: boolean | null;
    isLive?: boolean | null;
    deliveryMethod: TDeliveryMethod;
    name: string;
    fullyConfigured?: boolean | null;
    employeeBirthdayActivated: boolean;
    employeeWorkAnniversaryActivated: boolean;
    employeeNewHireKitActivated: boolean;
    significantOtherBirthdayActivated: boolean;
    childBirthdayActivated: boolean;
    petBirthdayActivated: boolean;
    workAnniversaryGiftVariation?: TWorkAnniversaryGiftVariations | null;
    customWorkAnniversaryNotes?: string | null;
    hasCustomOnboardBrick?: boolean | null;
    tissueColor: string;
    legoColor?: string | null;
    signAs?: string | null;
    specialNote?: string | null;
    swag?: string | null;
    startDate?: string | null;
    endDate?: string | null;
    subscriptionPricePerUser?: number | null;
    subscriptionPriceCurrency?: string | null;
    address?: {
      __typename?: 'Address';
      id: string;
      address1?: string | null;
      address2?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      zipCode?: string | null;
      parentType: TAddressParentTypes;
      useGoogleAddress?: boolean | null;
      _version: number;
      addressFromGoogle?: {
        __typename?: 'AddressFromGoogle';
        address1?: string | null;
        address2?: string | null;
        city?: string | null;
        state?: string | null;
        country?: string | null;
        zipCode?: string | null;
      } | null;
    } | null;
    contact?: {
      __typename?: 'Contact';
      name?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
    } | null;
  } | null;
};

export type TCreateCompanyMutationVariables = Exact<{
  input: TCreateCompanyMutationInput;
}>;

export type TCreateCompanyMutation = {
  __typename?: 'Mutation';
  createCompany?: {
    __typename?: 'Company';
    rosterId?: string | null;
    subdomain: string;
    emailDomains: Array<string>;
    googleLoginEnabled?: boolean | null;
    logo?: string | null;
    importFromBambooHR: boolean;
    bambooHRSubdomain?: string | null;
    bambooHRAPIKey?: string | null;
    bambooHRPetsTableName?: string | null;
    _version: number;
    _deleted?: boolean | null;
    id: string;
    active?: boolean | null;
    isLive?: boolean | null;
    deliveryMethod: TDeliveryMethod;
    name: string;
    fullyConfigured?: boolean | null;
    employeeBirthdayActivated: boolean;
    employeeWorkAnniversaryActivated: boolean;
    employeeNewHireKitActivated: boolean;
    significantOtherBirthdayActivated: boolean;
    childBirthdayActivated: boolean;
    petBirthdayActivated: boolean;
    workAnniversaryGiftVariation?: TWorkAnniversaryGiftVariations | null;
    customWorkAnniversaryNotes?: string | null;
    hasCustomOnboardBrick?: boolean | null;
    tissueColor: string;
    legoColor?: string | null;
    signAs?: string | null;
    specialNote?: string | null;
    swag?: string | null;
    startDate?: string | null;
    endDate?: string | null;
    subscriptionPricePerUser?: number | null;
    subscriptionPriceCurrency?: string | null;
    address?: {
      __typename?: 'Address';
      id: string;
      address1?: string | null;
      address2?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      zipCode?: string | null;
      parentType: TAddressParentTypes;
      useGoogleAddress?: boolean | null;
      _version: number;
      addressFromGoogle?: {
        __typename?: 'AddressFromGoogle';
        address1?: string | null;
        address2?: string | null;
        city?: string | null;
        state?: string | null;
        country?: string | null;
        zipCode?: string | null;
      } | null;
    } | null;
    contact?: {
      __typename?: 'Contact';
      name?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
    } | null;
  } | null;
};

export type TUpdateCompanyMutationVariables = Exact<{
  id: Scalars['ID'];
  _version: Scalars['Int'];
  input: TUpdateCompanyMutationInput;
}>;

export type TUpdateCompanyMutation = {
  __typename?: 'Mutation';
  updateCompany?: {
    __typename?: 'Company';
    rosterId?: string | null;
    subdomain: string;
    emailDomains: Array<string>;
    googleLoginEnabled?: boolean | null;
    logo?: string | null;
    importFromBambooHR: boolean;
    bambooHRSubdomain?: string | null;
    bambooHRAPIKey?: string | null;
    bambooHRPetsTableName?: string | null;
    _version: number;
    _deleted?: boolean | null;
    id: string;
    active?: boolean | null;
    isLive?: boolean | null;
    deliveryMethod: TDeliveryMethod;
    name: string;
    fullyConfigured?: boolean | null;
    employeeBirthdayActivated: boolean;
    employeeWorkAnniversaryActivated: boolean;
    employeeNewHireKitActivated: boolean;
    significantOtherBirthdayActivated: boolean;
    childBirthdayActivated: boolean;
    petBirthdayActivated: boolean;
    workAnniversaryGiftVariation?: TWorkAnniversaryGiftVariations | null;
    customWorkAnniversaryNotes?: string | null;
    hasCustomOnboardBrick?: boolean | null;
    tissueColor: string;
    legoColor?: string | null;
    signAs?: string | null;
    specialNote?: string | null;
    swag?: string | null;
    startDate?: string | null;
    endDate?: string | null;
    subscriptionPricePerUser?: number | null;
    subscriptionPriceCurrency?: string | null;
    address?: {
      __typename?: 'Address';
      id: string;
      address1?: string | null;
      address2?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      zipCode?: string | null;
      parentType: TAddressParentTypes;
      useGoogleAddress?: boolean | null;
      _version: number;
      addressFromGoogle?: {
        __typename?: 'AddressFromGoogle';
        address1?: string | null;
        address2?: string | null;
        city?: string | null;
        state?: string | null;
        country?: string | null;
        zipCode?: string | null;
      } | null;
    } | null;
    contact?: {
      __typename?: 'Contact';
      name?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
    } | null;
  } | null;
};

export type TDeleteCompanyMutationVariables = Exact<{
  id: Scalars['ID'];
  _version: Scalars['Int'];
}>;

export type TDeleteCompanyMutation = {
  __typename?: 'Mutation';
  deleteCompany?: {
    __typename?: 'Company';
    rosterId?: string | null;
    subdomain: string;
    emailDomains: Array<string>;
    googleLoginEnabled?: boolean | null;
    logo?: string | null;
    importFromBambooHR: boolean;
    bambooHRSubdomain?: string | null;
    bambooHRAPIKey?: string | null;
    bambooHRPetsTableName?: string | null;
    _version: number;
    _deleted?: boolean | null;
    id: string;
    active?: boolean | null;
    isLive?: boolean | null;
    deliveryMethod: TDeliveryMethod;
    name: string;
    fullyConfigured?: boolean | null;
    employeeBirthdayActivated: boolean;
    employeeWorkAnniversaryActivated: boolean;
    employeeNewHireKitActivated: boolean;
    significantOtherBirthdayActivated: boolean;
    childBirthdayActivated: boolean;
    petBirthdayActivated: boolean;
    workAnniversaryGiftVariation?: TWorkAnniversaryGiftVariations | null;
    customWorkAnniversaryNotes?: string | null;
    hasCustomOnboardBrick?: boolean | null;
    tissueColor: string;
    legoColor?: string | null;
    signAs?: string | null;
    specialNote?: string | null;
    swag?: string | null;
    startDate?: string | null;
    endDate?: string | null;
    subscriptionPricePerUser?: number | null;
    subscriptionPriceCurrency?: string | null;
    contact?: {
      __typename?: 'Contact';
      name?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
    } | null;
  } | null;
};

export type TAnyCompanyChangedFieldsFragment = {
  __typename?: 'Company';
  rosterId?: string | null;
  subdomain: string;
  emailDomains: Array<string>;
  googleLoginEnabled?: boolean | null;
  logo?: string | null;
  importFromBambooHR: boolean;
  bambooHRSubdomain?: string | null;
  bambooHRAPIKey?: string | null;
  bambooHRPetsTableName?: string | null;
  _version: number;
  _deleted?: boolean | null;
  id: string;
  active?: boolean | null;
  isLive?: boolean | null;
  deliveryMethod: TDeliveryMethod;
  name: string;
  fullyConfigured?: boolean | null;
  employeeBirthdayActivated: boolean;
  employeeWorkAnniversaryActivated: boolean;
  employeeNewHireKitActivated: boolean;
  significantOtherBirthdayActivated: boolean;
  childBirthdayActivated: boolean;
  petBirthdayActivated: boolean;
  workAnniversaryGiftVariation?: TWorkAnniversaryGiftVariations | null;
  customWorkAnniversaryNotes?: string | null;
  hasCustomOnboardBrick?: boolean | null;
  tissueColor: string;
  legoColor?: string | null;
  signAs?: string | null;
  specialNote?: string | null;
  swag?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  subscriptionPricePerUser?: number | null;
  subscriptionPriceCurrency?: string | null;
  contact?: { __typename?: 'Contact'; name?: string | null; email?: string | null; phoneNumber?: string | null } | null;
};

export type TOnAnyCompanyChangedSubscriptionVariables = Exact<{ [key: string]: never }>;

export type TOnAnyCompanyChangedSubscription = {
  __typename?: 'Subscription';
  onAnyCompanyChanged?: {
    __typename?: 'Company';
    rosterId?: string | null;
    subdomain: string;
    emailDomains: Array<string>;
    googleLoginEnabled?: boolean | null;
    logo?: string | null;
    importFromBambooHR: boolean;
    bambooHRSubdomain?: string | null;
    bambooHRAPIKey?: string | null;
    bambooHRPetsTableName?: string | null;
    _version: number;
    _deleted?: boolean | null;
    id: string;
    active?: boolean | null;
    isLive?: boolean | null;
    deliveryMethod: TDeliveryMethod;
    name: string;
    fullyConfigured?: boolean | null;
    employeeBirthdayActivated: boolean;
    employeeWorkAnniversaryActivated: boolean;
    employeeNewHireKitActivated: boolean;
    significantOtherBirthdayActivated: boolean;
    childBirthdayActivated: boolean;
    petBirthdayActivated: boolean;
    workAnniversaryGiftVariation?: TWorkAnniversaryGiftVariations | null;
    customWorkAnniversaryNotes?: string | null;
    hasCustomOnboardBrick?: boolean | null;
    tissueColor: string;
    legoColor?: string | null;
    signAs?: string | null;
    specialNote?: string | null;
    swag?: string | null;
    startDate?: string | null;
    endDate?: string | null;
    subscriptionPricePerUser?: number | null;
    subscriptionPriceCurrency?: string | null;
    contact?: {
      __typename?: 'Contact';
      name?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
    } | null;
  } | null;
};

export type TContactFieldsFragment = {
  __typename?: 'Contact';
  name?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
};

export type TBasicDependantFieldsFragment = {
  __typename?: 'Dependant';
  id: string;
  companyId: string;
  employeeId: string;
  firstName?: string | null;
  fullName?: string | null;
  lastName?: string | null;
  birthDate?: string | null;
  type: TDependantType;
  petType?: string | null;
  foodPreferences?: string | null;
  donateBirthdayGift?: boolean | null;
  _version: number;
  _deleted?: boolean | null;
};

export type TDependantWithEmployeeFieldsFragment = {
  __typename?: 'Dependant';
  id: string;
  companyId: string;
  employeeId: string;
  firstName?: string | null;
  fullName?: string | null;
  lastName?: string | null;
  birthDate?: string | null;
  type: TDependantType;
  petType?: string | null;
  foodPreferences?: string | null;
  donateBirthdayGift?: boolean | null;
  _version: number;
  _deleted?: boolean | null;
  employee?: {
    __typename?: 'Employee';
    id: string;
    active: boolean;
    externalId?: string | null;
    companyId: string;
    officeId?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    preferredFirstName?: string | null;
    fullName?: string | null;
    hireDate?: string | null;
    birthDate?: string | null;
    email?: string | null;
    phoneNumber?: Array<string | null> | null;
    foodPreferences?: string | null;
    customOnboardBrick?: string | null;
    title?: string | null;
    organization?: string | null;
    tShirtSize?: string | null;
    sweaterSize?: string | null;
    donateBirthdayGift?: boolean | null;
    donateWorkAnniversaryGift?: boolean | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    _version: number;
    _deleted?: boolean | null;
  } | null;
};

export type TListDependantsQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<TOrderDirection>;
}>;

export type TListDependantsQuery = {
  __typename?: 'Query';
  listDependants: {
    __typename?: 'DependantsConnection';
    nextToken?: string | null;
    items: Array<{
      __typename?: 'Dependant';
      id: string;
      companyId: string;
      employeeId: string;
      firstName?: string | null;
      fullName?: string | null;
      lastName?: string | null;
      birthDate?: string | null;
      type: TDependantType;
      petType?: string | null;
      foodPreferences?: string | null;
      donateBirthdayGift?: boolean | null;
      _version: number;
      _deleted?: boolean | null;
    } | null>;
  };
};

export type TListEmployeeDependantsQueryVariables = Exact<{
  companyId: Scalars['ID'];
  employeeId: Scalars['ID'];
  nextToken?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<TOrderDirection>;
}>;

export type TListEmployeeDependantsQuery = {
  __typename?: 'Query';
  listEmployeeDependants: {
    __typename?: 'DependantsConnection';
    nextToken?: string | null;
    items: Array<{
      __typename?: 'Dependant';
      id: string;
      companyId: string;
      employeeId: string;
      firstName?: string | null;
      fullName?: string | null;
      lastName?: string | null;
      birthDate?: string | null;
      type: TDependantType;
      petType?: string | null;
      foodPreferences?: string | null;
      donateBirthdayGift?: boolean | null;
      _version: number;
      _deleted?: boolean | null;
    } | null>;
  };
};

export type TAnyDependantChangedFieldsFragment = {
  __typename?: 'Dependant';
  id: string;
  companyId: string;
  employeeId: string;
  firstName?: string | null;
  fullName?: string | null;
  lastName?: string | null;
  birthDate?: string | null;
  type: TDependantType;
  petType?: string | null;
  foodPreferences?: string | null;
  donateBirthdayGift?: boolean | null;
  _version: number;
  _deleted?: boolean | null;
};

export type TOnAnyDependantChangedSubscriptionVariables = Exact<{ [key: string]: never }>;

export type TOnAnyDependantChangedSubscription = {
  __typename?: 'Subscription';
  onAnyDependantChanged?: {
    __typename?: 'Dependant';
    id: string;
    companyId: string;
    employeeId: string;
    firstName?: string | null;
    fullName?: string | null;
    lastName?: string | null;
    birthDate?: string | null;
    type: TDependantType;
    petType?: string | null;
    foodPreferences?: string | null;
    donateBirthdayGift?: boolean | null;
    _version: number;
    _deleted?: boolean | null;
  } | null;
};

export type TBasicEmployeeFieldsFragment = {
  __typename?: 'Employee';
  id: string;
  active: boolean;
  externalId?: string | null;
  companyId: string;
  officeId?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  preferredFirstName?: string | null;
  fullName?: string | null;
  hireDate?: string | null;
  birthDate?: string | null;
  email?: string | null;
  phoneNumber?: Array<string | null> | null;
  foodPreferences?: string | null;
  customOnboardBrick?: string | null;
  title?: string | null;
  organization?: string | null;
  tShirtSize?: string | null;
  sweaterSize?: string | null;
  donateBirthdayGift?: boolean | null;
  donateWorkAnniversaryGift?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  _version: number;
  _deleted?: boolean | null;
};

export type TEmployeeFieldsFragment = {
  __typename?: 'Employee';
  id: string;
  active: boolean;
  externalId?: string | null;
  companyId: string;
  officeId?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  preferredFirstName?: string | null;
  fullName?: string | null;
  hireDate?: string | null;
  birthDate?: string | null;
  email?: string | null;
  phoneNumber?: Array<string | null> | null;
  foodPreferences?: string | null;
  customOnboardBrick?: string | null;
  title?: string | null;
  organization?: string | null;
  tShirtSize?: string | null;
  sweaterSize?: string | null;
  donateBirthdayGift?: boolean | null;
  donateWorkAnniversaryGift?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  _version: number;
  _deleted?: boolean | null;
};

export type TEmployeeWithAddressFieldsFragment = {
  __typename?: 'Employee';
  id: string;
  active: boolean;
  externalId?: string | null;
  companyId: string;
  officeId?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  preferredFirstName?: string | null;
  fullName?: string | null;
  hireDate?: string | null;
  birthDate?: string | null;
  email?: string | null;
  phoneNumber?: Array<string | null> | null;
  foodPreferences?: string | null;
  customOnboardBrick?: string | null;
  title?: string | null;
  organization?: string | null;
  tShirtSize?: string | null;
  sweaterSize?: string | null;
  donateBirthdayGift?: boolean | null;
  donateWorkAnniversaryGift?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  _version: number;
  _deleted?: boolean | null;
  address?: {
    __typename?: 'Address';
    id: string;
    address1?: string | null;
    address2?: string | null;
    city?: string | null;
    state?: string | null;
    country?: string | null;
    zipCode?: string | null;
    parentType: TAddressParentTypes;
    useGoogleAddress?: boolean | null;
    _version: number;
    addressFromGoogle?: {
      __typename?: 'AddressFromGoogle';
      address1?: string | null;
      address2?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      zipCode?: string | null;
    } | null;
  } | null;
};

export type TEmployeeWithDependantsFieldsFragment = {
  __typename?: 'Employee';
  id: string;
  active: boolean;
  externalId?: string | null;
  companyId: string;
  officeId?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  preferredFirstName?: string | null;
  fullName?: string | null;
  hireDate?: string | null;
  birthDate?: string | null;
  email?: string | null;
  phoneNumber?: Array<string | null> | null;
  foodPreferences?: string | null;
  customOnboardBrick?: string | null;
  title?: string | null;
  organization?: string | null;
  tShirtSize?: string | null;
  sweaterSize?: string | null;
  donateBirthdayGift?: boolean | null;
  donateWorkAnniversaryGift?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  _version: number;
  _deleted?: boolean | null;
  dependants: {
    __typename?: 'DependantsConnection';
    items: Array<{
      __typename?: 'Dependant';
      id: string;
      companyId: string;
      employeeId: string;
      firstName?: string | null;
      fullName?: string | null;
      lastName?: string | null;
      birthDate?: string | null;
      type: TDependantType;
      petType?: string | null;
      foodPreferences?: string | null;
      donateBirthdayGift?: boolean | null;
      _version: number;
      _deleted?: boolean | null;
    } | null>;
  };
};

export type TGetEmployeeQueryVariables = Exact<{
  id: Scalars['ID'];
  companyId: Scalars['ID'];
}>;

export type TGetEmployeeQuery = {
  __typename?: 'Query';
  getEmployee?: {
    __typename?: 'Employee';
    id: string;
    active: boolean;
    externalId?: string | null;
    companyId: string;
    officeId?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    preferredFirstName?: string | null;
    fullName?: string | null;
    hireDate?: string | null;
    birthDate?: string | null;
    email?: string | null;
    phoneNumber?: Array<string | null> | null;
    foodPreferences?: string | null;
    customOnboardBrick?: string | null;
    title?: string | null;
    organization?: string | null;
    tShirtSize?: string | null;
    sweaterSize?: string | null;
    donateBirthdayGift?: boolean | null;
    donateWorkAnniversaryGift?: boolean | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    _version: number;
    _deleted?: boolean | null;
    address?: {
      __typename?: 'Address';
      id: string;
      address1?: string | null;
      address2?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      zipCode?: string | null;
      parentType: TAddressParentTypes;
      useGoogleAddress?: boolean | null;
      _version: number;
      addressFromGoogle?: {
        __typename?: 'AddressFromGoogle';
        address1?: string | null;
        address2?: string | null;
        city?: string | null;
        state?: string | null;
        country?: string | null;
        zipCode?: string | null;
      } | null;
    } | null;
  } | null;
};

export type TListEmployeesQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<TOrderDirection>;
}>;

export type TListEmployeesQuery = {
  __typename?: 'Query';
  listEmployees: {
    __typename?: 'EmployeesConnection';
    nextToken?: string | null;
    items: Array<{
      __typename?: 'Employee';
      id: string;
      active: boolean;
      externalId?: string | null;
      companyId: string;
      officeId?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      preferredFirstName?: string | null;
      fullName?: string | null;
      hireDate?: string | null;
      birthDate?: string | null;
      email?: string | null;
      phoneNumber?: Array<string | null> | null;
      foodPreferences?: string | null;
      customOnboardBrick?: string | null;
      title?: string | null;
      organization?: string | null;
      tShirtSize?: string | null;
      sweaterSize?: string | null;
      donateBirthdayGift?: boolean | null;
      donateWorkAnniversaryGift?: boolean | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      _version: number;
      _deleted?: boolean | null;
    } | null>;
  };
};

export type TListCompanyEmployeesWithDependantsQueryVariables = Exact<{
  companyId: Scalars['ID'];
  nextToken?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<TOrderDirection>;
}>;

export type TListCompanyEmployeesWithDependantsQuery = {
  __typename?: 'Query';
  listCompanyEmployees: {
    __typename?: 'EmployeesConnection';
    nextToken?: string | null;
    items: Array<{
      __typename?: 'Employee';
      id: string;
      active: boolean;
      externalId?: string | null;
      companyId: string;
      officeId?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      preferredFirstName?: string | null;
      fullName?: string | null;
      hireDate?: string | null;
      birthDate?: string | null;
      email?: string | null;
      phoneNumber?: Array<string | null> | null;
      foodPreferences?: string | null;
      customOnboardBrick?: string | null;
      title?: string | null;
      organization?: string | null;
      tShirtSize?: string | null;
      sweaterSize?: string | null;
      donateBirthdayGift?: boolean | null;
      donateWorkAnniversaryGift?: boolean | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      _version: number;
      _deleted?: boolean | null;
      dependants: {
        __typename?: 'DependantsConnection';
        items: Array<{
          __typename?: 'Dependant';
          id: string;
          companyId: string;
          employeeId: string;
          firstName?: string | null;
          fullName?: string | null;
          lastName?: string | null;
          birthDate?: string | null;
          type: TDependantType;
          petType?: string | null;
          foodPreferences?: string | null;
          donateBirthdayGift?: boolean | null;
          _version: number;
          _deleted?: boolean | null;
        } | null>;
      };
    } | null>;
  };
};

export type TListCompanyEmployeesQueryVariables = Exact<{
  companyId: Scalars['ID'];
  nextToken?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<TOrderDirection>;
}>;

export type TListCompanyEmployeesQuery = {
  __typename?: 'Query';
  listCompanyEmployees: {
    __typename?: 'EmployeesConnection';
    nextToken?: string | null;
    items: Array<{
      __typename?: 'Employee';
      id: string;
      active: boolean;
      externalId?: string | null;
      companyId: string;
      officeId?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      preferredFirstName?: string | null;
      fullName?: string | null;
      hireDate?: string | null;
      birthDate?: string | null;
      email?: string | null;
      phoneNumber?: Array<string | null> | null;
      foodPreferences?: string | null;
      customOnboardBrick?: string | null;
      title?: string | null;
      organization?: string | null;
      tShirtSize?: string | null;
      sweaterSize?: string | null;
      donateBirthdayGift?: boolean | null;
      donateWorkAnniversaryGift?: boolean | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      _version: number;
      _deleted?: boolean | null;
    } | null>;
  };
};

export type TAnyEmployeeChangedFieldsFragment = {
  __typename?: 'Employee';
  id: string;
  active: boolean;
  externalId?: string | null;
  companyId: string;
  officeId?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  preferredFirstName?: string | null;
  fullName?: string | null;
  hireDate?: string | null;
  birthDate?: string | null;
  email?: string | null;
  phoneNumber?: Array<string | null> | null;
  foodPreferences?: string | null;
  customOnboardBrick?: string | null;
  title?: string | null;
  organization?: string | null;
  tShirtSize?: string | null;
  sweaterSize?: string | null;
  donateBirthdayGift?: boolean | null;
  donateWorkAnniversaryGift?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  _version: number;
  _deleted?: boolean | null;
};

export type TOnAnyEmployeeChangedSubscriptionVariables = Exact<{ [key: string]: never }>;

export type TOnAnyEmployeeChangedSubscription = {
  __typename?: 'Subscription';
  onAnyEmployeeChanged?: {
    __typename?: 'Employee';
    id: string;
    active: boolean;
    externalId?: string | null;
    companyId: string;
    officeId?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    preferredFirstName?: string | null;
    fullName?: string | null;
    hireDate?: string | null;
    birthDate?: string | null;
    email?: string | null;
    phoneNumber?: Array<string | null> | null;
    foodPreferences?: string | null;
    customOnboardBrick?: string | null;
    title?: string | null;
    organization?: string | null;
    tShirtSize?: string | null;
    sweaterSize?: string | null;
    donateBirthdayGift?: boolean | null;
    donateWorkAnniversaryGift?: boolean | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    _version: number;
    _deleted?: boolean | null;
  } | null;
};

export type TBasicEventFieldsFragment = {
  __typename?: 'Event';
  id: string;
  companyId: string;
  employeeId: string;
  recipientId: string;
  addressId?: string | null;
  referenceEventId?: string | null;
  giftId?: string | null;
  eventDate: string;
  type: TEventTypes;
  customType?: string | null;
  age?: number | null;
  skippedAt?: string | null;
  deliveredAt?: string | null;
  groupedToEventId?: string | null;
  isPaused?: boolean | null;
  wasPaused?: boolean | null;
  _version: number;
  _deleted?: boolean | null;
};

export type TBasicReferenceEventsFragment = {
  __typename?: 'ReferenceEvent';
  id: string;
  companyId: string;
  employeeId: string;
  recipientId: string;
  addressId?: string | null;
  fullDate: string;
  date: string;
  type: TReferenceEventTypes;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt?: number | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  company?: { __typename?: 'Company'; name: string } | null;
  employee?: { __typename?: 'Employee'; fullName?: string | null } | null;
  recipient?:
    | {
        __typename?: 'Dependant';
        id: string;
        companyId: string;
        employeeId: string;
        firstName?: string | null;
        fullName?: string | null;
        lastName?: string | null;
        birthDate?: string | null;
        type: TDependantType;
        petType?: string | null;
        foodPreferences?: string | null;
        donateBirthdayGift?: boolean | null;
        _version: number;
        _deleted?: boolean | null;
        employee?: {
          __typename?: 'Employee';
          id: string;
          active: boolean;
          externalId?: string | null;
          companyId: string;
          officeId?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          preferredFirstName?: string | null;
          fullName?: string | null;
          hireDate?: string | null;
          birthDate?: string | null;
          email?: string | null;
          phoneNumber?: Array<string | null> | null;
          foodPreferences?: string | null;
          customOnboardBrick?: string | null;
          title?: string | null;
          organization?: string | null;
          tShirtSize?: string | null;
          sweaterSize?: string | null;
          donateBirthdayGift?: boolean | null;
          donateWorkAnniversaryGift?: boolean | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          _version: number;
          _deleted?: boolean | null;
        } | null;
      }
    | {
        __typename?: 'Employee';
        id: string;
        active: boolean;
        externalId?: string | null;
        companyId: string;
        officeId?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        preferredFirstName?: string | null;
        fullName?: string | null;
        hireDate?: string | null;
        birthDate?: string | null;
        email?: string | null;
        phoneNumber?: Array<string | null> | null;
        foodPreferences?: string | null;
        customOnboardBrick?: string | null;
        title?: string | null;
        organization?: string | null;
        tShirtSize?: string | null;
        sweaterSize?: string | null;
        donateBirthdayGift?: boolean | null;
        donateWorkAnniversaryGift?: boolean | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        _version: number;
        _deleted?: boolean | null;
      }
    | null;
  address?: { __typename?: 'Address'; id: string; address1?: string | null } | null;
};

export type TEventFieldsFragment = {
  __typename?: 'Event';
  id: string;
  companyId: string;
  employeeId: string;
  recipientId: string;
  addressId?: string | null;
  referenceEventId?: string | null;
  giftId?: string | null;
  eventDate: string;
  type: TEventTypes;
  customType?: string | null;
  age?: number | null;
  skippedAt?: string | null;
  deliveredAt?: string | null;
  groupedToEventId?: string | null;
  isPaused?: boolean | null;
  wasPaused?: boolean | null;
  _version: number;
  _deleted?: boolean | null;
};

export type TGetEventQueryVariables = Exact<{
  id: Scalars['ID'];
  companyId: Scalars['ID'];
}>;

export type TGetEventQuery = {
  __typename?: 'Query';
  getEvent?: {
    __typename?: 'Event';
    id: string;
    companyId: string;
    employeeId: string;
    recipientId: string;
    addressId?: string | null;
    referenceEventId?: string | null;
    giftId?: string | null;
    eventDate: string;
    type: TEventTypes;
    customType?: string | null;
    age?: number | null;
    skippedAt?: string | null;
    deliveredAt?: string | null;
    groupedToEventId?: string | null;
    isPaused?: boolean | null;
    wasPaused?: boolean | null;
    _version: number;
    _deleted?: boolean | null;
  } | null;
};

export type TListNotSkippedEventsWithoutGiftQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<TOrderDirection>;
}>;

export type TListNotSkippedEventsWithoutGiftQuery = {
  __typename?: 'Query';
  listNotSkippedEventsWithoutGift: {
    __typename?: 'EventsConnection';
    nextToken?: string | null;
    items: Array<{
      __typename?: 'Event';
      id: string;
      companyId: string;
      employeeId: string;
      recipientId: string;
      addressId?: string | null;
      referenceEventId?: string | null;
      giftId?: string | null;
      eventDate: string;
      type: TEventTypes;
      customType?: string | null;
      age?: number | null;
      skippedAt?: string | null;
      deliveredAt?: string | null;
      groupedToEventId?: string | null;
      isPaused?: boolean | null;
      wasPaused?: boolean | null;
      _version: number;
      _deleted?: boolean | null;
    } | null>;
  };
};

export type TListSkippedEventsQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<TOrderDirection>;
}>;

export type TListSkippedEventsQuery = {
  __typename?: 'Query';
  listSkippedEvents: {
    __typename?: 'EventsConnection';
    nextToken?: string | null;
    items: Array<{
      __typename?: 'Event';
      id: string;
      companyId: string;
      employeeId: string;
      recipientId: string;
      addressId?: string | null;
      referenceEventId?: string | null;
      giftId?: string | null;
      eventDate: string;
      type: TEventTypes;
      customType?: string | null;
      age?: number | null;
      skippedAt?: string | null;
      deliveredAt?: string | null;
      groupedToEventId?: string | null;
      isPaused?: boolean | null;
      wasPaused?: boolean | null;
      _version: number;
      _deleted?: boolean | null;
    } | null>;
  };
};

export type TListEmployeeReferenceEventsQueryVariables = Exact<{
  employeeId: Scalars['ID'];
  companyId: Scalars['ID'];
  nextToken?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<TOrderDirection>;
}>;

export type TListEmployeeReferenceEventsQuery = {
  __typename?: 'Query';
  listEmployeeReferenceEvents: {
    __typename?: 'ReferenceEventsConnection';
    items: Array<{
      __typename?: 'ReferenceEvent';
      id: string;
      companyId: string;
      employeeId: string;
      recipientId: string;
      addressId?: string | null;
      fullDate: string;
      date: string;
      type: TReferenceEventTypes;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt?: number | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      company?: { __typename?: 'Company'; name: string } | null;
      employee?: { __typename?: 'Employee'; fullName?: string | null } | null;
      recipient?:
        | {
            __typename?: 'Dependant';
            id: string;
            companyId: string;
            employeeId: string;
            firstName?: string | null;
            fullName?: string | null;
            lastName?: string | null;
            birthDate?: string | null;
            type: TDependantType;
            petType?: string | null;
            foodPreferences?: string | null;
            donateBirthdayGift?: boolean | null;
            _version: number;
            _deleted?: boolean | null;
            employee?: {
              __typename?: 'Employee';
              id: string;
              active: boolean;
              externalId?: string | null;
              companyId: string;
              officeId?: string | null;
              firstName?: string | null;
              lastName?: string | null;
              preferredFirstName?: string | null;
              fullName?: string | null;
              hireDate?: string | null;
              birthDate?: string | null;
              email?: string | null;
              phoneNumber?: Array<string | null> | null;
              foodPreferences?: string | null;
              customOnboardBrick?: string | null;
              title?: string | null;
              organization?: string | null;
              tShirtSize?: string | null;
              sweaterSize?: string | null;
              donateBirthdayGift?: boolean | null;
              donateWorkAnniversaryGift?: boolean | null;
              createdAt?: string | null;
              updatedAt?: string | null;
              _version: number;
              _deleted?: boolean | null;
            } | null;
          }
        | {
            __typename?: 'Employee';
            id: string;
            active: boolean;
            externalId?: string | null;
            companyId: string;
            officeId?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            preferredFirstName?: string | null;
            fullName?: string | null;
            hireDate?: string | null;
            birthDate?: string | null;
            email?: string | null;
            phoneNumber?: Array<string | null> | null;
            foodPreferences?: string | null;
            customOnboardBrick?: string | null;
            title?: string | null;
            organization?: string | null;
            tShirtSize?: string | null;
            sweaterSize?: string | null;
            donateBirthdayGift?: boolean | null;
            donateWorkAnniversaryGift?: boolean | null;
            createdAt?: string | null;
            updatedAt?: string | null;
            _version: number;
            _deleted?: boolean | null;
          }
        | null;
      address?: { __typename?: 'Address'; id: string; address1?: string | null } | null;
    } | null>;
  };
};

export type TCreateEventMutationVariables = Exact<{
  input: TCreateEventMutationInput;
}>;

export type TCreateEventMutation = {
  __typename?: 'Mutation';
  createEvent?: {
    __typename?: 'Event';
    id: string;
    companyId: string;
    employeeId: string;
    recipientId: string;
    addressId?: string | null;
    referenceEventId?: string | null;
    giftId?: string | null;
    eventDate: string;
    type: TEventTypes;
    customType?: string | null;
    age?: number | null;
    skippedAt?: string | null;
    deliveredAt?: string | null;
    groupedToEventId?: string | null;
    isPaused?: boolean | null;
    wasPaused?: boolean | null;
    _version: number;
    _deleted?: boolean | null;
  } | null;
};

export type TCreateReferenceEventMutationVariables = Exact<{
  input: TCreateReferenceEventMutationInput;
}>;

export type TCreateReferenceEventMutation = {
  __typename?: 'Mutation';
  createReferenceEvent?: {
    __typename?: 'ReferenceEvent';
    id: string;
    companyId: string;
    employeeId: string;
    recipientId: string;
    addressId?: string | null;
    fullDate: string;
    date: string;
    type: TReferenceEventTypes;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt?: number | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    company?: { __typename?: 'Company'; name: string } | null;
    employee?: { __typename?: 'Employee'; fullName?: string | null } | null;
    recipient?:
      | {
          __typename?: 'Dependant';
          id: string;
          companyId: string;
          employeeId: string;
          firstName?: string | null;
          fullName?: string | null;
          lastName?: string | null;
          birthDate?: string | null;
          type: TDependantType;
          petType?: string | null;
          foodPreferences?: string | null;
          donateBirthdayGift?: boolean | null;
          _version: number;
          _deleted?: boolean | null;
          employee?: {
            __typename?: 'Employee';
            id: string;
            active: boolean;
            externalId?: string | null;
            companyId: string;
            officeId?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            preferredFirstName?: string | null;
            fullName?: string | null;
            hireDate?: string | null;
            birthDate?: string | null;
            email?: string | null;
            phoneNumber?: Array<string | null> | null;
            foodPreferences?: string | null;
            customOnboardBrick?: string | null;
            title?: string | null;
            organization?: string | null;
            tShirtSize?: string | null;
            sweaterSize?: string | null;
            donateBirthdayGift?: boolean | null;
            donateWorkAnniversaryGift?: boolean | null;
            createdAt?: string | null;
            updatedAt?: string | null;
            _version: number;
            _deleted?: boolean | null;
          } | null;
        }
      | {
          __typename?: 'Employee';
          id: string;
          active: boolean;
          externalId?: string | null;
          companyId: string;
          officeId?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          preferredFirstName?: string | null;
          fullName?: string | null;
          hireDate?: string | null;
          birthDate?: string | null;
          email?: string | null;
          phoneNumber?: Array<string | null> | null;
          foodPreferences?: string | null;
          customOnboardBrick?: string | null;
          title?: string | null;
          organization?: string | null;
          tShirtSize?: string | null;
          sweaterSize?: string | null;
          donateBirthdayGift?: boolean | null;
          donateWorkAnniversaryGift?: boolean | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          _version: number;
          _deleted?: boolean | null;
        }
      | null;
    address?: { __typename?: 'Address'; id: string; address1?: string | null } | null;
  } | null;
};

export type TSkipEventMutationVariables = Exact<{
  id: Scalars['ID'];
  companyId: Scalars['ID'];
  version: Scalars['Int'];
}>;

export type TSkipEventMutation = {
  __typename?: 'Mutation';
  skipEvent?: {
    __typename?: 'Event';
    id: string;
    companyId: string;
    employeeId: string;
    recipientId: string;
    addressId?: string | null;
    referenceEventId?: string | null;
    giftId?: string | null;
    eventDate: string;
    type: TEventTypes;
    customType?: string | null;
    age?: number | null;
    skippedAt?: string | null;
    deliveredAt?: string | null;
    groupedToEventId?: string | null;
    isPaused?: boolean | null;
    wasPaused?: boolean | null;
    _version: number;
    _deleted?: boolean | null;
  } | null;
};

export type TUnskipEventMutationVariables = Exact<{
  id: Scalars['ID'];
  companyId: Scalars['ID'];
  version: Scalars['Int'];
}>;

export type TUnskipEventMutation = {
  __typename?: 'Mutation';
  unskipEvent?: {
    __typename?: 'Event';
    id: string;
    companyId: string;
    employeeId: string;
    recipientId: string;
    addressId?: string | null;
    referenceEventId?: string | null;
    giftId?: string | null;
    eventDate: string;
    type: TEventTypes;
    customType?: string | null;
    age?: number | null;
    skippedAt?: string | null;
    deliveredAt?: string | null;
    groupedToEventId?: string | null;
    isPaused?: boolean | null;
    wasPaused?: boolean | null;
    _version: number;
    _deleted?: boolean | null;
  } | null;
};

export type TAnyEventChangedFieldsFragment = {
  __typename?: 'Event';
  id: string;
  companyId: string;
  employeeId: string;
  recipientId: string;
  addressId?: string | null;
  referenceEventId?: string | null;
  giftId?: string | null;
  eventDate: string;
  type: TEventTypes;
  customType?: string | null;
  age?: number | null;
  skippedAt?: string | null;
  deliveredAt?: string | null;
  groupedToEventId?: string | null;
  isPaused?: boolean | null;
  wasPaused?: boolean | null;
  _version: number;
  _deleted?: boolean | null;
};

export type TOnAnyEventChangedSubscriptionVariables = Exact<{ [key: string]: never }>;

export type TOnAnyEventChangedSubscription = {
  __typename?: 'Subscription';
  onAnyEventChanged?: {
    __typename?: 'Event';
    id: string;
    companyId: string;
    employeeId: string;
    recipientId: string;
    addressId?: string | null;
    referenceEventId?: string | null;
    giftId?: string | null;
    eventDate: string;
    type: TEventTypes;
    customType?: string | null;
    age?: number | null;
    skippedAt?: string | null;
    deliveredAt?: string | null;
    groupedToEventId?: string | null;
    isPaused?: boolean | null;
    wasPaused?: boolean | null;
    _version: number;
    _deleted?: boolean | null;
  } | null;
};

export type TBasicGiftFieldsFragment = {
  __typename?: 'Gift';
  id: string;
  companyId: string;
  employeeId: string;
  recipientId: string;
  addressId?: string | null;
  eventId: string;
  shipmentId?: string | null;
  status: TGiftStatuses;
  type: TGiftTypes;
  customType?: string | null;
  eventDate: string;
  rack?: string | null;
  notes?: string | null;
  group?: string | null;
  doneAt?: string | null;
  isDone?: number | null;
  recreatedFromId?: string | null;
  recipientData?: string | null;
  _version: number;
  _deleted?: boolean | null;
  alerts?: Array<{
    __typename?: 'Alert';
    message: string;
    oldItem?: string | null;
    newItem?: string | null;
    acknowledgedBy?: string | null;
    acknowledgedAt?: string | null;
  } | null> | null;
};

export type TGiftFieldsFragment = {
  __typename?: 'Gift';
  id: string;
  companyId: string;
  employeeId: string;
  recipientId: string;
  addressId?: string | null;
  eventId: string;
  shipmentId?: string | null;
  status: TGiftStatuses;
  type: TGiftTypes;
  customType?: string | null;
  eventDate: string;
  rack?: string | null;
  notes?: string | null;
  group?: string | null;
  doneAt?: string | null;
  isDone?: number | null;
  recreatedFromId?: string | null;
  recipientData?: string | null;
  _version: number;
  _deleted?: boolean | null;
  event?: { __typename?: 'Event'; age?: number | null } | null;
  alerts?: Array<{
    __typename?: 'Alert';
    message: string;
    oldItem?: string | null;
    newItem?: string | null;
    acknowledgedBy?: string | null;
    acknowledgedAt?: string | null;
  } | null> | null;
};

export type TGiftWithShipmentFieldsFragment = {
  __typename?: 'Gift';
  id: string;
  companyId: string;
  employeeId: string;
  recipientId: string;
  addressId?: string | null;
  eventId: string;
  shipmentId?: string | null;
  status: TGiftStatuses;
  type: TGiftTypes;
  customType?: string | null;
  eventDate: string;
  rack?: string | null;
  notes?: string | null;
  group?: string | null;
  doneAt?: string | null;
  isDone?: number | null;
  recreatedFromId?: string | null;
  recipientData?: string | null;
  _version: number;
  _deleted?: boolean | null;
  shipment?: {
    __typename?: 'Shipment';
    id: string;
    companyId: string;
    addressId: string;
    addressVerified?: TShipStationAddressVerified | null;
    useGoogleAddress?: boolean | null;
    status: TShipmentStatuses;
    rack?: string | null;
    notes?: string | null;
    actualShippingDate?: string | null;
    shippingDate?: string | null;
    trackingNumber?: string | null;
    trackingURL?: string | null;
    shippedAt?: string | null;
    setForPickupAt?: string | null;
    pickedUpAt?: string | null;
    shipStationOrderId?: number | null;
    shipStationShipmentId?: number | null;
    lastShipStationResyncStartedAt?: string | null;
    shipStationError?: string | null;
    _version: number;
    _deleted?: boolean | null;
    gifts?: {
      __typename?: 'GiftsConnection';
      items: Array<{
        __typename?: 'Gift';
        id: string;
        companyId: string;
        employeeId: string;
        recipientId: string;
        addressId?: string | null;
        eventId: string;
        shipmentId?: string | null;
        status: TGiftStatuses;
        type: TGiftTypes;
        customType?: string | null;
        eventDate: string;
        rack?: string | null;
        notes?: string | null;
        group?: string | null;
        doneAt?: string | null;
        isDone?: number | null;
        recreatedFromId?: string | null;
        recipientData?: string | null;
        _version: number;
        _deleted?: boolean | null;
        alerts?: Array<{
          __typename?: 'Alert';
          message: string;
          oldItem?: string | null;
          newItem?: string | null;
          acknowledgedBy?: string | null;
          acknowledgedAt?: string | null;
        } | null> | null;
      } | null>;
    } | null;
    alerts?: Array<{
      __typename?: 'Alert';
      message: string;
      oldItem?: string | null;
      newItem?: string | null;
      acknowledgedBy?: string | null;
      acknowledgedAt?: string | null;
    } | null> | null;
    packageInfo?: { __typename?: 'PackageInfo'; length: number; width: number; height: number; weight: number } | null;
    customsData?: Array<{
      __typename?: 'CustomsData';
      value: number;
      hsCode: string;
      quantity: number;
      description: string;
      country: string;
    } | null> | null;
    label?: {
      __typename?: 'ShipmentLabel';
      cost: number;
      createdAt?: string | null;
      carrier: string;
      labelFile?: {
        __typename?: 'S3Object';
        bucket: string;
        key: string;
        region: string;
        version?: string | null;
      } | null;
      addressData?: {
        __typename?: 'Address';
        id: string;
        address1?: string | null;
        address2?: string | null;
        city?: string | null;
        state?: string | null;
        country?: string | null;
        zipCode?: string | null;
        parentType: TAddressParentTypes;
        useGoogleAddress?: boolean | null;
        _version: number;
      } | null;
    } | null;
    history?: Array<{
      __typename?: 'ShipmentHistory';
      trackingNumber?: string | null;
      trackingURL?: string | null;
      label?: {
        __typename?: 'ShipmentLabel';
        cost: number;
        createdAt?: string | null;
        carrier: string;
        labelFile?: {
          __typename?: 'S3Object';
          bucket: string;
          key: string;
          region: string;
          version?: string | null;
        } | null;
        addressData?: {
          __typename?: 'Address';
          id: string;
          address1?: string | null;
          address2?: string | null;
          city?: string | null;
          state?: string | null;
          country?: string | null;
          zipCode?: string | null;
          parentType: TAddressParentTypes;
          useGoogleAddress?: boolean | null;
          _version: number;
        } | null;
      } | null;
    } | null> | null;
  } | null;
  event?: { __typename?: 'Event'; age?: number | null } | null;
  alerts?: Array<{
    __typename?: 'Alert';
    message: string;
    oldItem?: string | null;
    newItem?: string | null;
    acknowledgedBy?: string | null;
    acknowledgedAt?: string | null;
  } | null> | null;
};

export type TListEmployeeAndDependatsGiftsQueryVariables = Exact<{
  employeeId: Scalars['ID'];
  companyId: Scalars['ID'];
  nextToken?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<TOrderDirection>;
}>;

export type TListEmployeeAndDependatsGiftsQuery = {
  __typename?: 'Query';
  listEmployeeAndDependatsGifts: {
    __typename?: 'GiftsConnection';
    nextToken?: string | null;
    items: Array<{
      __typename?: 'Gift';
      id: string;
      companyId: string;
      employeeId: string;
      recipientId: string;
      addressId?: string | null;
      eventId: string;
      shipmentId?: string | null;
      status: TGiftStatuses;
      type: TGiftTypes;
      customType?: string | null;
      eventDate: string;
      rack?: string | null;
      notes?: string | null;
      group?: string | null;
      doneAt?: string | null;
      isDone?: number | null;
      recreatedFromId?: string | null;
      recipientData?: string | null;
      _version: number;
      _deleted?: boolean | null;
      shipment?: {
        __typename?: 'Shipment';
        id: string;
        companyId: string;
        addressId: string;
        addressVerified?: TShipStationAddressVerified | null;
        useGoogleAddress?: boolean | null;
        status: TShipmentStatuses;
        rack?: string | null;
        notes?: string | null;
        actualShippingDate?: string | null;
        shippingDate?: string | null;
        trackingNumber?: string | null;
        trackingURL?: string | null;
        shippedAt?: string | null;
        setForPickupAt?: string | null;
        pickedUpAt?: string | null;
        shipStationOrderId?: number | null;
        shipStationShipmentId?: number | null;
        lastShipStationResyncStartedAt?: string | null;
        shipStationError?: string | null;
        _version: number;
        _deleted?: boolean | null;
        gifts?: {
          __typename?: 'GiftsConnection';
          items: Array<{
            __typename?: 'Gift';
            id: string;
            companyId: string;
            employeeId: string;
            recipientId: string;
            addressId?: string | null;
            eventId: string;
            shipmentId?: string | null;
            status: TGiftStatuses;
            type: TGiftTypes;
            customType?: string | null;
            eventDate: string;
            rack?: string | null;
            notes?: string | null;
            group?: string | null;
            doneAt?: string | null;
            isDone?: number | null;
            recreatedFromId?: string | null;
            recipientData?: string | null;
            _version: number;
            _deleted?: boolean | null;
            alerts?: Array<{
              __typename?: 'Alert';
              message: string;
              oldItem?: string | null;
              newItem?: string | null;
              acknowledgedBy?: string | null;
              acknowledgedAt?: string | null;
            } | null> | null;
          } | null>;
        } | null;
        alerts?: Array<{
          __typename?: 'Alert';
          message: string;
          oldItem?: string | null;
          newItem?: string | null;
          acknowledgedBy?: string | null;
          acknowledgedAt?: string | null;
        } | null> | null;
        packageInfo?: {
          __typename?: 'PackageInfo';
          length: number;
          width: number;
          height: number;
          weight: number;
        } | null;
        customsData?: Array<{
          __typename?: 'CustomsData';
          value: number;
          hsCode: string;
          quantity: number;
          description: string;
          country: string;
        } | null> | null;
        label?: {
          __typename?: 'ShipmentLabel';
          cost: number;
          createdAt?: string | null;
          carrier: string;
          labelFile?: {
            __typename?: 'S3Object';
            bucket: string;
            key: string;
            region: string;
            version?: string | null;
          } | null;
          addressData?: {
            __typename?: 'Address';
            id: string;
            address1?: string | null;
            address2?: string | null;
            city?: string | null;
            state?: string | null;
            country?: string | null;
            zipCode?: string | null;
            parentType: TAddressParentTypes;
            useGoogleAddress?: boolean | null;
            _version: number;
          } | null;
        } | null;
        history?: Array<{
          __typename?: 'ShipmentHistory';
          trackingNumber?: string | null;
          trackingURL?: string | null;
          label?: {
            __typename?: 'ShipmentLabel';
            cost: number;
            createdAt?: string | null;
            carrier: string;
            labelFile?: {
              __typename?: 'S3Object';
              bucket: string;
              key: string;
              region: string;
              version?: string | null;
            } | null;
            addressData?: {
              __typename?: 'Address';
              id: string;
              address1?: string | null;
              address2?: string | null;
              city?: string | null;
              state?: string | null;
              country?: string | null;
              zipCode?: string | null;
              parentType: TAddressParentTypes;
              useGoogleAddress?: boolean | null;
              _version: number;
            } | null;
          } | null;
        } | null> | null;
      } | null;
      event?: { __typename?: 'Event'; age?: number | null } | null;
      alerts?: Array<{
        __typename?: 'Alert';
        message: string;
        oldItem?: string | null;
        newItem?: string | null;
        acknowledgedBy?: string | null;
        acknowledgedAt?: string | null;
      } | null> | null;
    } | null>;
  };
};

export type TListNotDoneGiftsQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<TOrderDirection>;
}>;

export type TListNotDoneGiftsQuery = {
  __typename?: 'Query';
  listNotDoneGifts: {
    __typename?: 'GiftsConnection';
    nextToken?: string | null;
    items: Array<{
      __typename?: 'Gift';
      id: string;
      companyId: string;
      employeeId: string;
      recipientId: string;
      addressId?: string | null;
      eventId: string;
      shipmentId?: string | null;
      status: TGiftStatuses;
      type: TGiftTypes;
      customType?: string | null;
      eventDate: string;
      rack?: string | null;
      notes?: string | null;
      group?: string | null;
      doneAt?: string | null;
      isDone?: number | null;
      recreatedFromId?: string | null;
      recipientData?: string | null;
      _version: number;
      _deleted?: boolean | null;
      event?: { __typename?: 'Event'; age?: number | null } | null;
      alerts?: Array<{
        __typename?: 'Alert';
        message: string;
        oldItem?: string | null;
        newItem?: string | null;
        acknowledgedBy?: string | null;
        acknowledgedAt?: string | null;
      } | null> | null;
    } | null>;
  };
};

export type TListRecentlyDoneGiftsQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<TOrderDirection>;
}>;

export type TListRecentlyDoneGiftsQuery = {
  __typename?: 'Query';
  listRecentlyDoneGifts: {
    __typename?: 'GiftsConnection';
    nextToken?: string | null;
    items: Array<{
      __typename?: 'Gift';
      id: string;
      companyId: string;
      employeeId: string;
      recipientId: string;
      addressId?: string | null;
      eventId: string;
      shipmentId?: string | null;
      status: TGiftStatuses;
      type: TGiftTypes;
      customType?: string | null;
      eventDate: string;
      rack?: string | null;
      notes?: string | null;
      group?: string | null;
      doneAt?: string | null;
      isDone?: number | null;
      recreatedFromId?: string | null;
      recipientData?: string | null;
      _version: number;
      _deleted?: boolean | null;
      event?: { __typename?: 'Event'; age?: number | null } | null;
      alerts?: Array<{
        __typename?: 'Alert';
        message: string;
        oldItem?: string | null;
        newItem?: string | null;
        acknowledgedBy?: string | null;
        acknowledgedAt?: string | null;
      } | null> | null;
    } | null>;
  };
};

export type TListShipmentGiftsQueryVariables = Exact<{
  shipmentId: Scalars['ID'];
  companyId: Scalars['ID'];
  nextToken?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<TOrderDirection>;
}>;

export type TListShipmentGiftsQuery = {
  __typename?: 'Query';
  listShipmentGifts: {
    __typename?: 'GiftsConnection';
    nextToken?: string | null;
    items: Array<{
      __typename?: 'Gift';
      id: string;
      companyId: string;
      employeeId: string;
      recipientId: string;
      addressId?: string | null;
      eventId: string;
      shipmentId?: string | null;
      status: TGiftStatuses;
      type: TGiftTypes;
      customType?: string | null;
      eventDate: string;
      rack?: string | null;
      notes?: string | null;
      group?: string | null;
      doneAt?: string | null;
      isDone?: number | null;
      recreatedFromId?: string | null;
      recipientData?: string | null;
      _version: number;
      _deleted?: boolean | null;
      event?: { __typename?: 'Event'; age?: number | null } | null;
      alerts?: Array<{
        __typename?: 'Alert';
        message: string;
        oldItem?: string | null;
        newItem?: string | null;
        acknowledgedBy?: string | null;
        acknowledgedAt?: string | null;
      } | null> | null;
    } | null>;
  };
};

export type TGetGiftQueryVariables = Exact<{
  id: Scalars['ID'];
  companyId: Scalars['ID'];
}>;

export type TGetGiftQuery = {
  __typename?: 'Query';
  getGift?: {
    __typename?: 'Gift';
    id: string;
    companyId: string;
    employeeId: string;
    recipientId: string;
    addressId?: string | null;
    eventId: string;
    shipmentId?: string | null;
    status: TGiftStatuses;
    type: TGiftTypes;
    customType?: string | null;
    eventDate: string;
    rack?: string | null;
    notes?: string | null;
    group?: string | null;
    doneAt?: string | null;
    isDone?: number | null;
    recreatedFromId?: string | null;
    recipientData?: string | null;
    _version: number;
    _deleted?: boolean | null;
    event?: { __typename?: 'Event'; age?: number | null } | null;
    alerts?: Array<{
      __typename?: 'Alert';
      message: string;
      oldItem?: string | null;
      newItem?: string | null;
      acknowledgedBy?: string | null;
      acknowledgedAt?: string | null;
    } | null> | null;
  } | null;
};

export type TCreateEventGiftMutationVariables = Exact<{
  eventId: Scalars['ID'];
  companyId: Scalars['ID'];
  eventVersion: Scalars['Int'];
  group?: InputMaybe<Scalars['String']>;
}>;

export type TCreateEventGiftMutation = {
  __typename?: 'Mutation';
  createEventGift?: {
    __typename?: 'CreateEventGiftResponse';
    companyId: string;
    event?: {
      __typename?: 'Event';
      id: string;
      companyId: string;
      employeeId: string;
      recipientId: string;
      addressId?: string | null;
      referenceEventId?: string | null;
      giftId?: string | null;
      eventDate: string;
      type: TEventTypes;
      customType?: string | null;
      age?: number | null;
      skippedAt?: string | null;
      deliveredAt?: string | null;
      groupedToEventId?: string | null;
      isPaused?: boolean | null;
      wasPaused?: boolean | null;
      _version: number;
      _deleted?: boolean | null;
    } | null;
    gift?: {
      __typename?: 'Gift';
      id: string;
      companyId: string;
      employeeId: string;
      recipientId: string;
      addressId?: string | null;
      eventId: string;
      shipmentId?: string | null;
      status: TGiftStatuses;
      type: TGiftTypes;
      customType?: string | null;
      eventDate: string;
      rack?: string | null;
      notes?: string | null;
      group?: string | null;
      doneAt?: string | null;
      isDone?: number | null;
      recreatedFromId?: string | null;
      recipientData?: string | null;
      _version: number;
      _deleted?: boolean | null;
      event?: { __typename?: 'Event'; age?: number | null } | null;
      alerts?: Array<{
        __typename?: 'Alert';
        message: string;
        oldItem?: string | null;
        newItem?: string | null;
        acknowledgedBy?: string | null;
        acknowledgedAt?: string | null;
      } | null> | null;
    } | null;
  } | null;
};

export type TMoveGiftMutationVariables = Exact<{
  id: Scalars['ID'];
  companyId: Scalars['ID'];
  version: Scalars['Int'];
  input: TMoveGiftMutationInput;
}>;

export type TMoveGiftMutation = {
  __typename?: 'Mutation';
  moveGift?: {
    __typename?: 'Gift';
    id: string;
    companyId: string;
    employeeId: string;
    recipientId: string;
    addressId?: string | null;
    eventId: string;
    shipmentId?: string | null;
    status: TGiftStatuses;
    type: TGiftTypes;
    customType?: string | null;
    eventDate: string;
    rack?: string | null;
    notes?: string | null;
    group?: string | null;
    doneAt?: string | null;
    isDone?: number | null;
    recreatedFromId?: string | null;
    recipientData?: string | null;
    _version: number;
    _deleted?: boolean | null;
    event?: { __typename?: 'Event'; age?: number | null } | null;
    alerts?: Array<{
      __typename?: 'Alert';
      message: string;
      oldItem?: string | null;
      newItem?: string | null;
      acknowledgedBy?: string | null;
      acknowledgedAt?: string | null;
    } | null> | null;
  } | null;
};

export type TUpdateGiftMutationVariables = Exact<{
  id: Scalars['ID'];
  companyId: Scalars['ID'];
  version: Scalars['Int'];
  input: TUpdateGiftMutationInput;
}>;

export type TUpdateGiftMutation = {
  __typename?: 'Mutation';
  updateGift?: {
    __typename?: 'Gift';
    id: string;
    companyId: string;
    employeeId: string;
    recipientId: string;
    addressId?: string | null;
    eventId: string;
    shipmentId?: string | null;
    status: TGiftStatuses;
    type: TGiftTypes;
    customType?: string | null;
    eventDate: string;
    rack?: string | null;
    notes?: string | null;
    group?: string | null;
    doneAt?: string | null;
    isDone?: number | null;
    recreatedFromId?: string | null;
    recipientData?: string | null;
    _version: number;
    _deleted?: boolean | null;
    event?: { __typename?: 'Event'; age?: number | null } | null;
    alerts?: Array<{
      __typename?: 'Alert';
      message: string;
      oldItem?: string | null;
      newItem?: string | null;
      acknowledgedBy?: string | null;
      acknowledgedAt?: string | null;
    } | null> | null;
  } | null;
};

export type TRemoveGiftFromGroupMutationVariables = Exact<{
  id: Scalars['ID'];
  companyId: Scalars['ID'];
  version: Scalars['Int'];
}>;

export type TRemoveGiftFromGroupMutation = {
  __typename?: 'Mutation';
  removeGiftFromGroup?: {
    __typename?: 'RemovedGiftFromGroupResponse';
    items: Array<{
      __typename?: 'Gift';
      id: string;
      companyId: string;
      employeeId: string;
      recipientId: string;
      addressId?: string | null;
      eventId: string;
      shipmentId?: string | null;
      status: TGiftStatuses;
      type: TGiftTypes;
      customType?: string | null;
      eventDate: string;
      rack?: string | null;
      notes?: string | null;
      group?: string | null;
      doneAt?: string | null;
      isDone?: number | null;
      recreatedFromId?: string | null;
      recipientData?: string | null;
      _version: number;
      _deleted?: boolean | null;
      event?: { __typename?: 'Event'; age?: number | null } | null;
      alerts?: Array<{
        __typename?: 'Alert';
        message: string;
        oldItem?: string | null;
        newItem?: string | null;
        acknowledgedBy?: string | null;
        acknowledgedAt?: string | null;
      } | null> | null;
    } | null>;
  } | null;
};

export type TDeleteGiftMutationVariables = Exact<{
  id: Scalars['ID'];
  companyId: Scalars['ID'];
  version: Scalars['Int'];
}>;

export type TDeleteGiftMutation = {
  __typename?: 'Mutation';
  deleteGift?: {
    __typename?: 'Gift';
    id: string;
    companyId: string;
    employeeId: string;
    recipientId: string;
    addressId?: string | null;
    eventId: string;
    shipmentId?: string | null;
    status: TGiftStatuses;
    type: TGiftTypes;
    customType?: string | null;
    eventDate: string;
    rack?: string | null;
    notes?: string | null;
    group?: string | null;
    doneAt?: string | null;
    isDone?: number | null;
    recreatedFromId?: string | null;
    recipientData?: string | null;
    _version: number;
    _deleted?: boolean | null;
    alerts?: Array<{
      __typename?: 'Alert';
      message: string;
      oldItem?: string | null;
      newItem?: string | null;
      acknowledgedBy?: string | null;
      acknowledgedAt?: string | null;
    } | null> | null;
  } | null;
};

export type TNotifyNewHiresMutationVariables = Exact<{
  events: Array<InputMaybe<TNotifyNewHireMutationInput>> | InputMaybe<TNotifyNewHireMutationInput>;
}>;

export type TNotifyNewHiresMutation = { __typename?: 'Mutation'; notifyNewHires?: boolean | null };

export type TAnyEventGiftCreatedEventFieldsFragment = {
  __typename?: 'Event';
  id: string;
  companyId: string;
  employeeId: string;
  recipientId: string;
  addressId?: string | null;
  referenceEventId?: string | null;
  giftId?: string | null;
  eventDate: string;
  type: TEventTypes;
  customType?: string | null;
  age?: number | null;
  skippedAt?: string | null;
  deliveredAt?: string | null;
  groupedToEventId?: string | null;
  isPaused?: boolean | null;
  wasPaused?: boolean | null;
  _version: number;
  _deleted?: boolean | null;
};

export type TAnyEventGiftCreatedGiftFieldsFragment = {
  __typename?: 'Gift';
  id: string;
  companyId: string;
  employeeId: string;
  recipientId: string;
  addressId?: string | null;
  eventId: string;
  shipmentId?: string | null;
  status: TGiftStatuses;
  type: TGiftTypes;
  customType?: string | null;
  eventDate: string;
  rack?: string | null;
  notes?: string | null;
  group?: string | null;
  doneAt?: string | null;
  isDone?: number | null;
  recreatedFromId?: string | null;
  recipientData?: string | null;
  _version: number;
  _deleted?: boolean | null;
  alerts?: Array<{
    __typename?: 'Alert';
    message: string;
    oldItem?: string | null;
    newItem?: string | null;
    acknowledgedBy?: string | null;
    acknowledgedAt?: string | null;
  } | null> | null;
};

export type TOnAnyEventGiftCreatedSubscriptionVariables = Exact<{ [key: string]: never }>;

export type TOnAnyEventGiftCreatedSubscription = {
  __typename?: 'Subscription';
  onAnyEventGiftCreated?: {
    __typename?: 'CreateEventGiftResponse';
    event?: {
      __typename?: 'Event';
      id: string;
      companyId: string;
      employeeId: string;
      recipientId: string;
      addressId?: string | null;
      referenceEventId?: string | null;
      giftId?: string | null;
      eventDate: string;
      type: TEventTypes;
      customType?: string | null;
      age?: number | null;
      skippedAt?: string | null;
      deliveredAt?: string | null;
      groupedToEventId?: string | null;
      isPaused?: boolean | null;
      wasPaused?: boolean | null;
      _version: number;
      _deleted?: boolean | null;
    } | null;
    gift?: {
      __typename?: 'Gift';
      id: string;
      companyId: string;
      employeeId: string;
      recipientId: string;
      addressId?: string | null;
      eventId: string;
      shipmentId?: string | null;
      status: TGiftStatuses;
      type: TGiftTypes;
      customType?: string | null;
      eventDate: string;
      rack?: string | null;
      notes?: string | null;
      group?: string | null;
      doneAt?: string | null;
      isDone?: number | null;
      recreatedFromId?: string | null;
      recipientData?: string | null;
      _version: number;
      _deleted?: boolean | null;
      alerts?: Array<{
        __typename?: 'Alert';
        message: string;
        oldItem?: string | null;
        newItem?: string | null;
        acknowledgedBy?: string | null;
        acknowledgedAt?: string | null;
      } | null> | null;
    } | null;
  } | null;
};

export type TAnyGiftRemovedFromGroupFieldsFragment = {
  __typename?: 'Gift';
  id: string;
  companyId: string;
  employeeId: string;
  recipientId: string;
  addressId?: string | null;
  eventId: string;
  shipmentId?: string | null;
  status: TGiftStatuses;
  type: TGiftTypes;
  customType?: string | null;
  eventDate: string;
  rack?: string | null;
  notes?: string | null;
  group?: string | null;
  doneAt?: string | null;
  isDone?: number | null;
  recreatedFromId?: string | null;
  recipientData?: string | null;
  _version: number;
  _deleted?: boolean | null;
  alerts?: Array<{
    __typename?: 'Alert';
    message: string;
    oldItem?: string | null;
    newItem?: string | null;
    acknowledgedBy?: string | null;
    acknowledgedAt?: string | null;
  } | null> | null;
};

export type TOnAnyGiftRemovedFromGroupSubscriptionVariables = Exact<{ [key: string]: never }>;

export type TOnAnyGiftRemovedFromGroupSubscription = {
  __typename?: 'Subscription';
  onAnyGiftRemovedFromGroup?: {
    __typename?: 'RemovedGiftFromGroupResponse';
    items: Array<{
      __typename?: 'Gift';
      id: string;
      companyId: string;
      employeeId: string;
      recipientId: string;
      addressId?: string | null;
      eventId: string;
      shipmentId?: string | null;
      status: TGiftStatuses;
      type: TGiftTypes;
      customType?: string | null;
      eventDate: string;
      rack?: string | null;
      notes?: string | null;
      group?: string | null;
      doneAt?: string | null;
      isDone?: number | null;
      recreatedFromId?: string | null;
      recipientData?: string | null;
      _version: number;
      _deleted?: boolean | null;
      alerts?: Array<{
        __typename?: 'Alert';
        message: string;
        oldItem?: string | null;
        newItem?: string | null;
        acknowledgedBy?: string | null;
        acknowledgedAt?: string | null;
      } | null> | null;
    } | null>;
  } | null;
};

export type TAnyGiftChangedFieldsFragment = {
  __typename?: 'Gift';
  id: string;
  companyId: string;
  employeeId: string;
  recipientId: string;
  addressId?: string | null;
  eventId: string;
  shipmentId?: string | null;
  status: TGiftStatuses;
  type: TGiftTypes;
  customType?: string | null;
  eventDate: string;
  rack?: string | null;
  notes?: string | null;
  group?: string | null;
  doneAt?: string | null;
  isDone?: number | null;
  recreatedFromId?: string | null;
  recipientData?: string | null;
  _version: number;
  _deleted?: boolean | null;
  alerts?: Array<{
    __typename?: 'Alert';
    message: string;
    oldItem?: string | null;
    newItem?: string | null;
    acknowledgedBy?: string | null;
    acknowledgedAt?: string | null;
  } | null> | null;
};

export type TOnAnyGiftChangedSubscriptionVariables = Exact<{ [key: string]: never }>;

export type TOnAnyGiftChangedSubscription = {
  __typename?: 'Subscription';
  onAnyGiftChanged?: {
    __typename?: 'Gift';
    id: string;
    companyId: string;
    employeeId: string;
    recipientId: string;
    addressId?: string | null;
    eventId: string;
    shipmentId?: string | null;
    status: TGiftStatuses;
    type: TGiftTypes;
    customType?: string | null;
    eventDate: string;
    rack?: string | null;
    notes?: string | null;
    group?: string | null;
    doneAt?: string | null;
    isDone?: number | null;
    recreatedFromId?: string | null;
    recipientData?: string | null;
    _version: number;
    _deleted?: boolean | null;
    alerts?: Array<{
      __typename?: 'Alert';
      message: string;
      oldItem?: string | null;
      newItem?: string | null;
      acknowledgedBy?: string | null;
      acknowledgedAt?: string | null;
    } | null> | null;
  } | null;
};

export type TBasicLogFieldsFragment = {
  __typename?: 'Log';
  id: string;
  occurredAt?: string | null;
  arguments?: string | null;
  companyId?: string | null;
  fieldName?: string | null;
  identity?: string | null;
  parentTypeName?: string | null;
  resourceId?: string | null;
  response?: string | null;
  selectionSetGraphQL?: string | null;
  type?: string | null;
  userId?: string | null;
  userType?: TLogUserTypes | null;
  variables?: string | null;
};

export type TLogFieldsFragment = {
  __typename?: 'Log';
  id: string;
  occurredAt?: string | null;
  arguments?: string | null;
  companyId?: string | null;
  fieldName?: string | null;
  identity?: string | null;
  parentTypeName?: string | null;
  resourceId?: string | null;
  response?: string | null;
  selectionSetGraphQL?: string | null;
  type?: string | null;
  userId?: string | null;
  userType?: TLogUserTypes | null;
  variables?: string | null;
};

export type TListAllLogsQueryVariables = Exact<{
  userType: TLogUserTypes;
  nextToken?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<TOrderDirection>;
}>;

export type TListAllLogsQuery = {
  __typename?: 'Query';
  listAllLogs: {
    __typename?: 'LogsConnection';
    nextToken?: string | null;
    items: Array<{
      __typename?: 'Log';
      id: string;
      occurredAt?: string | null;
      arguments?: string | null;
      companyId?: string | null;
      fieldName?: string | null;
      identity?: string | null;
      parentTypeName?: string | null;
      resourceId?: string | null;
      response?: string | null;
      selectionSetGraphQL?: string | null;
      type?: string | null;
      userId?: string | null;
      userType?: TLogUserTypes | null;
      variables?: string | null;
    } | null>;
  };
};

export type TOfficeFieldsFragment = {
  __typename?: 'Office';
  id: string;
  companyId: string;
  name: string;
  _version: number;
  _deleted?: boolean | null;
  contact?: { __typename?: 'Contact'; name?: string | null } | null;
};

export type TOfficeWithAddressFieldsFragment = {
  __typename?: 'Office';
  id: string;
  companyId: string;
  name: string;
  _version: number;
  _deleted?: boolean | null;
  address?: {
    __typename?: 'Address';
    id: string;
    address1?: string | null;
    address2?: string | null;
    city?: string | null;
    state?: string | null;
    country?: string | null;
    zipCode?: string | null;
    parentType: TAddressParentTypes;
    useGoogleAddress?: boolean | null;
    _version: number;
    addressFromGoogle?: {
      __typename?: 'AddressFromGoogle';
      address1?: string | null;
      address2?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      zipCode?: string | null;
    } | null;
  } | null;
  contact?: { __typename?: 'Contact'; name?: string | null } | null;
};

export type TGetOfficeQueryVariables = Exact<{
  id: Scalars['ID'];
  companyId: Scalars['ID'];
}>;

export type TGetOfficeQuery = {
  __typename?: 'Query';
  getOffice?: {
    __typename?: 'Office';
    id: string;
    companyId: string;
    name: string;
    _version: number;
    _deleted?: boolean | null;
    address?: {
      __typename?: 'Address';
      id: string;
      address1?: string | null;
      address2?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      zipCode?: string | null;
      parentType: TAddressParentTypes;
      useGoogleAddress?: boolean | null;
      _version: number;
      addressFromGoogle?: {
        __typename?: 'AddressFromGoogle';
        address1?: string | null;
        address2?: string | null;
        city?: string | null;
        state?: string | null;
        country?: string | null;
        zipCode?: string | null;
      } | null;
    } | null;
    contact?: { __typename?: 'Contact'; name?: string | null } | null;
  } | null;
};

export type TListAllOfficesQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<TOrderDirection>;
}>;

export type TListAllOfficesQuery = {
  __typename?: 'Query';
  listAllOffices: {
    __typename?: 'OfficesConnection';
    nextToken?: string | null;
    items: Array<{
      __typename?: 'Office';
      id: string;
      companyId: string;
      name: string;
      _version: number;
      _deleted?: boolean | null;
      address?: {
        __typename?: 'Address';
        id: string;
        address1?: string | null;
        address2?: string | null;
        city?: string | null;
        state?: string | null;
        country?: string | null;
        zipCode?: string | null;
        parentType: TAddressParentTypes;
        useGoogleAddress?: boolean | null;
        _version: number;
        addressFromGoogle?: {
          __typename?: 'AddressFromGoogle';
          address1?: string | null;
          address2?: string | null;
          city?: string | null;
          state?: string | null;
          country?: string | null;
          zipCode?: string | null;
        } | null;
      } | null;
      contact?: { __typename?: 'Contact'; name?: string | null } | null;
    } | null>;
  };
};

export type TListCompanyOfficesQueryVariables = Exact<{
  companyId: Scalars['ID'];
  nextToken?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<TOrderDirection>;
}>;

export type TListCompanyOfficesQuery = {
  __typename?: 'Query';
  listOffices: {
    __typename?: 'OfficesConnection';
    nextToken?: string | null;
    items: Array<{
      __typename?: 'Office';
      id: string;
      companyId: string;
      name: string;
      _version: number;
      _deleted?: boolean | null;
      contact?: { __typename?: 'Contact'; name?: string | null } | null;
    } | null>;
  };
};

export type TAnyOfficeChangedFieldsFragment = {
  __typename?: 'Office';
  id: string;
  companyId: string;
  name: string;
  _version: number;
  _deleted?: boolean | null;
  contact?: { __typename?: 'Contact'; name?: string | null } | null;
};

export type TOnAnyOfficeChangedSubscriptionVariables = Exact<{ [key: string]: never }>;

export type TOnAnyOfficeChangedSubscription = {
  __typename?: 'Subscription';
  onAnyOfficeChanged?: {
    __typename?: 'Office';
    id: string;
    companyId: string;
    name: string;
    _version: number;
    _deleted?: boolean | null;
    contact?: { __typename?: 'Contact'; name?: string | null } | null;
  } | null;
};

export type TRecipientFields_Dependant_Fragment = {
  __typename?: 'Dependant';
  id: string;
  companyId: string;
  employeeId: string;
  firstName?: string | null;
  fullName?: string | null;
  lastName?: string | null;
  birthDate?: string | null;
  type: TDependantType;
  petType?: string | null;
  foodPreferences?: string | null;
  donateBirthdayGift?: boolean | null;
  _version: number;
  _deleted?: boolean | null;
  employee?: {
    __typename?: 'Employee';
    id: string;
    active: boolean;
    externalId?: string | null;
    companyId: string;
    officeId?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    preferredFirstName?: string | null;
    fullName?: string | null;
    hireDate?: string | null;
    birthDate?: string | null;
    email?: string | null;
    phoneNumber?: Array<string | null> | null;
    foodPreferences?: string | null;
    customOnboardBrick?: string | null;
    title?: string | null;
    organization?: string | null;
    tShirtSize?: string | null;
    sweaterSize?: string | null;
    donateBirthdayGift?: boolean | null;
    donateWorkAnniversaryGift?: boolean | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    _version: number;
    _deleted?: boolean | null;
  } | null;
};

export type TRecipientFields_Employee_Fragment = {
  __typename?: 'Employee';
  id: string;
  active: boolean;
  externalId?: string | null;
  companyId: string;
  officeId?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  preferredFirstName?: string | null;
  fullName?: string | null;
  hireDate?: string | null;
  birthDate?: string | null;
  email?: string | null;
  phoneNumber?: Array<string | null> | null;
  foodPreferences?: string | null;
  customOnboardBrick?: string | null;
  title?: string | null;
  organization?: string | null;
  tShirtSize?: string | null;
  sweaterSize?: string | null;
  donateBirthdayGift?: boolean | null;
  donateWorkAnniversaryGift?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  _version: number;
  _deleted?: boolean | null;
};

export type TRecipientFieldsFragment = TRecipientFields_Dependant_Fragment | TRecipientFields_Employee_Fragment;

export type TGetRecipientQueryVariables = Exact<{
  id: Scalars['ID'];
  companyId: Scalars['ID'];
}>;

export type TGetRecipientQuery = {
  __typename?: 'Query';
  getRecipient?:
    | {
        __typename?: 'Dependant';
        id: string;
        companyId: string;
        employeeId: string;
        firstName?: string | null;
        fullName?: string | null;
        lastName?: string | null;
        birthDate?: string | null;
        type: TDependantType;
        petType?: string | null;
        foodPreferences?: string | null;
        donateBirthdayGift?: boolean | null;
        _version: number;
        _deleted?: boolean | null;
        employee?: {
          __typename?: 'Employee';
          id: string;
          active: boolean;
          externalId?: string | null;
          companyId: string;
          officeId?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          preferredFirstName?: string | null;
          fullName?: string | null;
          hireDate?: string | null;
          birthDate?: string | null;
          email?: string | null;
          phoneNumber?: Array<string | null> | null;
          foodPreferences?: string | null;
          customOnboardBrick?: string | null;
          title?: string | null;
          organization?: string | null;
          tShirtSize?: string | null;
          sweaterSize?: string | null;
          donateBirthdayGift?: boolean | null;
          donateWorkAnniversaryGift?: boolean | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          _version: number;
          _deleted?: boolean | null;
        } | null;
      }
    | {
        __typename?: 'Employee';
        id: string;
        active: boolean;
        externalId?: string | null;
        companyId: string;
        officeId?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        preferredFirstName?: string | null;
        fullName?: string | null;
        hireDate?: string | null;
        birthDate?: string | null;
        email?: string | null;
        phoneNumber?: Array<string | null> | null;
        foodPreferences?: string | null;
        customOnboardBrick?: string | null;
        title?: string | null;
        organization?: string | null;
        tShirtSize?: string | null;
        sweaterSize?: string | null;
        donateBirthdayGift?: boolean | null;
        donateWorkAnniversaryGift?: boolean | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        _version: number;
        _deleted?: boolean | null;
      }
    | null;
};

export type TRosterFileFieldsFragment = {
  __typename?: 'RosterFile';
  id: string;
  companyId: string;
  headerMapping?: string | null;
  mapping: string;
  officeMapping?: string | null;
  changelog?: string | null;
  importStartedAt?: string | null;
  impoortEndedAt?: string | null;
  importErrors?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  _version: number;
  _deleted?: boolean | null;
  createdBy?: { __typename?: 'RosterFileCreatedBy'; username?: string | null; sub?: string | null } | null;
  file: { __typename?: 'RosterFileS3Object'; bucket: string; region: string; key: string; version?: string | null };
  changelogFile?: {
    __typename?: 'RosterFileS3Object';
    bucket: string;
    region: string;
    key: string;
    version?: string | null;
  } | null;
};

export type TListRosterFilesQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<TOrderDirection>;
}>;

export type TListRosterFilesQuery = {
  __typename?: 'Query';
  listRosterFiles: {
    __typename?: 'RosterFilesConnection';
    nextToken?: string | null;
    items: Array<{
      __typename?: 'RosterFile';
      id: string;
      companyId: string;
      headerMapping?: string | null;
      mapping: string;
      officeMapping?: string | null;
      changelog?: string | null;
      importStartedAt?: string | null;
      impoortEndedAt?: string | null;
      importErrors?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      _version: number;
      _deleted?: boolean | null;
      createdBy?: { __typename?: 'RosterFileCreatedBy'; username?: string | null; sub?: string | null } | null;
      file: { __typename?: 'RosterFileS3Object'; bucket: string; region: string; key: string; version?: string | null };
      changelogFile?: {
        __typename?: 'RosterFileS3Object';
        bucket: string;
        region: string;
        key: string;
        version?: string | null;
      } | null;
    } | null>;
  };
};

export type TListRosterFilesByCompanyIdQueryVariables = Exact<{
  companyId: Scalars['ID'];
  nextToken?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<TOrderDirection>;
}>;

export type TListRosterFilesByCompanyIdQuery = {
  __typename?: 'Query';
  listRosterFilesByCompanyId: {
    __typename?: 'RosterFilesConnection';
    nextToken?: string | null;
    items: Array<{
      __typename?: 'RosterFile';
      id: string;
      companyId: string;
      headerMapping?: string | null;
      mapping: string;
      officeMapping?: string | null;
      changelog?: string | null;
      importStartedAt?: string | null;
      impoortEndedAt?: string | null;
      importErrors?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      _version: number;
      _deleted?: boolean | null;
      createdBy?: { __typename?: 'RosterFileCreatedBy'; username?: string | null; sub?: string | null } | null;
      file: { __typename?: 'RosterFileS3Object'; bucket: string; region: string; key: string; version?: string | null };
      changelogFile?: {
        __typename?: 'RosterFileS3Object';
        bucket: string;
        region: string;
        key: string;
        version?: string | null;
      } | null;
    } | null>;
  };
};

export type TCreateRosterFileMutationVariables = Exact<{
  input: TCreateRosterFileInput;
}>;

export type TCreateRosterFileMutation = {
  __typename?: 'Mutation';
  createRosterFile?: {
    __typename?: 'RosterFile';
    id: string;
    companyId: string;
    headerMapping?: string | null;
    mapping: string;
    officeMapping?: string | null;
    changelog?: string | null;
    importStartedAt?: string | null;
    impoortEndedAt?: string | null;
    importErrors?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    _version: number;
    _deleted?: boolean | null;
    createdBy?: { __typename?: 'RosterFileCreatedBy'; username?: string | null; sub?: string | null } | null;
    file: { __typename?: 'RosterFileS3Object'; bucket: string; region: string; key: string; version?: string | null };
    changelogFile?: {
      __typename?: 'RosterFileS3Object';
      bucket: string;
      region: string;
      key: string;
      version?: string | null;
    } | null;
  } | null;
};

export type TAnyRosterFileChangedFieldsFragment = {
  __typename?: 'RosterFile';
  id: string;
  companyId: string;
  headerMapping?: string | null;
  mapping: string;
  officeMapping?: string | null;
  changelog?: string | null;
  importStartedAt?: string | null;
  impoortEndedAt?: string | null;
  importErrors?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  _version: number;
  _deleted?: boolean | null;
  createdBy?: { __typename?: 'RosterFileCreatedBy'; username?: string | null; sub?: string | null } | null;
  file: { __typename?: 'RosterFileS3Object'; bucket: string; region: string; key: string; version?: string | null };
  changelogFile?: {
    __typename?: 'RosterFileS3Object';
    bucket: string;
    region: string;
    key: string;
    version?: string | null;
  } | null;
};

export type TOnAnyRosterFileChangedSubscriptionVariables = Exact<{ [key: string]: never }>;

export type TOnAnyRosterFileChangedSubscription = {
  __typename?: 'Subscription';
  onAnyRosterFileChanged?: {
    __typename?: 'RosterFile';
    id: string;
    companyId: string;
    headerMapping?: string | null;
    mapping: string;
    officeMapping?: string | null;
    changelog?: string | null;
    importStartedAt?: string | null;
    impoortEndedAt?: string | null;
    importErrors?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    _version: number;
    _deleted?: boolean | null;
    createdBy?: { __typename?: 'RosterFileCreatedBy'; username?: string | null; sub?: string | null } | null;
    file: { __typename?: 'RosterFileS3Object'; bucket: string; region: string; key: string; version?: string | null };
    changelogFile?: {
      __typename?: 'RosterFileS3Object';
      bucket: string;
      region: string;
      key: string;
      version?: string | null;
    } | null;
  } | null;
};

export type TShipmentBasicFieldsFragment = {
  __typename?: 'Shipment';
  id: string;
  companyId: string;
  addressId: string;
  addressVerified?: TShipStationAddressVerified | null;
  useGoogleAddress?: boolean | null;
  status: TShipmentStatuses;
  rack?: string | null;
  notes?: string | null;
  actualShippingDate?: string | null;
  shippingDate?: string | null;
  trackingNumber?: string | null;
  trackingURL?: string | null;
  shippedAt?: string | null;
  setForPickupAt?: string | null;
  pickedUpAt?: string | null;
  shipStationOrderId?: number | null;
  shipStationShipmentId?: number | null;
  lastShipStationResyncStartedAt?: string | null;
  shipStationError?: string | null;
  _version: number;
  _deleted?: boolean | null;
  alerts?: Array<{
    __typename?: 'Alert';
    message: string;
    oldItem?: string | null;
    newItem?: string | null;
    acknowledgedBy?: string | null;
    acknowledgedAt?: string | null;
  } | null> | null;
  packageInfo?: { __typename?: 'PackageInfo'; length: number; width: number; height: number; weight: number } | null;
  customsData?: Array<{
    __typename?: 'CustomsData';
    value: number;
    hsCode: string;
    quantity: number;
    description: string;
    country: string;
  } | null> | null;
  label?: {
    __typename?: 'ShipmentLabel';
    cost: number;
    createdAt?: string | null;
    carrier: string;
    labelFile?: {
      __typename?: 'S3Object';
      bucket: string;
      key: string;
      region: string;
      version?: string | null;
    } | null;
    addressData?: {
      __typename?: 'Address';
      id: string;
      address1?: string | null;
      address2?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      zipCode?: string | null;
      parentType: TAddressParentTypes;
      useGoogleAddress?: boolean | null;
      _version: number;
    } | null;
  } | null;
  history?: Array<{
    __typename?: 'ShipmentHistory';
    trackingNumber?: string | null;
    trackingURL?: string | null;
    label?: {
      __typename?: 'ShipmentLabel';
      cost: number;
      createdAt?: string | null;
      carrier: string;
      labelFile?: {
        __typename?: 'S3Object';
        bucket: string;
        key: string;
        region: string;
        version?: string | null;
      } | null;
      addressData?: {
        __typename?: 'Address';
        id: string;
        address1?: string | null;
        address2?: string | null;
        city?: string | null;
        state?: string | null;
        country?: string | null;
        zipCode?: string | null;
        parentType: TAddressParentTypes;
        useGoogleAddress?: boolean | null;
        _version: number;
      } | null;
    } | null;
  } | null> | null;
};

export type TShipmentFieldsFragment = {
  __typename?: 'Shipment';
  id: string;
  companyId: string;
  addressId: string;
  addressVerified?: TShipStationAddressVerified | null;
  useGoogleAddress?: boolean | null;
  status: TShipmentStatuses;
  rack?: string | null;
  notes?: string | null;
  actualShippingDate?: string | null;
  shippingDate?: string | null;
  trackingNumber?: string | null;
  trackingURL?: string | null;
  shippedAt?: string | null;
  setForPickupAt?: string | null;
  pickedUpAt?: string | null;
  shipStationOrderId?: number | null;
  shipStationShipmentId?: number | null;
  lastShipStationResyncStartedAt?: string | null;
  shipStationError?: string | null;
  _version: number;
  _deleted?: boolean | null;
  gifts?: {
    __typename?: 'GiftsConnection';
    items: Array<{
      __typename?: 'Gift';
      id: string;
      companyId: string;
      employeeId: string;
      recipientId: string;
      addressId?: string | null;
      eventId: string;
      shipmentId?: string | null;
      status: TGiftStatuses;
      type: TGiftTypes;
      customType?: string | null;
      eventDate: string;
      rack?: string | null;
      notes?: string | null;
      group?: string | null;
      doneAt?: string | null;
      isDone?: number | null;
      recreatedFromId?: string | null;
      recipientData?: string | null;
      _version: number;
      _deleted?: boolean | null;
      alerts?: Array<{
        __typename?: 'Alert';
        message: string;
        oldItem?: string | null;
        newItem?: string | null;
        acknowledgedBy?: string | null;
        acknowledgedAt?: string | null;
      } | null> | null;
    } | null>;
  } | null;
  alerts?: Array<{
    __typename?: 'Alert';
    message: string;
    oldItem?: string | null;
    newItem?: string | null;
    acknowledgedBy?: string | null;
    acknowledgedAt?: string | null;
  } | null> | null;
  packageInfo?: { __typename?: 'PackageInfo'; length: number; width: number; height: number; weight: number } | null;
  customsData?: Array<{
    __typename?: 'CustomsData';
    value: number;
    hsCode: string;
    quantity: number;
    description: string;
    country: string;
  } | null> | null;
  label?: {
    __typename?: 'ShipmentLabel';
    cost: number;
    createdAt?: string | null;
    carrier: string;
    labelFile?: {
      __typename?: 'S3Object';
      bucket: string;
      key: string;
      region: string;
      version?: string | null;
    } | null;
    addressData?: {
      __typename?: 'Address';
      id: string;
      address1?: string | null;
      address2?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      zipCode?: string | null;
      parentType: TAddressParentTypes;
      useGoogleAddress?: boolean | null;
      _version: number;
    } | null;
  } | null;
  history?: Array<{
    __typename?: 'ShipmentHistory';
    trackingNumber?: string | null;
    trackingURL?: string | null;
    label?: {
      __typename?: 'ShipmentLabel';
      cost: number;
      createdAt?: string | null;
      carrier: string;
      labelFile?: {
        __typename?: 'S3Object';
        bucket: string;
        key: string;
        region: string;
        version?: string | null;
      } | null;
      addressData?: {
        __typename?: 'Address';
        id: string;
        address1?: string | null;
        address2?: string | null;
        city?: string | null;
        state?: string | null;
        country?: string | null;
        zipCode?: string | null;
        parentType: TAddressParentTypes;
        useGoogleAddress?: boolean | null;
        _version: number;
      } | null;
    } | null;
  } | null> | null;
};

export type TListOpenShipmentsQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<TOrderDirection>;
}>;

export type TListOpenShipmentsQuery = {
  __typename?: 'Query';
  listOpenShipments: {
    __typename?: 'ShipmentsConnection';
    nextToken?: string | null;
    items: Array<{
      __typename?: 'Shipment';
      id: string;
      companyId: string;
      addressId: string;
      addressVerified?: TShipStationAddressVerified | null;
      useGoogleAddress?: boolean | null;
      status: TShipmentStatuses;
      rack?: string | null;
      notes?: string | null;
      actualShippingDate?: string | null;
      shippingDate?: string | null;
      trackingNumber?: string | null;
      trackingURL?: string | null;
      shippedAt?: string | null;
      setForPickupAt?: string | null;
      pickedUpAt?: string | null;
      shipStationOrderId?: number | null;
      shipStationShipmentId?: number | null;
      lastShipStationResyncStartedAt?: string | null;
      shipStationError?: string | null;
      _version: number;
      _deleted?: boolean | null;
      gifts?: {
        __typename?: 'GiftsConnection';
        items: Array<{
          __typename?: 'Gift';
          id: string;
          companyId: string;
          employeeId: string;
          recipientId: string;
          addressId?: string | null;
          eventId: string;
          shipmentId?: string | null;
          status: TGiftStatuses;
          type: TGiftTypes;
          customType?: string | null;
          eventDate: string;
          rack?: string | null;
          notes?: string | null;
          group?: string | null;
          doneAt?: string | null;
          isDone?: number | null;
          recreatedFromId?: string | null;
          recipientData?: string | null;
          _version: number;
          _deleted?: boolean | null;
          alerts?: Array<{
            __typename?: 'Alert';
            message: string;
            oldItem?: string | null;
            newItem?: string | null;
            acknowledgedBy?: string | null;
            acknowledgedAt?: string | null;
          } | null> | null;
        } | null>;
      } | null;
      alerts?: Array<{
        __typename?: 'Alert';
        message: string;
        oldItem?: string | null;
        newItem?: string | null;
        acknowledgedBy?: string | null;
        acknowledgedAt?: string | null;
      } | null> | null;
      packageInfo?: {
        __typename?: 'PackageInfo';
        length: number;
        width: number;
        height: number;
        weight: number;
      } | null;
      customsData?: Array<{
        __typename?: 'CustomsData';
        value: number;
        hsCode: string;
        quantity: number;
        description: string;
        country: string;
      } | null> | null;
      label?: {
        __typename?: 'ShipmentLabel';
        cost: number;
        createdAt?: string | null;
        carrier: string;
        labelFile?: {
          __typename?: 'S3Object';
          bucket: string;
          key: string;
          region: string;
          version?: string | null;
        } | null;
        addressData?: {
          __typename?: 'Address';
          id: string;
          address1?: string | null;
          address2?: string | null;
          city?: string | null;
          state?: string | null;
          country?: string | null;
          zipCode?: string | null;
          parentType: TAddressParentTypes;
          useGoogleAddress?: boolean | null;
          _version: number;
        } | null;
      } | null;
      history?: Array<{
        __typename?: 'ShipmentHistory';
        trackingNumber?: string | null;
        trackingURL?: string | null;
        label?: {
          __typename?: 'ShipmentLabel';
          cost: number;
          createdAt?: string | null;
          carrier: string;
          labelFile?: {
            __typename?: 'S3Object';
            bucket: string;
            key: string;
            region: string;
            version?: string | null;
          } | null;
          addressData?: {
            __typename?: 'Address';
            id: string;
            address1?: string | null;
            address2?: string | null;
            city?: string | null;
            state?: string | null;
            country?: string | null;
            zipCode?: string | null;
            parentType: TAddressParentTypes;
            useGoogleAddress?: boolean | null;
            _version: number;
          } | null;
        } | null;
      } | null> | null;
    } | null>;
  };
};

export type TListShippedShipmentsQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<TOrderDirection>;
}>;

export type TListShippedShipmentsQuery = {
  __typename?: 'Query';
  listShippedShipments?: {
    __typename?: 'ShipmentsConnection';
    nextToken?: string | null;
    items: Array<{
      __typename?: 'Shipment';
      id: string;
      companyId: string;
      addressId: string;
      addressVerified?: TShipStationAddressVerified | null;
      useGoogleAddress?: boolean | null;
      status: TShipmentStatuses;
      rack?: string | null;
      notes?: string | null;
      actualShippingDate?: string | null;
      shippingDate?: string | null;
      trackingNumber?: string | null;
      trackingURL?: string | null;
      shippedAt?: string | null;
      setForPickupAt?: string | null;
      pickedUpAt?: string | null;
      shipStationOrderId?: number | null;
      shipStationShipmentId?: number | null;
      lastShipStationResyncStartedAt?: string | null;
      shipStationError?: string | null;
      _version: number;
      _deleted?: boolean | null;
      gifts?: {
        __typename?: 'GiftsConnection';
        items: Array<{
          __typename?: 'Gift';
          id: string;
          companyId: string;
          employeeId: string;
          recipientId: string;
          addressId?: string | null;
          eventId: string;
          shipmentId?: string | null;
          status: TGiftStatuses;
          type: TGiftTypes;
          customType?: string | null;
          eventDate: string;
          rack?: string | null;
          notes?: string | null;
          group?: string | null;
          doneAt?: string | null;
          isDone?: number | null;
          recreatedFromId?: string | null;
          recipientData?: string | null;
          _version: number;
          _deleted?: boolean | null;
          alerts?: Array<{
            __typename?: 'Alert';
            message: string;
            oldItem?: string | null;
            newItem?: string | null;
            acknowledgedBy?: string | null;
            acknowledgedAt?: string | null;
          } | null> | null;
        } | null>;
      } | null;
      alerts?: Array<{
        __typename?: 'Alert';
        message: string;
        oldItem?: string | null;
        newItem?: string | null;
        acknowledgedBy?: string | null;
        acknowledgedAt?: string | null;
      } | null> | null;
      packageInfo?: {
        __typename?: 'PackageInfo';
        length: number;
        width: number;
        height: number;
        weight: number;
      } | null;
      customsData?: Array<{
        __typename?: 'CustomsData';
        value: number;
        hsCode: string;
        quantity: number;
        description: string;
        country: string;
      } | null> | null;
      label?: {
        __typename?: 'ShipmentLabel';
        cost: number;
        createdAt?: string | null;
        carrier: string;
        labelFile?: {
          __typename?: 'S3Object';
          bucket: string;
          key: string;
          region: string;
          version?: string | null;
        } | null;
        addressData?: {
          __typename?: 'Address';
          id: string;
          address1?: string | null;
          address2?: string | null;
          city?: string | null;
          state?: string | null;
          country?: string | null;
          zipCode?: string | null;
          parentType: TAddressParentTypes;
          useGoogleAddress?: boolean | null;
          _version: number;
        } | null;
      } | null;
      history?: Array<{
        __typename?: 'ShipmentHistory';
        trackingNumber?: string | null;
        trackingURL?: string | null;
        label?: {
          __typename?: 'ShipmentLabel';
          cost: number;
          createdAt?: string | null;
          carrier: string;
          labelFile?: {
            __typename?: 'S3Object';
            bucket: string;
            key: string;
            region: string;
            version?: string | null;
          } | null;
          addressData?: {
            __typename?: 'Address';
            id: string;
            address1?: string | null;
            address2?: string | null;
            city?: string | null;
            state?: string | null;
            country?: string | null;
            zipCode?: string | null;
            parentType: TAddressParentTypes;
            useGoogleAddress?: boolean | null;
            _version: number;
          } | null;
        } | null;
      } | null> | null;
    } | null>;
  } | null;
};

export type TListSetForPickupShipmentsQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<TOrderDirection>;
}>;

export type TListSetForPickupShipmentsQuery = {
  __typename?: 'Query';
  listSetForPickupShipments?: {
    __typename?: 'ShipmentsConnection';
    nextToken?: string | null;
    items: Array<{
      __typename?: 'Shipment';
      id: string;
      companyId: string;
      addressId: string;
      addressVerified?: TShipStationAddressVerified | null;
      useGoogleAddress?: boolean | null;
      status: TShipmentStatuses;
      rack?: string | null;
      notes?: string | null;
      actualShippingDate?: string | null;
      shippingDate?: string | null;
      trackingNumber?: string | null;
      trackingURL?: string | null;
      shippedAt?: string | null;
      setForPickupAt?: string | null;
      pickedUpAt?: string | null;
      shipStationOrderId?: number | null;
      shipStationShipmentId?: number | null;
      lastShipStationResyncStartedAt?: string | null;
      shipStationError?: string | null;
      _version: number;
      _deleted?: boolean | null;
      gifts?: {
        __typename?: 'GiftsConnection';
        items: Array<{
          __typename?: 'Gift';
          id: string;
          companyId: string;
          employeeId: string;
          recipientId: string;
          addressId?: string | null;
          eventId: string;
          shipmentId?: string | null;
          status: TGiftStatuses;
          type: TGiftTypes;
          customType?: string | null;
          eventDate: string;
          rack?: string | null;
          notes?: string | null;
          group?: string | null;
          doneAt?: string | null;
          isDone?: number | null;
          recreatedFromId?: string | null;
          recipientData?: string | null;
          _version: number;
          _deleted?: boolean | null;
          alerts?: Array<{
            __typename?: 'Alert';
            message: string;
            oldItem?: string | null;
            newItem?: string | null;
            acknowledgedBy?: string | null;
            acknowledgedAt?: string | null;
          } | null> | null;
        } | null>;
      } | null;
      alerts?: Array<{
        __typename?: 'Alert';
        message: string;
        oldItem?: string | null;
        newItem?: string | null;
        acknowledgedBy?: string | null;
        acknowledgedAt?: string | null;
      } | null> | null;
      packageInfo?: {
        __typename?: 'PackageInfo';
        length: number;
        width: number;
        height: number;
        weight: number;
      } | null;
      customsData?: Array<{
        __typename?: 'CustomsData';
        value: number;
        hsCode: string;
        quantity: number;
        description: string;
        country: string;
      } | null> | null;
      label?: {
        __typename?: 'ShipmentLabel';
        cost: number;
        createdAt?: string | null;
        carrier: string;
        labelFile?: {
          __typename?: 'S3Object';
          bucket: string;
          key: string;
          region: string;
          version?: string | null;
        } | null;
        addressData?: {
          __typename?: 'Address';
          id: string;
          address1?: string | null;
          address2?: string | null;
          city?: string | null;
          state?: string | null;
          country?: string | null;
          zipCode?: string | null;
          parentType: TAddressParentTypes;
          useGoogleAddress?: boolean | null;
          _version: number;
        } | null;
      } | null;
      history?: Array<{
        __typename?: 'ShipmentHistory';
        trackingNumber?: string | null;
        trackingURL?: string | null;
        label?: {
          __typename?: 'ShipmentLabel';
          cost: number;
          createdAt?: string | null;
          carrier: string;
          labelFile?: {
            __typename?: 'S3Object';
            bucket: string;
            key: string;
            region: string;
            version?: string | null;
          } | null;
          addressData?: {
            __typename?: 'Address';
            id: string;
            address1?: string | null;
            address2?: string | null;
            city?: string | null;
            state?: string | null;
            country?: string | null;
            zipCode?: string | null;
            parentType: TAddressParentTypes;
            useGoogleAddress?: boolean | null;
            _version: number;
          } | null;
        } | null;
      } | null> | null;
    } | null>;
  } | null;
};

export type TListShipmentsByAddressAndStatusQueryVariables = Exact<{
  addressId: Scalars['ID'];
  status: TShipmentStatuses;
  nextToken?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<TOrderDirection>;
}>;

export type TListShipmentsByAddressAndStatusQuery = {
  __typename?: 'Query';
  listShipmentsByAddressAndStatus: {
    __typename?: 'ShipmentsConnection';
    nextToken?: string | null;
    items: Array<{
      __typename?: 'Shipment';
      id: string;
      companyId: string;
      addressId: string;
      addressVerified?: TShipStationAddressVerified | null;
      useGoogleAddress?: boolean | null;
      status: TShipmentStatuses;
      rack?: string | null;
      notes?: string | null;
      actualShippingDate?: string | null;
      shippingDate?: string | null;
      trackingNumber?: string | null;
      trackingURL?: string | null;
      shippedAt?: string | null;
      setForPickupAt?: string | null;
      pickedUpAt?: string | null;
      shipStationOrderId?: number | null;
      shipStationShipmentId?: number | null;
      lastShipStationResyncStartedAt?: string | null;
      shipStationError?: string | null;
      _version: number;
      _deleted?: boolean | null;
      gifts?: {
        __typename?: 'GiftsConnection';
        items: Array<{
          __typename?: 'Gift';
          id: string;
          companyId: string;
          employeeId: string;
          recipientId: string;
          addressId?: string | null;
          eventId: string;
          shipmentId?: string | null;
          status: TGiftStatuses;
          type: TGiftTypes;
          customType?: string | null;
          eventDate: string;
          rack?: string | null;
          notes?: string | null;
          group?: string | null;
          doneAt?: string | null;
          isDone?: number | null;
          recreatedFromId?: string | null;
          recipientData?: string | null;
          _version: number;
          _deleted?: boolean | null;
          alerts?: Array<{
            __typename?: 'Alert';
            message: string;
            oldItem?: string | null;
            newItem?: string | null;
            acknowledgedBy?: string | null;
            acknowledgedAt?: string | null;
          } | null> | null;
        } | null>;
      } | null;
      alerts?: Array<{
        __typename?: 'Alert';
        message: string;
        oldItem?: string | null;
        newItem?: string | null;
        acknowledgedBy?: string | null;
        acknowledgedAt?: string | null;
      } | null> | null;
      packageInfo?: {
        __typename?: 'PackageInfo';
        length: number;
        width: number;
        height: number;
        weight: number;
      } | null;
      customsData?: Array<{
        __typename?: 'CustomsData';
        value: number;
        hsCode: string;
        quantity: number;
        description: string;
        country: string;
      } | null> | null;
      label?: {
        __typename?: 'ShipmentLabel';
        cost: number;
        createdAt?: string | null;
        carrier: string;
        labelFile?: {
          __typename?: 'S3Object';
          bucket: string;
          key: string;
          region: string;
          version?: string | null;
        } | null;
        addressData?: {
          __typename?: 'Address';
          id: string;
          address1?: string | null;
          address2?: string | null;
          city?: string | null;
          state?: string | null;
          country?: string | null;
          zipCode?: string | null;
          parentType: TAddressParentTypes;
          useGoogleAddress?: boolean | null;
          _version: number;
        } | null;
      } | null;
      history?: Array<{
        __typename?: 'ShipmentHistory';
        trackingNumber?: string | null;
        trackingURL?: string | null;
        label?: {
          __typename?: 'ShipmentLabel';
          cost: number;
          createdAt?: string | null;
          carrier: string;
          labelFile?: {
            __typename?: 'S3Object';
            bucket: string;
            key: string;
            region: string;
            version?: string | null;
          } | null;
          addressData?: {
            __typename?: 'Address';
            id: string;
            address1?: string | null;
            address2?: string | null;
            city?: string | null;
            state?: string | null;
            country?: string | null;
            zipCode?: string | null;
            parentType: TAddressParentTypes;
            useGoogleAddress?: boolean | null;
            _version: number;
          } | null;
        } | null;
      } | null> | null;
    } | null>;
  };
};

export type TGetShipmentQueryVariables = Exact<{
  id: Scalars['ID'];
  companyId: Scalars['ID'];
}>;

export type TGetShipmentQuery = {
  __typename?: 'Query';
  getShipment?: {
    __typename?: 'Shipment';
    id: string;
    companyId: string;
    addressId: string;
    addressVerified?: TShipStationAddressVerified | null;
    useGoogleAddress?: boolean | null;
    status: TShipmentStatuses;
    rack?: string | null;
    notes?: string | null;
    actualShippingDate?: string | null;
    shippingDate?: string | null;
    trackingNumber?: string | null;
    trackingURL?: string | null;
    shippedAt?: string | null;
    setForPickupAt?: string | null;
    pickedUpAt?: string | null;
    shipStationOrderId?: number | null;
    shipStationShipmentId?: number | null;
    lastShipStationResyncStartedAt?: string | null;
    shipStationError?: string | null;
    _version: number;
    _deleted?: boolean | null;
    gifts?: {
      __typename?: 'GiftsConnection';
      items: Array<{
        __typename?: 'Gift';
        id: string;
        companyId: string;
        employeeId: string;
        recipientId: string;
        addressId?: string | null;
        eventId: string;
        shipmentId?: string | null;
        status: TGiftStatuses;
        type: TGiftTypes;
        customType?: string | null;
        eventDate: string;
        rack?: string | null;
        notes?: string | null;
        group?: string | null;
        doneAt?: string | null;
        isDone?: number | null;
        recreatedFromId?: string | null;
        recipientData?: string | null;
        _version: number;
        _deleted?: boolean | null;
        alerts?: Array<{
          __typename?: 'Alert';
          message: string;
          oldItem?: string | null;
          newItem?: string | null;
          acknowledgedBy?: string | null;
          acknowledgedAt?: string | null;
        } | null> | null;
      } | null>;
    } | null;
    alerts?: Array<{
      __typename?: 'Alert';
      message: string;
      oldItem?: string | null;
      newItem?: string | null;
      acknowledgedBy?: string | null;
      acknowledgedAt?: string | null;
    } | null> | null;
    packageInfo?: { __typename?: 'PackageInfo'; length: number; width: number; height: number; weight: number } | null;
    customsData?: Array<{
      __typename?: 'CustomsData';
      value: number;
      hsCode: string;
      quantity: number;
      description: string;
      country: string;
    } | null> | null;
    label?: {
      __typename?: 'ShipmentLabel';
      cost: number;
      createdAt?: string | null;
      carrier: string;
      labelFile?: {
        __typename?: 'S3Object';
        bucket: string;
        key: string;
        region: string;
        version?: string | null;
      } | null;
      addressData?: {
        __typename?: 'Address';
        id: string;
        address1?: string | null;
        address2?: string | null;
        city?: string | null;
        state?: string | null;
        country?: string | null;
        zipCode?: string | null;
        parentType: TAddressParentTypes;
        useGoogleAddress?: boolean | null;
        _version: number;
      } | null;
    } | null;
    history?: Array<{
      __typename?: 'ShipmentHistory';
      trackingNumber?: string | null;
      trackingURL?: string | null;
      label?: {
        __typename?: 'ShipmentLabel';
        cost: number;
        createdAt?: string | null;
        carrier: string;
        labelFile?: {
          __typename?: 'S3Object';
          bucket: string;
          key: string;
          region: string;
          version?: string | null;
        } | null;
        addressData?: {
          __typename?: 'Address';
          id: string;
          address1?: string | null;
          address2?: string | null;
          city?: string | null;
          state?: string | null;
          country?: string | null;
          zipCode?: string | null;
          parentType: TAddressParentTypes;
          useGoogleAddress?: boolean | null;
          _version: number;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type TCreateShipmentWithGiftsMutationVariables = Exact<{
  input: TCreateShipmentWithGiftsMutationInput;
}>;

export type TCreateShipmentWithGiftsMutation = {
  __typename?: 'Mutation';
  createShipmentWithGifts?: {
    __typename?: 'Shipment';
    id: string;
    companyId: string;
    addressId: string;
    addressVerified?: TShipStationAddressVerified | null;
    useGoogleAddress?: boolean | null;
    status: TShipmentStatuses;
    rack?: string | null;
    notes?: string | null;
    actualShippingDate?: string | null;
    shippingDate?: string | null;
    trackingNumber?: string | null;
    trackingURL?: string | null;
    shippedAt?: string | null;
    setForPickupAt?: string | null;
    pickedUpAt?: string | null;
    shipStationOrderId?: number | null;
    shipStationShipmentId?: number | null;
    lastShipStationResyncStartedAt?: string | null;
    shipStationError?: string | null;
    _version: number;
    _deleted?: boolean | null;
    gifts?: {
      __typename?: 'GiftsConnection';
      items: Array<{
        __typename?: 'Gift';
        id: string;
        companyId: string;
        employeeId: string;
        recipientId: string;
        addressId?: string | null;
        eventId: string;
        shipmentId?: string | null;
        status: TGiftStatuses;
        type: TGiftTypes;
        customType?: string | null;
        eventDate: string;
        rack?: string | null;
        notes?: string | null;
        group?: string | null;
        doneAt?: string | null;
        isDone?: number | null;
        recreatedFromId?: string | null;
        recipientData?: string | null;
        _version: number;
        _deleted?: boolean | null;
        alerts?: Array<{
          __typename?: 'Alert';
          message: string;
          oldItem?: string | null;
          newItem?: string | null;
          acknowledgedBy?: string | null;
          acknowledgedAt?: string | null;
        } | null> | null;
      } | null>;
    } | null;
    alerts?: Array<{
      __typename?: 'Alert';
      message: string;
      oldItem?: string | null;
      newItem?: string | null;
      acknowledgedBy?: string | null;
      acknowledgedAt?: string | null;
    } | null> | null;
    packageInfo?: { __typename?: 'PackageInfo'; length: number; width: number; height: number; weight: number } | null;
    customsData?: Array<{
      __typename?: 'CustomsData';
      value: number;
      hsCode: string;
      quantity: number;
      description: string;
      country: string;
    } | null> | null;
    label?: {
      __typename?: 'ShipmentLabel';
      cost: number;
      createdAt?: string | null;
      carrier: string;
      labelFile?: {
        __typename?: 'S3Object';
        bucket: string;
        key: string;
        region: string;
        version?: string | null;
      } | null;
      addressData?: {
        __typename?: 'Address';
        id: string;
        address1?: string | null;
        address2?: string | null;
        city?: string | null;
        state?: string | null;
        country?: string | null;
        zipCode?: string | null;
        parentType: TAddressParentTypes;
        useGoogleAddress?: boolean | null;
        _version: number;
      } | null;
    } | null;
    history?: Array<{
      __typename?: 'ShipmentHistory';
      trackingNumber?: string | null;
      trackingURL?: string | null;
      label?: {
        __typename?: 'ShipmentLabel';
        cost: number;
        createdAt?: string | null;
        carrier: string;
        labelFile?: {
          __typename?: 'S3Object';
          bucket: string;
          key: string;
          region: string;
          version?: string | null;
        } | null;
        addressData?: {
          __typename?: 'Address';
          id: string;
          address1?: string | null;
          address2?: string | null;
          city?: string | null;
          state?: string | null;
          country?: string | null;
          zipCode?: string | null;
          parentType: TAddressParentTypes;
          useGoogleAddress?: boolean | null;
          _version: number;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type TAddGiftsToShipmentMutationVariables = Exact<{
  shipmentId: Scalars['ID'];
  companyId: Scalars['ID'];
  giftsIds: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type TAddGiftsToShipmentMutation = {
  __typename?: 'Mutation';
  addGiftsToShipment?: {
    __typename?: 'Shipment';
    id: string;
    companyId: string;
    addressId: string;
    addressVerified?: TShipStationAddressVerified | null;
    useGoogleAddress?: boolean | null;
    status: TShipmentStatuses;
    rack?: string | null;
    notes?: string | null;
    actualShippingDate?: string | null;
    shippingDate?: string | null;
    trackingNumber?: string | null;
    trackingURL?: string | null;
    shippedAt?: string | null;
    setForPickupAt?: string | null;
    pickedUpAt?: string | null;
    shipStationOrderId?: number | null;
    shipStationShipmentId?: number | null;
    lastShipStationResyncStartedAt?: string | null;
    shipStationError?: string | null;
    _version: number;
    _deleted?: boolean | null;
    gifts?: {
      __typename?: 'GiftsConnection';
      items: Array<{
        __typename?: 'Gift';
        id: string;
        companyId: string;
        employeeId: string;
        recipientId: string;
        addressId?: string | null;
        eventId: string;
        shipmentId?: string | null;
        status: TGiftStatuses;
        type: TGiftTypes;
        customType?: string | null;
        eventDate: string;
        rack?: string | null;
        notes?: string | null;
        group?: string | null;
        doneAt?: string | null;
        isDone?: number | null;
        recreatedFromId?: string | null;
        recipientData?: string | null;
        _version: number;
        _deleted?: boolean | null;
        alerts?: Array<{
          __typename?: 'Alert';
          message: string;
          oldItem?: string | null;
          newItem?: string | null;
          acknowledgedBy?: string | null;
          acknowledgedAt?: string | null;
        } | null> | null;
      } | null>;
    } | null;
    alerts?: Array<{
      __typename?: 'Alert';
      message: string;
      oldItem?: string | null;
      newItem?: string | null;
      acknowledgedBy?: string | null;
      acknowledgedAt?: string | null;
    } | null> | null;
    packageInfo?: { __typename?: 'PackageInfo'; length: number; width: number; height: number; weight: number } | null;
    customsData?: Array<{
      __typename?: 'CustomsData';
      value: number;
      hsCode: string;
      quantity: number;
      description: string;
      country: string;
    } | null> | null;
    label?: {
      __typename?: 'ShipmentLabel';
      cost: number;
      createdAt?: string | null;
      carrier: string;
      labelFile?: {
        __typename?: 'S3Object';
        bucket: string;
        key: string;
        region: string;
        version?: string | null;
      } | null;
      addressData?: {
        __typename?: 'Address';
        id: string;
        address1?: string | null;
        address2?: string | null;
        city?: string | null;
        state?: string | null;
        country?: string | null;
        zipCode?: string | null;
        parentType: TAddressParentTypes;
        useGoogleAddress?: boolean | null;
        _version: number;
      } | null;
    } | null;
    history?: Array<{
      __typename?: 'ShipmentHistory';
      trackingNumber?: string | null;
      trackingURL?: string | null;
      label?: {
        __typename?: 'ShipmentLabel';
        cost: number;
        createdAt?: string | null;
        carrier: string;
        labelFile?: {
          __typename?: 'S3Object';
          bucket: string;
          key: string;
          region: string;
          version?: string | null;
        } | null;
        addressData?: {
          __typename?: 'Address';
          id: string;
          address1?: string | null;
          address2?: string | null;
          city?: string | null;
          state?: string | null;
          country?: string | null;
          zipCode?: string | null;
          parentType: TAddressParentTypes;
          useGoogleAddress?: boolean | null;
          _version: number;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type TUpdateShipmentMutationVariables = Exact<{
  id: Scalars['ID'];
  companyId: Scalars['ID'];
  version: Scalars['Int'];
  input: TUpdateShipmentMutationInput;
}>;

export type TUpdateShipmentMutation = {
  __typename?: 'Mutation';
  updateShipment?: {
    __typename?: 'Shipment';
    id: string;
    companyId: string;
    addressId: string;
    addressVerified?: TShipStationAddressVerified | null;
    useGoogleAddress?: boolean | null;
    status: TShipmentStatuses;
    rack?: string | null;
    notes?: string | null;
    actualShippingDate?: string | null;
    shippingDate?: string | null;
    trackingNumber?: string | null;
    trackingURL?: string | null;
    shippedAt?: string | null;
    setForPickupAt?: string | null;
    pickedUpAt?: string | null;
    shipStationOrderId?: number | null;
    shipStationShipmentId?: number | null;
    lastShipStationResyncStartedAt?: string | null;
    shipStationError?: string | null;
    _version: number;
    _deleted?: boolean | null;
    alerts?: Array<{
      __typename?: 'Alert';
      message: string;
      oldItem?: string | null;
      newItem?: string | null;
      acknowledgedBy?: string | null;
      acknowledgedAt?: string | null;
    } | null> | null;
    packageInfo?: { __typename?: 'PackageInfo'; length: number; width: number; height: number; weight: number } | null;
    customsData?: Array<{
      __typename?: 'CustomsData';
      value: number;
      hsCode: string;
      quantity: number;
      description: string;
      country: string;
    } | null> | null;
    label?: {
      __typename?: 'ShipmentLabel';
      cost: number;
      createdAt?: string | null;
      carrier: string;
      labelFile?: {
        __typename?: 'S3Object';
        bucket: string;
        key: string;
        region: string;
        version?: string | null;
      } | null;
      addressData?: {
        __typename?: 'Address';
        id: string;
        address1?: string | null;
        address2?: string | null;
        city?: string | null;
        state?: string | null;
        country?: string | null;
        zipCode?: string | null;
        parentType: TAddressParentTypes;
        useGoogleAddress?: boolean | null;
        _version: number;
      } | null;
    } | null;
    history?: Array<{
      __typename?: 'ShipmentHistory';
      trackingNumber?: string | null;
      trackingURL?: string | null;
      label?: {
        __typename?: 'ShipmentLabel';
        cost: number;
        createdAt?: string | null;
        carrier: string;
        labelFile?: {
          __typename?: 'S3Object';
          bucket: string;
          key: string;
          region: string;
          version?: string | null;
        } | null;
        addressData?: {
          __typename?: 'Address';
          id: string;
          address1?: string | null;
          address2?: string | null;
          city?: string | null;
          state?: string | null;
          country?: string | null;
          zipCode?: string | null;
          parentType: TAddressParentTypes;
          useGoogleAddress?: boolean | null;
          _version: number;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type TSetShipmentForPickupMutationVariables = Exact<{
  id: Scalars['ID'];
  companyId: Scalars['ID'];
  version: Scalars['Int'];
}>;

export type TSetShipmentForPickupMutation = {
  __typename?: 'Mutation';
  setShipmentForPickup?: {
    __typename?: 'Shipment';
    id: string;
    companyId: string;
    addressId: string;
    addressVerified?: TShipStationAddressVerified | null;
    useGoogleAddress?: boolean | null;
    status: TShipmentStatuses;
    rack?: string | null;
    notes?: string | null;
    actualShippingDate?: string | null;
    shippingDate?: string | null;
    trackingNumber?: string | null;
    trackingURL?: string | null;
    shippedAt?: string | null;
    setForPickupAt?: string | null;
    pickedUpAt?: string | null;
    shipStationOrderId?: number | null;
    shipStationShipmentId?: number | null;
    lastShipStationResyncStartedAt?: string | null;
    shipStationError?: string | null;
    _version: number;
    _deleted?: boolean | null;
    alerts?: Array<{
      __typename?: 'Alert';
      message: string;
      oldItem?: string | null;
      newItem?: string | null;
      acknowledgedBy?: string | null;
      acknowledgedAt?: string | null;
    } | null> | null;
    packageInfo?: { __typename?: 'PackageInfo'; length: number; width: number; height: number; weight: number } | null;
    customsData?: Array<{
      __typename?: 'CustomsData';
      value: number;
      hsCode: string;
      quantity: number;
      description: string;
      country: string;
    } | null> | null;
    label?: {
      __typename?: 'ShipmentLabel';
      cost: number;
      createdAt?: string | null;
      carrier: string;
      labelFile?: {
        __typename?: 'S3Object';
        bucket: string;
        key: string;
        region: string;
        version?: string | null;
      } | null;
      addressData?: {
        __typename?: 'Address';
        id: string;
        address1?: string | null;
        address2?: string | null;
        city?: string | null;
        state?: string | null;
        country?: string | null;
        zipCode?: string | null;
        parentType: TAddressParentTypes;
        useGoogleAddress?: boolean | null;
        _version: number;
      } | null;
    } | null;
    history?: Array<{
      __typename?: 'ShipmentHistory';
      trackingNumber?: string | null;
      trackingURL?: string | null;
      label?: {
        __typename?: 'ShipmentLabel';
        cost: number;
        createdAt?: string | null;
        carrier: string;
        labelFile?: {
          __typename?: 'S3Object';
          bucket: string;
          key: string;
          region: string;
          version?: string | null;
        } | null;
        addressData?: {
          __typename?: 'Address';
          id: string;
          address1?: string | null;
          address2?: string | null;
          city?: string | null;
          state?: string | null;
          country?: string | null;
          zipCode?: string | null;
          parentType: TAddressParentTypes;
          useGoogleAddress?: boolean | null;
          _version: number;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type TSetShipmentAsPickedUpMutationVariables = Exact<{
  id: Scalars['ID'];
  companyId: Scalars['ID'];
  version: Scalars['Int'];
}>;

export type TSetShipmentAsPickedUpMutation = {
  __typename?: 'Mutation';
  setShipmentAsPickedUp?: {
    __typename?: 'Shipment';
    id: string;
    companyId: string;
    addressId: string;
    addressVerified?: TShipStationAddressVerified | null;
    useGoogleAddress?: boolean | null;
    status: TShipmentStatuses;
    rack?: string | null;
    notes?: string | null;
    actualShippingDate?: string | null;
    shippingDate?: string | null;
    trackingNumber?: string | null;
    trackingURL?: string | null;
    shippedAt?: string | null;
    setForPickupAt?: string | null;
    pickedUpAt?: string | null;
    shipStationOrderId?: number | null;
    shipStationShipmentId?: number | null;
    lastShipStationResyncStartedAt?: string | null;
    shipStationError?: string | null;
    _version: number;
    _deleted?: boolean | null;
    alerts?: Array<{
      __typename?: 'Alert';
      message: string;
      oldItem?: string | null;
      newItem?: string | null;
      acknowledgedBy?: string | null;
      acknowledgedAt?: string | null;
    } | null> | null;
    packageInfo?: { __typename?: 'PackageInfo'; length: number; width: number; height: number; weight: number } | null;
    customsData?: Array<{
      __typename?: 'CustomsData';
      value: number;
      hsCode: string;
      quantity: number;
      description: string;
      country: string;
    } | null> | null;
    label?: {
      __typename?: 'ShipmentLabel';
      cost: number;
      createdAt?: string | null;
      carrier: string;
      labelFile?: {
        __typename?: 'S3Object';
        bucket: string;
        key: string;
        region: string;
        version?: string | null;
      } | null;
      addressData?: {
        __typename?: 'Address';
        id: string;
        address1?: string | null;
        address2?: string | null;
        city?: string | null;
        state?: string | null;
        country?: string | null;
        zipCode?: string | null;
        parentType: TAddressParentTypes;
        useGoogleAddress?: boolean | null;
        _version: number;
      } | null;
    } | null;
    history?: Array<{
      __typename?: 'ShipmentHistory';
      trackingNumber?: string | null;
      trackingURL?: string | null;
      label?: {
        __typename?: 'ShipmentLabel';
        cost: number;
        createdAt?: string | null;
        carrier: string;
        labelFile?: {
          __typename?: 'S3Object';
          bucket: string;
          key: string;
          region: string;
          version?: string | null;
        } | null;
        addressData?: {
          __typename?: 'Address';
          id: string;
          address1?: string | null;
          address2?: string | null;
          city?: string | null;
          state?: string | null;
          country?: string | null;
          zipCode?: string | null;
          parentType: TAddressParentTypes;
          useGoogleAddress?: boolean | null;
          _version: number;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type TCancelShipmentForPickupMutationVariables = Exact<{
  id: Scalars['ID'];
  companyId: Scalars['ID'];
  version: Scalars['Int'];
}>;

export type TCancelShipmentForPickupMutation = {
  __typename?: 'Mutation';
  cancelShipmentForPickup?: {
    __typename?: 'Shipment';
    id: string;
    companyId: string;
    addressId: string;
    addressVerified?: TShipStationAddressVerified | null;
    useGoogleAddress?: boolean | null;
    status: TShipmentStatuses;
    rack?: string | null;
    notes?: string | null;
    actualShippingDate?: string | null;
    shippingDate?: string | null;
    trackingNumber?: string | null;
    trackingURL?: string | null;
    shippedAt?: string | null;
    setForPickupAt?: string | null;
    pickedUpAt?: string | null;
    shipStationOrderId?: number | null;
    shipStationShipmentId?: number | null;
    lastShipStationResyncStartedAt?: string | null;
    shipStationError?: string | null;
    _version: number;
    _deleted?: boolean | null;
    alerts?: Array<{
      __typename?: 'Alert';
      message: string;
      oldItem?: string | null;
      newItem?: string | null;
      acknowledgedBy?: string | null;
      acknowledgedAt?: string | null;
    } | null> | null;
    packageInfo?: { __typename?: 'PackageInfo'; length: number; width: number; height: number; weight: number } | null;
    customsData?: Array<{
      __typename?: 'CustomsData';
      value: number;
      hsCode: string;
      quantity: number;
      description: string;
      country: string;
    } | null> | null;
    label?: {
      __typename?: 'ShipmentLabel';
      cost: number;
      createdAt?: string | null;
      carrier: string;
      labelFile?: {
        __typename?: 'S3Object';
        bucket: string;
        key: string;
        region: string;
        version?: string | null;
      } | null;
      addressData?: {
        __typename?: 'Address';
        id: string;
        address1?: string | null;
        address2?: string | null;
        city?: string | null;
        state?: string | null;
        country?: string | null;
        zipCode?: string | null;
        parentType: TAddressParentTypes;
        useGoogleAddress?: boolean | null;
        _version: number;
      } | null;
    } | null;
    history?: Array<{
      __typename?: 'ShipmentHistory';
      trackingNumber?: string | null;
      trackingURL?: string | null;
      label?: {
        __typename?: 'ShipmentLabel';
        cost: number;
        createdAt?: string | null;
        carrier: string;
        labelFile?: {
          __typename?: 'S3Object';
          bucket: string;
          key: string;
          region: string;
          version?: string | null;
        } | null;
        addressData?: {
          __typename?: 'Address';
          id: string;
          address1?: string | null;
          address2?: string | null;
          city?: string | null;
          state?: string | null;
          country?: string | null;
          zipCode?: string | null;
          parentType: TAddressParentTypes;
          useGoogleAddress?: boolean | null;
          _version: number;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type TSetShipmentAsReadyToShipMutationVariables = Exact<{
  id: Scalars['ID'];
  companyId: Scalars['ID'];
  version: Scalars['Int'];
  input: TSetShipmentAsReadyToShipMutationInput;
}>;

export type TSetShipmentAsReadyToShipMutation = {
  __typename?: 'Mutation';
  setShipmentAsReadyToShip?: {
    __typename?: 'Shipment';
    id: string;
    companyId: string;
    addressId: string;
    addressVerified?: TShipStationAddressVerified | null;
    useGoogleAddress?: boolean | null;
    status: TShipmentStatuses;
    rack?: string | null;
    notes?: string | null;
    actualShippingDate?: string | null;
    shippingDate?: string | null;
    trackingNumber?: string | null;
    trackingURL?: string | null;
    shippedAt?: string | null;
    setForPickupAt?: string | null;
    pickedUpAt?: string | null;
    shipStationOrderId?: number | null;
    shipStationShipmentId?: number | null;
    lastShipStationResyncStartedAt?: string | null;
    shipStationError?: string | null;
    _version: number;
    _deleted?: boolean | null;
    alerts?: Array<{
      __typename?: 'Alert';
      message: string;
      oldItem?: string | null;
      newItem?: string | null;
      acknowledgedBy?: string | null;
      acknowledgedAt?: string | null;
    } | null> | null;
    packageInfo?: { __typename?: 'PackageInfo'; length: number; width: number; height: number; weight: number } | null;
    customsData?: Array<{
      __typename?: 'CustomsData';
      value: number;
      hsCode: string;
      quantity: number;
      description: string;
      country: string;
    } | null> | null;
    label?: {
      __typename?: 'ShipmentLabel';
      cost: number;
      createdAt?: string | null;
      carrier: string;
      labelFile?: {
        __typename?: 'S3Object';
        bucket: string;
        key: string;
        region: string;
        version?: string | null;
      } | null;
      addressData?: {
        __typename?: 'Address';
        id: string;
        address1?: string | null;
        address2?: string | null;
        city?: string | null;
        state?: string | null;
        country?: string | null;
        zipCode?: string | null;
        parentType: TAddressParentTypes;
        useGoogleAddress?: boolean | null;
        _version: number;
      } | null;
    } | null;
    history?: Array<{
      __typename?: 'ShipmentHistory';
      trackingNumber?: string | null;
      trackingURL?: string | null;
      label?: {
        __typename?: 'ShipmentLabel';
        cost: number;
        createdAt?: string | null;
        carrier: string;
        labelFile?: {
          __typename?: 'S3Object';
          bucket: string;
          key: string;
          region: string;
          version?: string | null;
        } | null;
        addressData?: {
          __typename?: 'Address';
          id: string;
          address1?: string | null;
          address2?: string | null;
          city?: string | null;
          state?: string | null;
          country?: string | null;
          zipCode?: string | null;
          parentType: TAddressParentTypes;
          useGoogleAddress?: boolean | null;
          _version: number;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type TPrintShipmentLabelMutationVariables = Exact<{
  id: Scalars['ID'];
  companyId: Scalars['ID'];
  version: Scalars['Int'];
  input: TPrintShipmentLabelMutationInput;
}>;

export type TPrintShipmentLabelMutation = {
  __typename?: 'Mutation';
  printShipmentLabel?: {
    __typename?: 'Shipment';
    id: string;
    companyId: string;
    addressId: string;
    addressVerified?: TShipStationAddressVerified | null;
    useGoogleAddress?: boolean | null;
    status: TShipmentStatuses;
    rack?: string | null;
    notes?: string | null;
    actualShippingDate?: string | null;
    shippingDate?: string | null;
    trackingNumber?: string | null;
    trackingURL?: string | null;
    shippedAt?: string | null;
    setForPickupAt?: string | null;
    pickedUpAt?: string | null;
    shipStationOrderId?: number | null;
    shipStationShipmentId?: number | null;
    lastShipStationResyncStartedAt?: string | null;
    shipStationError?: string | null;
    _version: number;
    _deleted?: boolean | null;
    alerts?: Array<{
      __typename?: 'Alert';
      message: string;
      oldItem?: string | null;
      newItem?: string | null;
      acknowledgedBy?: string | null;
      acknowledgedAt?: string | null;
    } | null> | null;
    packageInfo?: { __typename?: 'PackageInfo'; length: number; width: number; height: number; weight: number } | null;
    customsData?: Array<{
      __typename?: 'CustomsData';
      value: number;
      hsCode: string;
      quantity: number;
      description: string;
      country: string;
    } | null> | null;
    label?: {
      __typename?: 'ShipmentLabel';
      cost: number;
      createdAt?: string | null;
      carrier: string;
      labelFile?: {
        __typename?: 'S3Object';
        bucket: string;
        key: string;
        region: string;
        version?: string | null;
      } | null;
      addressData?: {
        __typename?: 'Address';
        id: string;
        address1?: string | null;
        address2?: string | null;
        city?: string | null;
        state?: string | null;
        country?: string | null;
        zipCode?: string | null;
        parentType: TAddressParentTypes;
        useGoogleAddress?: boolean | null;
        _version: number;
      } | null;
    } | null;
    history?: Array<{
      __typename?: 'ShipmentHistory';
      trackingNumber?: string | null;
      trackingURL?: string | null;
      label?: {
        __typename?: 'ShipmentLabel';
        cost: number;
        createdAt?: string | null;
        carrier: string;
        labelFile?: {
          __typename?: 'S3Object';
          bucket: string;
          key: string;
          region: string;
          version?: string | null;
        } | null;
        addressData?: {
          __typename?: 'Address';
          id: string;
          address1?: string | null;
          address2?: string | null;
          city?: string | null;
          state?: string | null;
          country?: string | null;
          zipCode?: string | null;
          parentType: TAddressParentTypes;
          useGoogleAddress?: boolean | null;
          _version: number;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type TSetShipmentAsShippedMutationVariables = Exact<{
  id: Scalars['ID'];
  companyId: Scalars['ID'];
  version: Scalars['Int'];
}>;

export type TSetShipmentAsShippedMutation = {
  __typename?: 'Mutation';
  setShipmentAsShipped?: {
    __typename?: 'Shipment';
    id: string;
    companyId: string;
    addressId: string;
    addressVerified?: TShipStationAddressVerified | null;
    useGoogleAddress?: boolean | null;
    status: TShipmentStatuses;
    rack?: string | null;
    notes?: string | null;
    actualShippingDate?: string | null;
    shippingDate?: string | null;
    trackingNumber?: string | null;
    trackingURL?: string | null;
    shippedAt?: string | null;
    setForPickupAt?: string | null;
    pickedUpAt?: string | null;
    shipStationOrderId?: number | null;
    shipStationShipmentId?: number | null;
    lastShipStationResyncStartedAt?: string | null;
    shipStationError?: string | null;
    _version: number;
    _deleted?: boolean | null;
    gifts?: {
      __typename?: 'GiftsConnection';
      items: Array<{
        __typename?: 'Gift';
        id: string;
        companyId: string;
        employeeId: string;
        recipientId: string;
        addressId?: string | null;
        eventId: string;
        shipmentId?: string | null;
        status: TGiftStatuses;
        type: TGiftTypes;
        customType?: string | null;
        eventDate: string;
        rack?: string | null;
        notes?: string | null;
        group?: string | null;
        doneAt?: string | null;
        isDone?: number | null;
        recreatedFromId?: string | null;
        recipientData?: string | null;
        _version: number;
        _deleted?: boolean | null;
        alerts?: Array<{
          __typename?: 'Alert';
          message: string;
          oldItem?: string | null;
          newItem?: string | null;
          acknowledgedBy?: string | null;
          acknowledgedAt?: string | null;
        } | null> | null;
      } | null>;
    } | null;
    alerts?: Array<{
      __typename?: 'Alert';
      message: string;
      oldItem?: string | null;
      newItem?: string | null;
      acknowledgedBy?: string | null;
      acknowledgedAt?: string | null;
    } | null> | null;
    packageInfo?: { __typename?: 'PackageInfo'; length: number; width: number; height: number; weight: number } | null;
    customsData?: Array<{
      __typename?: 'CustomsData';
      value: number;
      hsCode: string;
      quantity: number;
      description: string;
      country: string;
    } | null> | null;
    label?: {
      __typename?: 'ShipmentLabel';
      cost: number;
      createdAt?: string | null;
      carrier: string;
      labelFile?: {
        __typename?: 'S3Object';
        bucket: string;
        key: string;
        region: string;
        version?: string | null;
      } | null;
      addressData?: {
        __typename?: 'Address';
        id: string;
        address1?: string | null;
        address2?: string | null;
        city?: string | null;
        state?: string | null;
        country?: string | null;
        zipCode?: string | null;
        parentType: TAddressParentTypes;
        useGoogleAddress?: boolean | null;
        _version: number;
      } | null;
    } | null;
    history?: Array<{
      __typename?: 'ShipmentHistory';
      trackingNumber?: string | null;
      trackingURL?: string | null;
      label?: {
        __typename?: 'ShipmentLabel';
        cost: number;
        createdAt?: string | null;
        carrier: string;
        labelFile?: {
          __typename?: 'S3Object';
          bucket: string;
          key: string;
          region: string;
          version?: string | null;
        } | null;
        addressData?: {
          __typename?: 'Address';
          id: string;
          address1?: string | null;
          address2?: string | null;
          city?: string | null;
          state?: string | null;
          country?: string | null;
          zipCode?: string | null;
          parentType: TAddressParentTypes;
          useGoogleAddress?: boolean | null;
          _version: number;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type TDeleteShipmentMutationVariables = Exact<{
  id: Scalars['ID'];
  companyId: Scalars['ID'];
  version: Scalars['Int'];
}>;

export type TDeleteShipmentMutation = {
  __typename?: 'Mutation';
  deleteShipment?: {
    __typename?: 'Shipment';
    id: string;
    companyId: string;
    addressId: string;
    addressVerified?: TShipStationAddressVerified | null;
    useGoogleAddress?: boolean | null;
    status: TShipmentStatuses;
    rack?: string | null;
    notes?: string | null;
    actualShippingDate?: string | null;
    shippingDate?: string | null;
    trackingNumber?: string | null;
    trackingURL?: string | null;
    shippedAt?: string | null;
    setForPickupAt?: string | null;
    pickedUpAt?: string | null;
    shipStationOrderId?: number | null;
    shipStationShipmentId?: number | null;
    lastShipStationResyncStartedAt?: string | null;
    shipStationError?: string | null;
    _version: number;
    _deleted?: boolean | null;
    alerts?: Array<{
      __typename?: 'Alert';
      message: string;
      oldItem?: string | null;
      newItem?: string | null;
      acknowledgedBy?: string | null;
      acknowledgedAt?: string | null;
    } | null> | null;
    packageInfo?: { __typename?: 'PackageInfo'; length: number; width: number; height: number; weight: number } | null;
    customsData?: Array<{
      __typename?: 'CustomsData';
      value: number;
      hsCode: string;
      quantity: number;
      description: string;
      country: string;
    } | null> | null;
    label?: {
      __typename?: 'ShipmentLabel';
      cost: number;
      createdAt?: string | null;
      carrier: string;
      labelFile?: {
        __typename?: 'S3Object';
        bucket: string;
        key: string;
        region: string;
        version?: string | null;
      } | null;
      addressData?: {
        __typename?: 'Address';
        id: string;
        address1?: string | null;
        address2?: string | null;
        city?: string | null;
        state?: string | null;
        country?: string | null;
        zipCode?: string | null;
        parentType: TAddressParentTypes;
        useGoogleAddress?: boolean | null;
        _version: number;
      } | null;
    } | null;
    history?: Array<{
      __typename?: 'ShipmentHistory';
      trackingNumber?: string | null;
      trackingURL?: string | null;
      label?: {
        __typename?: 'ShipmentLabel';
        cost: number;
        createdAt?: string | null;
        carrier: string;
        labelFile?: {
          __typename?: 'S3Object';
          bucket: string;
          key: string;
          region: string;
          version?: string | null;
        } | null;
        addressData?: {
          __typename?: 'Address';
          id: string;
          address1?: string | null;
          address2?: string | null;
          city?: string | null;
          state?: string | null;
          country?: string | null;
          zipCode?: string | null;
          parentType: TAddressParentTypes;
          useGoogleAddress?: boolean | null;
          _version: number;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type TRecreateShipmentMutationVariables = Exact<{
  id: Scalars['ID'];
  companyId: Scalars['ID'];
}>;

export type TRecreateShipmentMutation = {
  __typename?: 'Mutation';
  recreateShipment?: {
    __typename?: 'ShipmentRecreatedResponse';
    companyId: string;
    items: Array<{
      __typename?: 'Gift';
      id: string;
      companyId: string;
      employeeId: string;
      recipientId: string;
      addressId?: string | null;
      eventId: string;
      shipmentId?: string | null;
      status: TGiftStatuses;
      type: TGiftTypes;
      customType?: string | null;
      eventDate: string;
      rack?: string | null;
      notes?: string | null;
      group?: string | null;
      doneAt?: string | null;
      isDone?: number | null;
      recreatedFromId?: string | null;
      recipientData?: string | null;
      _version: number;
      _deleted?: boolean | null;
      alerts?: Array<{
        __typename?: 'Alert';
        message: string;
        oldItem?: string | null;
        newItem?: string | null;
        acknowledgedBy?: string | null;
        acknowledgedAt?: string | null;
      } | null> | null;
    } | null>;
  } | null;
};

export type TReshipShipmentMutationVariables = Exact<{
  id: Scalars['ID'];
  companyId: Scalars['ID'];
  version: Scalars['Int'];
}>;

export type TReshipShipmentMutation = {
  __typename?: 'Mutation';
  reshipShipment?: {
    __typename?: 'Shipment';
    id: string;
    companyId: string;
    addressId: string;
    addressVerified?: TShipStationAddressVerified | null;
    useGoogleAddress?: boolean | null;
    status: TShipmentStatuses;
    rack?: string | null;
    notes?: string | null;
    actualShippingDate?: string | null;
    shippingDate?: string | null;
    trackingNumber?: string | null;
    trackingURL?: string | null;
    shippedAt?: string | null;
    setForPickupAt?: string | null;
    pickedUpAt?: string | null;
    shipStationOrderId?: number | null;
    shipStationShipmentId?: number | null;
    lastShipStationResyncStartedAt?: string | null;
    shipStationError?: string | null;
    _version: number;
    _deleted?: boolean | null;
    alerts?: Array<{
      __typename?: 'Alert';
      message: string;
      oldItem?: string | null;
      newItem?: string | null;
      acknowledgedBy?: string | null;
      acknowledgedAt?: string | null;
    } | null> | null;
    packageInfo?: { __typename?: 'PackageInfo'; length: number; width: number; height: number; weight: number } | null;
    customsData?: Array<{
      __typename?: 'CustomsData';
      value: number;
      hsCode: string;
      quantity: number;
      description: string;
      country: string;
    } | null> | null;
    label?: {
      __typename?: 'ShipmentLabel';
      cost: number;
      createdAt?: string | null;
      carrier: string;
      labelFile?: {
        __typename?: 'S3Object';
        bucket: string;
        key: string;
        region: string;
        version?: string | null;
      } | null;
      addressData?: {
        __typename?: 'Address';
        id: string;
        address1?: string | null;
        address2?: string | null;
        city?: string | null;
        state?: string | null;
        country?: string | null;
        zipCode?: string | null;
        parentType: TAddressParentTypes;
        useGoogleAddress?: boolean | null;
        _version: number;
      } | null;
    } | null;
    history?: Array<{
      __typename?: 'ShipmentHistory';
      trackingNumber?: string | null;
      trackingURL?: string | null;
      label?: {
        __typename?: 'ShipmentLabel';
        cost: number;
        createdAt?: string | null;
        carrier: string;
        labelFile?: {
          __typename?: 'S3Object';
          bucket: string;
          key: string;
          region: string;
          version?: string | null;
        } | null;
        addressData?: {
          __typename?: 'Address';
          id: string;
          address1?: string | null;
          address2?: string | null;
          city?: string | null;
          state?: string | null;
          country?: string | null;
          zipCode?: string | null;
          parentType: TAddressParentTypes;
          useGoogleAddress?: boolean | null;
          _version: number;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type TResyncShipmentWithShipStationMutationVariables = Exact<{
  id: Scalars['ID'];
  companyId: Scalars['ID'];
  version: Scalars['Int'];
}>;

export type TResyncShipmentWithShipStationMutation = {
  __typename?: 'Mutation';
  resyncShipmentWithShipStation?: {
    __typename?: 'Shipment';
    id: string;
    companyId: string;
    addressId: string;
    addressVerified?: TShipStationAddressVerified | null;
    useGoogleAddress?: boolean | null;
    status: TShipmentStatuses;
    rack?: string | null;
    notes?: string | null;
    actualShippingDate?: string | null;
    shippingDate?: string | null;
    trackingNumber?: string | null;
    trackingURL?: string | null;
    shippedAt?: string | null;
    setForPickupAt?: string | null;
    pickedUpAt?: string | null;
    shipStationOrderId?: number | null;
    shipStationShipmentId?: number | null;
    lastShipStationResyncStartedAt?: string | null;
    shipStationError?: string | null;
    _version: number;
    _deleted?: boolean | null;
    alerts?: Array<{
      __typename?: 'Alert';
      message: string;
      oldItem?: string | null;
      newItem?: string | null;
      acknowledgedBy?: string | null;
      acknowledgedAt?: string | null;
    } | null> | null;
    packageInfo?: { __typename?: 'PackageInfo'; length: number; width: number; height: number; weight: number } | null;
    customsData?: Array<{
      __typename?: 'CustomsData';
      value: number;
      hsCode: string;
      quantity: number;
      description: string;
      country: string;
    } | null> | null;
    label?: {
      __typename?: 'ShipmentLabel';
      cost: number;
      createdAt?: string | null;
      carrier: string;
      labelFile?: {
        __typename?: 'S3Object';
        bucket: string;
        key: string;
        region: string;
        version?: string | null;
      } | null;
      addressData?: {
        __typename?: 'Address';
        id: string;
        address1?: string | null;
        address2?: string | null;
        city?: string | null;
        state?: string | null;
        country?: string | null;
        zipCode?: string | null;
        parentType: TAddressParentTypes;
        useGoogleAddress?: boolean | null;
        _version: number;
      } | null;
    } | null;
    history?: Array<{
      __typename?: 'ShipmentHistory';
      trackingNumber?: string | null;
      trackingURL?: string | null;
      label?: {
        __typename?: 'ShipmentLabel';
        cost: number;
        createdAt?: string | null;
        carrier: string;
        labelFile?: {
          __typename?: 'S3Object';
          bucket: string;
          key: string;
          region: string;
          version?: string | null;
        } | null;
        addressData?: {
          __typename?: 'Address';
          id: string;
          address1?: string | null;
          address2?: string | null;
          city?: string | null;
          state?: string | null;
          country?: string | null;
          zipCode?: string | null;
          parentType: TAddressParentTypes;
          useGoogleAddress?: boolean | null;
          _version: number;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type TAnyShipmentChangedFieldsFragment = {
  __typename?: 'Shipment';
  id: string;
  companyId: string;
  addressId: string;
  addressVerified?: TShipStationAddressVerified | null;
  useGoogleAddress?: boolean | null;
  status: TShipmentStatuses;
  rack?: string | null;
  notes?: string | null;
  actualShippingDate?: string | null;
  shippingDate?: string | null;
  trackingNumber?: string | null;
  trackingURL?: string | null;
  shippedAt?: string | null;
  setForPickupAt?: string | null;
  pickedUpAt?: string | null;
  shipStationOrderId?: number | null;
  shipStationShipmentId?: number | null;
  lastShipStationResyncStartedAt?: string | null;
  shipStationError?: string | null;
  _version: number;
  _deleted?: boolean | null;
  alerts?: Array<{
    __typename?: 'Alert';
    message: string;
    oldItem?: string | null;
    newItem?: string | null;
    acknowledgedBy?: string | null;
    acknowledgedAt?: string | null;
  } | null> | null;
  packageInfo?: { __typename?: 'PackageInfo'; length: number; width: number; height: number; weight: number } | null;
  customsData?: Array<{
    __typename?: 'CustomsData';
    value: number;
    hsCode: string;
    quantity: number;
    description: string;
    country: string;
  } | null> | null;
  label?: {
    __typename?: 'ShipmentLabel';
    cost: number;
    createdAt?: string | null;
    carrier: string;
    labelFile?: {
      __typename?: 'S3Object';
      bucket: string;
      key: string;
      region: string;
      version?: string | null;
    } | null;
    addressData?: {
      __typename?: 'Address';
      id: string;
      address1?: string | null;
      address2?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      zipCode?: string | null;
      parentType: TAddressParentTypes;
      useGoogleAddress?: boolean | null;
      _version: number;
    } | null;
  } | null;
  history?: Array<{
    __typename?: 'ShipmentHistory';
    trackingNumber?: string | null;
    trackingURL?: string | null;
    label?: {
      __typename?: 'ShipmentLabel';
      cost: number;
      createdAt?: string | null;
      carrier: string;
      labelFile?: {
        __typename?: 'S3Object';
        bucket: string;
        key: string;
        region: string;
        version?: string | null;
      } | null;
      addressData?: {
        __typename?: 'Address';
        id: string;
        address1?: string | null;
        address2?: string | null;
        city?: string | null;
        state?: string | null;
        country?: string | null;
        zipCode?: string | null;
        parentType: TAddressParentTypes;
        useGoogleAddress?: boolean | null;
        _version: number;
      } | null;
    } | null;
  } | null> | null;
};

export type TOnAnyShipmentChangedSubscriptionVariables = Exact<{ [key: string]: never }>;

export type TOnAnyShipmentChangedSubscription = {
  __typename?: 'Subscription';
  onAnyShipmentChanged?: {
    __typename?: 'Shipment';
    id: string;
    companyId: string;
    addressId: string;
    addressVerified?: TShipStationAddressVerified | null;
    useGoogleAddress?: boolean | null;
    status: TShipmentStatuses;
    rack?: string | null;
    notes?: string | null;
    actualShippingDate?: string | null;
    shippingDate?: string | null;
    trackingNumber?: string | null;
    trackingURL?: string | null;
    shippedAt?: string | null;
    setForPickupAt?: string | null;
    pickedUpAt?: string | null;
    shipStationOrderId?: number | null;
    shipStationShipmentId?: number | null;
    lastShipStationResyncStartedAt?: string | null;
    shipStationError?: string | null;
    _version: number;
    _deleted?: boolean | null;
    alerts?: Array<{
      __typename?: 'Alert';
      message: string;
      oldItem?: string | null;
      newItem?: string | null;
      acknowledgedBy?: string | null;
      acknowledgedAt?: string | null;
    } | null> | null;
    packageInfo?: { __typename?: 'PackageInfo'; length: number; width: number; height: number; weight: number } | null;
    customsData?: Array<{
      __typename?: 'CustomsData';
      value: number;
      hsCode: string;
      quantity: number;
      description: string;
      country: string;
    } | null> | null;
    label?: {
      __typename?: 'ShipmentLabel';
      cost: number;
      createdAt?: string | null;
      carrier: string;
      labelFile?: {
        __typename?: 'S3Object';
        bucket: string;
        key: string;
        region: string;
        version?: string | null;
      } | null;
      addressData?: {
        __typename?: 'Address';
        id: string;
        address1?: string | null;
        address2?: string | null;
        city?: string | null;
        state?: string | null;
        country?: string | null;
        zipCode?: string | null;
        parentType: TAddressParentTypes;
        useGoogleAddress?: boolean | null;
        _version: number;
      } | null;
    } | null;
    history?: Array<{
      __typename?: 'ShipmentHistory';
      trackingNumber?: string | null;
      trackingURL?: string | null;
      label?: {
        __typename?: 'ShipmentLabel';
        cost: number;
        createdAt?: string | null;
        carrier: string;
        labelFile?: {
          __typename?: 'S3Object';
          bucket: string;
          key: string;
          region: string;
          version?: string | null;
        } | null;
        addressData?: {
          __typename?: 'Address';
          id: string;
          address1?: string | null;
          address2?: string | null;
          city?: string | null;
          state?: string | null;
          country?: string | null;
          zipCode?: string | null;
          parentType: TAddressParentTypes;
          useGoogleAddress?: boolean | null;
          _version: number;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type TOnAnyShipmentRecreatedSubscriptionVariables = Exact<{ [key: string]: never }>;

export type TOnAnyShipmentRecreatedSubscription = {
  __typename?: 'Subscription';
  onAnyShipmentRecreated?: {
    __typename?: 'ShipmentRecreatedResponse';
    companyId: string;
    items: Array<{
      __typename?: 'Gift';
      id: string;
      companyId: string;
      employeeId: string;
      recipientId: string;
      addressId?: string | null;
      eventId: string;
      shipmentId?: string | null;
      status: TGiftStatuses;
      type: TGiftTypes;
      customType?: string | null;
      eventDate: string;
      rack?: string | null;
      notes?: string | null;
      group?: string | null;
      doneAt?: string | null;
      isDone?: number | null;
      recreatedFromId?: string | null;
      recipientData?: string | null;
      _version: number;
      _deleted?: boolean | null;
      alerts?: Array<{
        __typename?: 'Alert';
        message: string;
        oldItem?: string | null;
        newItem?: string | null;
        acknowledgedBy?: string | null;
        acknowledgedAt?: string | null;
      } | null> | null;
    } | null>;
  } | null;
};

export type TUserFieldsFragment = {
  __typename?: 'User';
  companyId: string;
  email: string;
  name: string;
  type: TUserType;
  title?: string | null;
  isCompanyOwner: boolean;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt?: number | null;
};

export type TListUsersQueryVariables = Exact<{
  companyId: Scalars['ID'];
  nextToken?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<TOrderDirection>;
}>;

export type TListUsersQuery = {
  __typename?: 'Query';
  listUsers: {
    __typename?: 'UsersConnection';
    nextToken?: string | null;
    items: Array<{
      __typename?: 'User';
      companyId: string;
      email: string;
      name: string;
      type: TUserType;
      title?: string | null;
      isCompanyOwner: boolean;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt?: number | null;
    } | null>;
  };
};

export type TListUsersIncludingCsUsersQueryVariables = Exact<{
  companyId: Scalars['ID'];
  nextToken?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<TOrderDirection>;
}>;

export type TListUsersIncludingCsUsersQuery = {
  __typename?: 'Query';
  listUsersIncludingCSUsers: {
    __typename?: 'UsersConnection';
    nextToken?: string | null;
    items: Array<{
      __typename?: 'User';
      companyId: string;
      email: string;
      name: string;
      type: TUserType;
      title?: string | null;
      isCompanyOwner: boolean;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt?: number | null;
    } | null>;
  };
};

export type TCreateUserMutationVariables = Exact<{
  input: TCreateUserMutationInput;
}>;

export type TCreateUserMutation = {
  __typename?: 'Mutation';
  createUser?: {
    __typename?: 'User';
    companyId: string;
    email: string;
    name: string;
    type: TUserType;
    title?: string | null;
    isCompanyOwner: boolean;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt?: number | null;
  } | null;
};

export type TDeleteUserMutationVariables = Exact<{
  email: Scalars['AWSEmail'];
  companyId: Scalars['ID'];
  version: Scalars['Int'];
}>;

export type TDeleteUserMutation = {
  __typename?: 'Mutation';
  deleteUser?: {
    __typename?: 'User';
    companyId: string;
    email: string;
    name: string;
    type: TUserType;
    title?: string | null;
    isCompanyOwner: boolean;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt?: number | null;
  } | null;
};

export type TListAdminCognitoUsersQueryVariables = Exact<{
  userPoolId: Scalars['String'];
}>;

export type TListAdminCognitoUsersQuery = { __typename?: 'Query'; listAdminCognitoUsers?: string | null };

export type TCompanyUserChangedFieldsFragment = {
  __typename?: 'User';
  companyId: string;
  email: string;
  name: string;
  type: TUserType;
  title?: string | null;
  isCompanyOwner: boolean;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt?: number | null;
};

export type TOnCompanyUserChangedSubscriptionVariables = Exact<{
  companyId: Scalars['ID'];
}>;

export type TOnCompanyUserChangedSubscription = {
  __typename?: 'Subscription';
  onCompanyUserChanged?: {
    __typename?: 'User';
    companyId: string;
    email: string;
    name: string;
    type: TUserType;
    title?: string | null;
    isCompanyOwner: boolean;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt?: number | null;
  } | null;
};

export const ListCompanyFieldsFragmentDoc = gql`
  fragment ListCompanyFields on Company {
    id
    active
    isLive
    deliveryMethod
    name
    fullyConfigured
    employeeBirthdayActivated
    employeeWorkAnniversaryActivated
    employeeNewHireKitActivated
    significantOtherBirthdayActivated
    childBirthdayActivated
    petBirthdayActivated
    workAnniversaryGiftVariation
    customWorkAnniversaryNotes
    hasCustomOnboardBrick
    tissueColor
    legoColor
    signAs
    specialNote
    swag
    startDate
    endDate
    subscriptionPricePerUser
    subscriptionPriceCurrency
    contact {
      name
      email
      phoneNumber
    }
  }
`;
export const CompanyFieldsFragmentDoc = gql`
  fragment CompanyFields on Company {
    ...ListCompanyFields
    rosterId
    subdomain
    emailDomains
    googleLoginEnabled
    logo
    importFromBambooHR
    bambooHRSubdomain
    bambooHRAPIKey
    bambooHRPetsTableName
    _version
    _deleted
  }
  ${ListCompanyFieldsFragmentDoc}
`;
export const AddressFieldsFragmentDoc = gql`
  fragment AddressFields on Address {
    id
    address1
    address2
    city
    state
    country
    zipCode
    parentType
    useGoogleAddress
    _version
  }
`;
export const AddressFromGoogleFieldsFragmentDoc = gql`
  fragment AddressFromGoogleFields on AddressFromGoogle {
    address1
    address2
    city
    state
    country
    zipCode
  }
`;
export const AddressFieldsWithGoogleFieldsFragmentDoc = gql`
  fragment AddressFieldsWithGoogleFields on Address {
    ...AddressFields
    addressFromGoogle {
      ...AddressFromGoogleFields
    }
  }
  ${AddressFieldsFragmentDoc}
  ${AddressFromGoogleFieldsFragmentDoc}
`;
export const CompanyWithAddressFieldsFragmentDoc = gql`
  fragment CompanyWithAddressFields on Company {
    ...CompanyFields
    address {
      ...AddressFieldsWithGoogleFields
    }
  }
  ${CompanyFieldsFragmentDoc}
  ${AddressFieldsWithGoogleFieldsFragmentDoc}
`;
export const AnyCompanyChangedFieldsFragmentDoc = gql`
  fragment AnyCompanyChangedFields on Company {
    ...CompanyFields
  }
  ${CompanyFieldsFragmentDoc}
`;
export const ContactFieldsFragmentDoc = gql`
  fragment ContactFields on Contact {
    name
    email
    phoneNumber
  }
`;
export const BasicDependantFieldsFragmentDoc = gql`
  fragment BasicDependantFields on Dependant {
    id
    companyId
    employeeId
    firstName
    fullName
    lastName
    birthDate
    type
    petType
    foodPreferences
    donateBirthdayGift
    _version
    _deleted
  }
`;
export const AnyDependantChangedFieldsFragmentDoc = gql`
  fragment AnyDependantChangedFields on Dependant {
    ...BasicDependantFields
  }
  ${BasicDependantFieldsFragmentDoc}
`;
export const BasicEmployeeFieldsFragmentDoc = gql`
  fragment BasicEmployeeFields on Employee {
    id
    active
    externalId
    companyId
    officeId
    firstName
    lastName
    preferredFirstName
    fullName
    hireDate
    birthDate
    email
    phoneNumber
    foodPreferences
    customOnboardBrick
    title
    organization
    tShirtSize
    sweaterSize
    donateBirthdayGift
    donateWorkAnniversaryGift
    createdAt
    updatedAt
    _version
    _deleted
  }
`;
export const EmployeeFieldsFragmentDoc = gql`
  fragment EmployeeFields on Employee {
    ...BasicEmployeeFields
  }
  ${BasicEmployeeFieldsFragmentDoc}
`;
export const EmployeeWithAddressFieldsFragmentDoc = gql`
  fragment EmployeeWithAddressFields on Employee {
    ...EmployeeFields
    address {
      ...AddressFieldsWithGoogleFields
    }
  }
  ${EmployeeFieldsFragmentDoc}
  ${AddressFieldsWithGoogleFieldsFragmentDoc}
`;
export const EmployeeWithDependantsFieldsFragmentDoc = gql`
  fragment EmployeeWithDependantsFields on Employee {
    ...EmployeeFields
    dependants {
      items {
        ...BasicDependantFields
      }
    }
  }
  ${EmployeeFieldsFragmentDoc}
  ${BasicDependantFieldsFragmentDoc}
`;
export const AnyEmployeeChangedFieldsFragmentDoc = gql`
  fragment AnyEmployeeChangedFields on Employee {
    ...BasicEmployeeFields
  }
  ${BasicEmployeeFieldsFragmentDoc}
`;
export const DependantWithEmployeeFieldsFragmentDoc = gql`
  fragment DependantWithEmployeeFields on Dependant {
    ...BasicDependantFields
    employee {
      ...EmployeeFields
    }
  }
  ${BasicDependantFieldsFragmentDoc}
  ${EmployeeFieldsFragmentDoc}
`;
export const BasicReferenceEventsFragmentDoc = gql`
  fragment BasicReferenceEvents on ReferenceEvent {
    id
    companyId
    company {
      name
    }
    employeeId
    employee {
      fullName
    }
    recipientId
    recipient {
      ... on Dependant {
        ...DependantWithEmployeeFields
      }
      ... on Employee {
        ...EmployeeFields
      }
    }
    addressId
    address {
      id
      address1
    }
    fullDate
    date
    type
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
  }
  ${DependantWithEmployeeFieldsFragmentDoc}
  ${EmployeeFieldsFragmentDoc}
`;
export const BasicEventFieldsFragmentDoc = gql`
  fragment BasicEventFields on Event {
    id
    companyId
    employeeId
    recipientId
    addressId
    referenceEventId
    giftId
    eventDate
    type
    customType
    age
    skippedAt
    deliveredAt
    groupedToEventId
    isPaused
    wasPaused
    _version
    _deleted
  }
`;
export const EventFieldsFragmentDoc = gql`
  fragment EventFields on Event {
    ...BasicEventFields
  }
  ${BasicEventFieldsFragmentDoc}
`;
export const AnyEventChangedFieldsFragmentDoc = gql`
  fragment AnyEventChangedFields on Event {
    ...BasicEventFields
  }
  ${BasicEventFieldsFragmentDoc}
`;
export const BasicGiftFieldsFragmentDoc = gql`
  fragment BasicGiftFields on Gift {
    id
    companyId
    employeeId
    recipientId
    addressId
    eventId
    shipmentId
    status
    type
    customType
    eventDate
    rack
    notes
    group
    doneAt
    isDone
    recreatedFromId
    alerts {
      message
      oldItem
      newItem
      acknowledgedBy
      acknowledgedAt
    }
    recipientData
    _version
    _deleted
  }
`;
export const GiftFieldsFragmentDoc = gql`
  fragment GiftFields on Gift {
    ...BasicGiftFields
    event {
      age
    }
  }
  ${BasicGiftFieldsFragmentDoc}
`;
export const ShipmentBasicFieldsFragmentDoc = gql`
  fragment ShipmentBasicFields on Shipment {
    id
    companyId
    addressId
    addressVerified
    useGoogleAddress
    alerts {
      message
      oldItem
      newItem
      acknowledgedBy
      acknowledgedAt
    }
    status
    rack
    notes
    actualShippingDate
    shippingDate
    trackingNumber
    trackingURL
    shippedAt
    setForPickupAt
    pickedUpAt
    packageInfo {
      length
      width
      height
      weight
    }
    customsData {
      value
      hsCode
      quantity
      description
      country
    }
    label {
      labelFile {
        bucket
        key
        region
        version
      }
      cost
      createdAt
      carrier
      addressData {
        ...AddressFields
      }
    }
    history {
      label {
        labelFile {
          bucket
          key
          region
          version
        }
        cost
        createdAt
        carrier
        addressData {
          ...AddressFields
        }
      }
      trackingNumber
      trackingURL
    }
    shipStationOrderId
    shipStationShipmentId
    lastShipStationResyncStartedAt
    shipStationError
    _version
    _deleted
  }
  ${AddressFieldsFragmentDoc}
`;
export const ShipmentFieldsFragmentDoc = gql`
  fragment ShipmentFields on Shipment {
    ...ShipmentBasicFields
    gifts {
      items {
        ...BasicGiftFields
      }
    }
  }
  ${ShipmentBasicFieldsFragmentDoc}
  ${BasicGiftFieldsFragmentDoc}
`;
export const GiftWithShipmentFieldsFragmentDoc = gql`
  fragment GiftWithShipmentFields on Gift {
    ...GiftFields
    shipment {
      ...ShipmentFields
    }
  }
  ${GiftFieldsFragmentDoc}
  ${ShipmentFieldsFragmentDoc}
`;
export const AnyEventGiftCreatedEventFieldsFragmentDoc = gql`
  fragment AnyEventGiftCreatedEventFields on Event {
    ...BasicEventFields
  }
  ${BasicEventFieldsFragmentDoc}
`;
export const AnyEventGiftCreatedGiftFieldsFragmentDoc = gql`
  fragment AnyEventGiftCreatedGiftFields on Gift {
    ...BasicGiftFields
  }
  ${BasicGiftFieldsFragmentDoc}
`;
export const AnyGiftRemovedFromGroupFieldsFragmentDoc = gql`
  fragment AnyGiftRemovedFromGroupFields on Gift {
    ...BasicGiftFields
  }
  ${BasicGiftFieldsFragmentDoc}
`;
export const AnyGiftChangedFieldsFragmentDoc = gql`
  fragment AnyGiftChangedFields on Gift {
    ...BasicGiftFields
  }
  ${BasicGiftFieldsFragmentDoc}
`;
export const BasicLogFieldsFragmentDoc = gql`
  fragment BasicLogFields on Log {
    id
    occurredAt
    arguments
    companyId
    fieldName
    identity
    parentTypeName
    resourceId
    response
    selectionSetGraphQL
    type
    userId
    userType
    variables
  }
`;
export const LogFieldsFragmentDoc = gql`
  fragment LogFields on Log {
    ...BasicLogFields
  }
  ${BasicLogFieldsFragmentDoc}
`;
export const OfficeFieldsFragmentDoc = gql`
  fragment OfficeFields on Office {
    id
    companyId
    name
    contact {
      name
    }
    _version
    _deleted
  }
`;
export const OfficeWithAddressFieldsFragmentDoc = gql`
  fragment OfficeWithAddressFields on Office {
    ...OfficeFields
    address {
      ...AddressFieldsWithGoogleFields
    }
  }
  ${OfficeFieldsFragmentDoc}
  ${AddressFieldsWithGoogleFieldsFragmentDoc}
`;
export const AnyOfficeChangedFieldsFragmentDoc = gql`
  fragment AnyOfficeChangedFields on Office {
    ...OfficeFields
  }
  ${OfficeFieldsFragmentDoc}
`;
export const RecipientFieldsFragmentDoc = gql`
  fragment RecipientFields on Recipient {
    ... on Dependant {
      ...DependantWithEmployeeFields
    }
    ... on Employee {
      ...EmployeeFields
    }
  }
  ${DependantWithEmployeeFieldsFragmentDoc}
  ${EmployeeFieldsFragmentDoc}
`;
export const RosterFileFieldsFragmentDoc = gql`
  fragment RosterFileFields on RosterFile {
    id
    companyId
    createdBy {
      username
      sub
    }
    file {
      bucket
      region
      key
      version
    }
    headerMapping
    mapping
    officeMapping
    changelog
    changelogFile {
      bucket
      region
      key
      version
    }
    importStartedAt
    impoortEndedAt
    importErrors
    createdAt
    updatedAt
    _version
    _deleted
  }
`;
export const AnyRosterFileChangedFieldsFragmentDoc = gql`
  fragment AnyRosterFileChangedFields on RosterFile {
    ...RosterFileFields
  }
  ${RosterFileFieldsFragmentDoc}
`;
export const AnyShipmentChangedFieldsFragmentDoc = gql`
  fragment AnyShipmentChangedFields on Shipment {
    ...ShipmentBasicFields
  }
  ${ShipmentBasicFieldsFragmentDoc}
`;
export const UserFieldsFragmentDoc = gql`
  fragment UserFields on User {
    companyId
    email
    name
    type
    title
    isCompanyOwner
    _version
    _deleted
    _lastChangedAt
  }
`;
export const CompanyUserChangedFieldsFragmentDoc = gql`
  fragment CompanyUserChangedFields on User {
    ...UserFields
  }
  ${UserFieldsFragmentDoc}
`;
export const ListAllAddressesDocument = gql`
  query ListAllAddresses($nextToken: String, $limit: Int, $order: OrderDirection) {
    listAddresses(nextToken: $nextToken, limit: $limit, order: $order) {
      nextToken
      items {
        ...AddressFieldsWithGoogleFields
      }
    }
  }
  ${AddressFieldsWithGoogleFieldsFragmentDoc}
`;

/**
 * __useListAllAddressesQuery__
 *
 * To run a query within a React component, call `useListAllAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAllAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAllAddressesQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useListAllAddressesQuery(
  baseOptions?: Apollo.QueryHookOptions<TListAllAddressesQuery, TListAllAddressesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TListAllAddressesQuery, TListAllAddressesQueryVariables>(ListAllAddressesDocument, options);
}
export function useListAllAddressesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TListAllAddressesQuery, TListAllAddressesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TListAllAddressesQuery, TListAllAddressesQueryVariables>(
    ListAllAddressesDocument,
    options,
  );
}
export type ListAllAddressesQueryHookResult = ReturnType<typeof useListAllAddressesQuery>;
export type ListAllAddressesLazyQueryHookResult = ReturnType<typeof useListAllAddressesLazyQuery>;
export type ListAllAddressesQueryResult = Apollo.QueryResult<TListAllAddressesQuery, TListAllAddressesQueryVariables>;
export const GetAddressByIdDocument = gql`
  query GetAddressById($id: ID!) {
    getAddressById(id: $id) {
      ...AddressFields
    }
  }
  ${AddressFieldsFragmentDoc}
`;

/**
 * __useGetAddressByIdQuery__
 *
 * To run a query within a React component, call `useGetAddressByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAddressByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAddressByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAddressByIdQuery(
  baseOptions: Apollo.QueryHookOptions<TGetAddressByIdQuery, TGetAddressByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TGetAddressByIdQuery, TGetAddressByIdQueryVariables>(GetAddressByIdDocument, options);
}
export function useGetAddressByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TGetAddressByIdQuery, TGetAddressByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TGetAddressByIdQuery, TGetAddressByIdQueryVariables>(GetAddressByIdDocument, options);
}
export type GetAddressByIdQueryHookResult = ReturnType<typeof useGetAddressByIdQuery>;
export type GetAddressByIdLazyQueryHookResult = ReturnType<typeof useGetAddressByIdLazyQuery>;
export type GetAddressByIdQueryResult = Apollo.QueryResult<TGetAddressByIdQuery, TGetAddressByIdQueryVariables>;
export const ListCompaniesDocument = gql`
  query ListCompanies($nextToken: String, $limit: Int, $order: OrderDirection) {
    listCompanies(nextToken: $nextToken, limit: $limit, order: $order) {
      items {
        ...ListCompanyFields
      }
      nextToken
    }
  }
  ${ListCompanyFieldsFragmentDoc}
`;

/**
 * __useListCompaniesQuery__
 *
 * To run a query within a React component, call `useListCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCompaniesQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useListCompaniesQuery(
  baseOptions?: Apollo.QueryHookOptions<TListCompaniesQuery, TListCompaniesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TListCompaniesQuery, TListCompaniesQueryVariables>(ListCompaniesDocument, options);
}
export function useListCompaniesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TListCompaniesQuery, TListCompaniesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TListCompaniesQuery, TListCompaniesQueryVariables>(ListCompaniesDocument, options);
}
export type ListCompaniesQueryHookResult = ReturnType<typeof useListCompaniesQuery>;
export type ListCompaniesLazyQueryHookResult = ReturnType<typeof useListCompaniesLazyQuery>;
export type ListCompaniesQueryResult = Apollo.QueryResult<TListCompaniesQuery, TListCompaniesQueryVariables>;
export const GetCompanyDocument = gql`
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      ...CompanyWithAddressFields
    }
  }
  ${CompanyWithAddressFieldsFragmentDoc}
`;

/**
 * __useGetCompanyQuery__
 *
 * To run a query within a React component, call `useGetCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCompanyQuery(baseOptions: Apollo.QueryHookOptions<TGetCompanyQuery, TGetCompanyQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TGetCompanyQuery, TGetCompanyQueryVariables>(GetCompanyDocument, options);
}
export function useGetCompanyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TGetCompanyQuery, TGetCompanyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TGetCompanyQuery, TGetCompanyQueryVariables>(GetCompanyDocument, options);
}
export type GetCompanyQueryHookResult = ReturnType<typeof useGetCompanyQuery>;
export type GetCompanyLazyQueryHookResult = ReturnType<typeof useGetCompanyLazyQuery>;
export type GetCompanyQueryResult = Apollo.QueryResult<TGetCompanyQuery, TGetCompanyQueryVariables>;
export const CreateCompanyDocument = gql`
  mutation CreateCompany($input: CreateCompanyMutationInput!) {
    createCompany(input: $input) {
      ...CompanyWithAddressFields
    }
  }
  ${CompanyWithAddressFieldsFragmentDoc}
`;
export type TCreateCompanyMutationFn = Apollo.MutationFunction<TCreateCompanyMutation, TCreateCompanyMutationVariables>;

/**
 * __useCreateCompanyMutation__
 *
 * To run a mutation, you first call `useCreateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyMutation, { data, loading, error }] = useCreateCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<TCreateCompanyMutation, TCreateCompanyMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TCreateCompanyMutation, TCreateCompanyMutationVariables>(CreateCompanyDocument, options);
}
export type CreateCompanyMutationHookResult = ReturnType<typeof useCreateCompanyMutation>;
export type CreateCompanyMutationResult = Apollo.MutationResult<TCreateCompanyMutation>;
export type CreateCompanyMutationOptions = Apollo.BaseMutationOptions<
  TCreateCompanyMutation,
  TCreateCompanyMutationVariables
>;
export const UpdateCompanyDocument = gql`
  mutation UpdateCompany($id: ID!, $_version: Int!, $input: UpdateCompanyMutationInput!) {
    updateCompany(id: $id, _version: $_version, input: $input) {
      ...CompanyWithAddressFields
    }
  }
  ${CompanyWithAddressFieldsFragmentDoc}
`;
export type TUpdateCompanyMutationFn = Apollo.MutationFunction<TUpdateCompanyMutation, TUpdateCompanyMutationVariables>;

/**
 * __useUpdateCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyMutation, { data, loading, error }] = useUpdateCompanyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      _version: // value for '_version'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<TUpdateCompanyMutation, TUpdateCompanyMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TUpdateCompanyMutation, TUpdateCompanyMutationVariables>(UpdateCompanyDocument, options);
}
export type UpdateCompanyMutationHookResult = ReturnType<typeof useUpdateCompanyMutation>;
export type UpdateCompanyMutationResult = Apollo.MutationResult<TUpdateCompanyMutation>;
export type UpdateCompanyMutationOptions = Apollo.BaseMutationOptions<
  TUpdateCompanyMutation,
  TUpdateCompanyMutationVariables
>;
export const DeleteCompanyDocument = gql`
  mutation DeleteCompany($id: ID!, $_version: Int!) {
    deleteCompany(id: $id, _version: $_version) {
      ...CompanyFields
    }
  }
  ${CompanyFieldsFragmentDoc}
`;
export type TDeleteCompanyMutationFn = Apollo.MutationFunction<TDeleteCompanyMutation, TDeleteCompanyMutationVariables>;

/**
 * __useDeleteCompanyMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyMutation, { data, loading, error }] = useDeleteCompanyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      _version: // value for '_version'
 *   },
 * });
 */
export function useDeleteCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<TDeleteCompanyMutation, TDeleteCompanyMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TDeleteCompanyMutation, TDeleteCompanyMutationVariables>(DeleteCompanyDocument, options);
}
export type DeleteCompanyMutationHookResult = ReturnType<typeof useDeleteCompanyMutation>;
export type DeleteCompanyMutationResult = Apollo.MutationResult<TDeleteCompanyMutation>;
export type DeleteCompanyMutationOptions = Apollo.BaseMutationOptions<
  TDeleteCompanyMutation,
  TDeleteCompanyMutationVariables
>;
export const OnAnyCompanyChangedDocument = gql`
  subscription OnAnyCompanyChanged {
    onAnyCompanyChanged {
      ...AnyCompanyChangedFields
    }
  }
  ${AnyCompanyChangedFieldsFragmentDoc}
`;

/**
 * __useOnAnyCompanyChangedSubscription__
 *
 * To run a query within a React component, call `useOnAnyCompanyChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnAnyCompanyChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnAnyCompanyChangedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnAnyCompanyChangedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    TOnAnyCompanyChangedSubscription,
    TOnAnyCompanyChangedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<TOnAnyCompanyChangedSubscription, TOnAnyCompanyChangedSubscriptionVariables>(
    OnAnyCompanyChangedDocument,
    options,
  );
}
export type OnAnyCompanyChangedSubscriptionHookResult = ReturnType<typeof useOnAnyCompanyChangedSubscription>;
export type OnAnyCompanyChangedSubscriptionResult = Apollo.SubscriptionResult<TOnAnyCompanyChangedSubscription>;
export const ListDependantsDocument = gql`
  query ListDependants($nextToken: String, $limit: Int, $order: OrderDirection) {
    listDependants(nextToken: $nextToken, limit: $limit, order: $order) {
      nextToken
      items {
        ...BasicDependantFields
      }
    }
  }
  ${BasicDependantFieldsFragmentDoc}
`;

/**
 * __useListDependantsQuery__
 *
 * To run a query within a React component, call `useListDependantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListDependantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListDependantsQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useListDependantsQuery(
  baseOptions?: Apollo.QueryHookOptions<TListDependantsQuery, TListDependantsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TListDependantsQuery, TListDependantsQueryVariables>(ListDependantsDocument, options);
}
export function useListDependantsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TListDependantsQuery, TListDependantsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TListDependantsQuery, TListDependantsQueryVariables>(ListDependantsDocument, options);
}
export type ListDependantsQueryHookResult = ReturnType<typeof useListDependantsQuery>;
export type ListDependantsLazyQueryHookResult = ReturnType<typeof useListDependantsLazyQuery>;
export type ListDependantsQueryResult = Apollo.QueryResult<TListDependantsQuery, TListDependantsQueryVariables>;
export const ListEmployeeDependantsDocument = gql`
  query ListEmployeeDependants(
    $companyId: ID!
    $employeeId: ID!
    $nextToken: String
    $limit: Int
    $order: OrderDirection
  ) {
    listEmployeeDependants(
      companyId: $companyId
      employeeId: $employeeId
      nextToken: $nextToken
      limit: $limit
      order: $order
    ) {
      nextToken
      items {
        ...BasicDependantFields
      }
    }
  }
  ${BasicDependantFieldsFragmentDoc}
`;

/**
 * __useListEmployeeDependantsQuery__
 *
 * To run a query within a React component, call `useListEmployeeDependantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListEmployeeDependantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListEmployeeDependantsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      employeeId: // value for 'employeeId'
 *      nextToken: // value for 'nextToken'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useListEmployeeDependantsQuery(
  baseOptions: Apollo.QueryHookOptions<TListEmployeeDependantsQuery, TListEmployeeDependantsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TListEmployeeDependantsQuery, TListEmployeeDependantsQueryVariables>(
    ListEmployeeDependantsDocument,
    options,
  );
}
export function useListEmployeeDependantsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TListEmployeeDependantsQuery, TListEmployeeDependantsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TListEmployeeDependantsQuery, TListEmployeeDependantsQueryVariables>(
    ListEmployeeDependantsDocument,
    options,
  );
}
export type ListEmployeeDependantsQueryHookResult = ReturnType<typeof useListEmployeeDependantsQuery>;
export type ListEmployeeDependantsLazyQueryHookResult = ReturnType<typeof useListEmployeeDependantsLazyQuery>;
export type ListEmployeeDependantsQueryResult = Apollo.QueryResult<
  TListEmployeeDependantsQuery,
  TListEmployeeDependantsQueryVariables
>;
export const OnAnyDependantChangedDocument = gql`
  subscription OnAnyDependantChanged {
    onAnyDependantChanged {
      ...AnyDependantChangedFields
    }
  }
  ${AnyDependantChangedFieldsFragmentDoc}
`;

/**
 * __useOnAnyDependantChangedSubscription__
 *
 * To run a query within a React component, call `useOnAnyDependantChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnAnyDependantChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnAnyDependantChangedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnAnyDependantChangedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    TOnAnyDependantChangedSubscription,
    TOnAnyDependantChangedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<TOnAnyDependantChangedSubscription, TOnAnyDependantChangedSubscriptionVariables>(
    OnAnyDependantChangedDocument,
    options,
  );
}
export type OnAnyDependantChangedSubscriptionHookResult = ReturnType<typeof useOnAnyDependantChangedSubscription>;
export type OnAnyDependantChangedSubscriptionResult = Apollo.SubscriptionResult<TOnAnyDependantChangedSubscription>;
export const GetEmployeeDocument = gql`
  query GetEmployee($id: ID!, $companyId: ID!) {
    getEmployee(id: $id, companyId: $companyId) {
      ...EmployeeWithAddressFields
    }
  }
  ${EmployeeWithAddressFieldsFragmentDoc}
`;

/**
 * __useGetEmployeeQuery__
 *
 * To run a query within a React component, call `useGetEmployeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeQuery({
 *   variables: {
 *      id: // value for 'id'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetEmployeeQuery(
  baseOptions: Apollo.QueryHookOptions<TGetEmployeeQuery, TGetEmployeeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TGetEmployeeQuery, TGetEmployeeQueryVariables>(GetEmployeeDocument, options);
}
export function useGetEmployeeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TGetEmployeeQuery, TGetEmployeeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TGetEmployeeQuery, TGetEmployeeQueryVariables>(GetEmployeeDocument, options);
}
export type GetEmployeeQueryHookResult = ReturnType<typeof useGetEmployeeQuery>;
export type GetEmployeeLazyQueryHookResult = ReturnType<typeof useGetEmployeeLazyQuery>;
export type GetEmployeeQueryResult = Apollo.QueryResult<TGetEmployeeQuery, TGetEmployeeQueryVariables>;
export const ListEmployeesDocument = gql`
  query ListEmployees($nextToken: String, $limit: Int, $order: OrderDirection) {
    listEmployees(nextToken: $nextToken, limit: $limit, order: $order) {
      nextToken
      items {
        ...EmployeeFields
      }
    }
  }
  ${EmployeeFieldsFragmentDoc}
`;

/**
 * __useListEmployeesQuery__
 *
 * To run a query within a React component, call `useListEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListEmployeesQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useListEmployeesQuery(
  baseOptions?: Apollo.QueryHookOptions<TListEmployeesQuery, TListEmployeesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TListEmployeesQuery, TListEmployeesQueryVariables>(ListEmployeesDocument, options);
}
export function useListEmployeesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TListEmployeesQuery, TListEmployeesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TListEmployeesQuery, TListEmployeesQueryVariables>(ListEmployeesDocument, options);
}
export type ListEmployeesQueryHookResult = ReturnType<typeof useListEmployeesQuery>;
export type ListEmployeesLazyQueryHookResult = ReturnType<typeof useListEmployeesLazyQuery>;
export type ListEmployeesQueryResult = Apollo.QueryResult<TListEmployeesQuery, TListEmployeesQueryVariables>;
export const ListCompanyEmployeesWithDependantsDocument = gql`
  query ListCompanyEmployeesWithDependants($companyId: ID!, $nextToken: String, $limit: Int, $order: OrderDirection) {
    listCompanyEmployees(companyId: $companyId, nextToken: $nextToken, limit: $limit, order: $order) {
      nextToken
      items {
        ...EmployeeWithDependantsFields
      }
    }
  }
  ${EmployeeWithDependantsFieldsFragmentDoc}
`;

/**
 * __useListCompanyEmployeesWithDependantsQuery__
 *
 * To run a query within a React component, call `useListCompanyEmployeesWithDependantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCompanyEmployeesWithDependantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCompanyEmployeesWithDependantsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      nextToken: // value for 'nextToken'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useListCompanyEmployeesWithDependantsQuery(
  baseOptions: Apollo.QueryHookOptions<
    TListCompanyEmployeesWithDependantsQuery,
    TListCompanyEmployeesWithDependantsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TListCompanyEmployeesWithDependantsQuery, TListCompanyEmployeesWithDependantsQueryVariables>(
    ListCompanyEmployeesWithDependantsDocument,
    options,
  );
}
export function useListCompanyEmployeesWithDependantsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TListCompanyEmployeesWithDependantsQuery,
    TListCompanyEmployeesWithDependantsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TListCompanyEmployeesWithDependantsQuery,
    TListCompanyEmployeesWithDependantsQueryVariables
  >(ListCompanyEmployeesWithDependantsDocument, options);
}
export type ListCompanyEmployeesWithDependantsQueryHookResult = ReturnType<
  typeof useListCompanyEmployeesWithDependantsQuery
>;
export type ListCompanyEmployeesWithDependantsLazyQueryHookResult = ReturnType<
  typeof useListCompanyEmployeesWithDependantsLazyQuery
>;
export type ListCompanyEmployeesWithDependantsQueryResult = Apollo.QueryResult<
  TListCompanyEmployeesWithDependantsQuery,
  TListCompanyEmployeesWithDependantsQueryVariables
>;
export const ListCompanyEmployeesDocument = gql`
  query ListCompanyEmployees($companyId: ID!, $nextToken: String, $limit: Int, $order: OrderDirection) {
    listCompanyEmployees(companyId: $companyId, nextToken: $nextToken, limit: $limit, order: $order)
      @connection(key: $companyId) {
      nextToken
      items {
        ...BasicEmployeeFields
      }
    }
  }
  ${BasicEmployeeFieldsFragmentDoc}
`;

/**
 * __useListCompanyEmployeesQuery__
 *
 * To run a query within a React component, call `useListCompanyEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCompanyEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCompanyEmployeesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      nextToken: // value for 'nextToken'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useListCompanyEmployeesQuery(
  baseOptions: Apollo.QueryHookOptions<TListCompanyEmployeesQuery, TListCompanyEmployeesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TListCompanyEmployeesQuery, TListCompanyEmployeesQueryVariables>(
    ListCompanyEmployeesDocument,
    options,
  );
}
export function useListCompanyEmployeesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TListCompanyEmployeesQuery, TListCompanyEmployeesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TListCompanyEmployeesQuery, TListCompanyEmployeesQueryVariables>(
    ListCompanyEmployeesDocument,
    options,
  );
}
export type ListCompanyEmployeesQueryHookResult = ReturnType<typeof useListCompanyEmployeesQuery>;
export type ListCompanyEmployeesLazyQueryHookResult = ReturnType<typeof useListCompanyEmployeesLazyQuery>;
export type ListCompanyEmployeesQueryResult = Apollo.QueryResult<
  TListCompanyEmployeesQuery,
  TListCompanyEmployeesQueryVariables
>;
export const OnAnyEmployeeChangedDocument = gql`
  subscription OnAnyEmployeeChanged {
    onAnyEmployeeChanged {
      ...AnyEmployeeChangedFields
    }
  }
  ${AnyEmployeeChangedFieldsFragmentDoc}
`;

/**
 * __useOnAnyEmployeeChangedSubscription__
 *
 * To run a query within a React component, call `useOnAnyEmployeeChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnAnyEmployeeChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnAnyEmployeeChangedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnAnyEmployeeChangedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    TOnAnyEmployeeChangedSubscription,
    TOnAnyEmployeeChangedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<TOnAnyEmployeeChangedSubscription, TOnAnyEmployeeChangedSubscriptionVariables>(
    OnAnyEmployeeChangedDocument,
    options,
  );
}
export type OnAnyEmployeeChangedSubscriptionHookResult = ReturnType<typeof useOnAnyEmployeeChangedSubscription>;
export type OnAnyEmployeeChangedSubscriptionResult = Apollo.SubscriptionResult<TOnAnyEmployeeChangedSubscription>;
export const GetEventDocument = gql`
  query GetEvent($id: ID!, $companyId: ID!) {
    getEvent(id: $id, companyId: $companyId) {
      ...EventFields
    }
  }
  ${EventFieldsFragmentDoc}
`;

/**
 * __useGetEventQuery__
 *
 * To run a query within a React component, call `useGetEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventQuery({
 *   variables: {
 *      id: // value for 'id'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetEventQuery(baseOptions: Apollo.QueryHookOptions<TGetEventQuery, TGetEventQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TGetEventQuery, TGetEventQueryVariables>(GetEventDocument, options);
}
export function useGetEventLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TGetEventQuery, TGetEventQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TGetEventQuery, TGetEventQueryVariables>(GetEventDocument, options);
}
export type GetEventQueryHookResult = ReturnType<typeof useGetEventQuery>;
export type GetEventLazyQueryHookResult = ReturnType<typeof useGetEventLazyQuery>;
export type GetEventQueryResult = Apollo.QueryResult<TGetEventQuery, TGetEventQueryVariables>;
export const ListNotSkippedEventsWithoutGiftDocument = gql`
  query ListNotSkippedEventsWithoutGift($nextToken: String, $limit: Int, $order: OrderDirection) {
    listNotSkippedEventsWithoutGift(nextToken: $nextToken, limit: $limit, order: $order)
      @connection(key: "listOpenEvents", filter: []) {
      nextToken
      items {
        ...EventFields
      }
    }
  }
  ${EventFieldsFragmentDoc}
`;

/**
 * __useListNotSkippedEventsWithoutGiftQuery__
 *
 * To run a query within a React component, call `useListNotSkippedEventsWithoutGiftQuery` and pass it any options that fit your needs.
 * When your component renders, `useListNotSkippedEventsWithoutGiftQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListNotSkippedEventsWithoutGiftQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useListNotSkippedEventsWithoutGiftQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TListNotSkippedEventsWithoutGiftQuery,
    TListNotSkippedEventsWithoutGiftQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TListNotSkippedEventsWithoutGiftQuery, TListNotSkippedEventsWithoutGiftQueryVariables>(
    ListNotSkippedEventsWithoutGiftDocument,
    options,
  );
}
export function useListNotSkippedEventsWithoutGiftLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TListNotSkippedEventsWithoutGiftQuery,
    TListNotSkippedEventsWithoutGiftQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TListNotSkippedEventsWithoutGiftQuery, TListNotSkippedEventsWithoutGiftQueryVariables>(
    ListNotSkippedEventsWithoutGiftDocument,
    options,
  );
}
export type ListNotSkippedEventsWithoutGiftQueryHookResult = ReturnType<typeof useListNotSkippedEventsWithoutGiftQuery>;
export type ListNotSkippedEventsWithoutGiftLazyQueryHookResult = ReturnType<
  typeof useListNotSkippedEventsWithoutGiftLazyQuery
>;
export type ListNotSkippedEventsWithoutGiftQueryResult = Apollo.QueryResult<
  TListNotSkippedEventsWithoutGiftQuery,
  TListNotSkippedEventsWithoutGiftQueryVariables
>;
export const ListSkippedEventsDocument = gql`
  query ListSkippedEvents($nextToken: String, $limit: Int, $order: OrderDirection) {
    listSkippedEvents(nextToken: $nextToken, limit: $limit, order: $order)
      @connection(key: "listSkippedEvents", filter: []) {
      nextToken
      items {
        ...EventFields
      }
    }
  }
  ${EventFieldsFragmentDoc}
`;

/**
 * __useListSkippedEventsQuery__
 *
 * To run a query within a React component, call `useListSkippedEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSkippedEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSkippedEventsQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useListSkippedEventsQuery(
  baseOptions?: Apollo.QueryHookOptions<TListSkippedEventsQuery, TListSkippedEventsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TListSkippedEventsQuery, TListSkippedEventsQueryVariables>(ListSkippedEventsDocument, options);
}
export function useListSkippedEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TListSkippedEventsQuery, TListSkippedEventsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TListSkippedEventsQuery, TListSkippedEventsQueryVariables>(
    ListSkippedEventsDocument,
    options,
  );
}
export type ListSkippedEventsQueryHookResult = ReturnType<typeof useListSkippedEventsQuery>;
export type ListSkippedEventsLazyQueryHookResult = ReturnType<typeof useListSkippedEventsLazyQuery>;
export type ListSkippedEventsQueryResult = Apollo.QueryResult<
  TListSkippedEventsQuery,
  TListSkippedEventsQueryVariables
>;
export const ListEmployeeReferenceEventsDocument = gql`
  query ListEmployeeReferenceEvents(
    $employeeId: ID!
    $companyId: ID!
    $nextToken: String
    $limit: Int
    $order: OrderDirection
  ) {
    listEmployeeReferenceEvents(
      employeeId: $employeeId
      companyId: $companyId
      nextToken: $nextToken
      limit: $limit
      order: $order
    ) {
      items {
        ...BasicReferenceEvents
      }
    }
  }
  ${BasicReferenceEventsFragmentDoc}
`;

/**
 * __useListEmployeeReferenceEventsQuery__
 *
 * To run a query within a React component, call `useListEmployeeReferenceEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListEmployeeReferenceEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListEmployeeReferenceEventsQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      companyId: // value for 'companyId'
 *      nextToken: // value for 'nextToken'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useListEmployeeReferenceEventsQuery(
  baseOptions: Apollo.QueryHookOptions<TListEmployeeReferenceEventsQuery, TListEmployeeReferenceEventsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TListEmployeeReferenceEventsQuery, TListEmployeeReferenceEventsQueryVariables>(
    ListEmployeeReferenceEventsDocument,
    options,
  );
}
export function useListEmployeeReferenceEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TListEmployeeReferenceEventsQuery,
    TListEmployeeReferenceEventsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TListEmployeeReferenceEventsQuery, TListEmployeeReferenceEventsQueryVariables>(
    ListEmployeeReferenceEventsDocument,
    options,
  );
}
export type ListEmployeeReferenceEventsQueryHookResult = ReturnType<typeof useListEmployeeReferenceEventsQuery>;
export type ListEmployeeReferenceEventsLazyQueryHookResult = ReturnType<typeof useListEmployeeReferenceEventsLazyQuery>;
export type ListEmployeeReferenceEventsQueryResult = Apollo.QueryResult<
  TListEmployeeReferenceEventsQuery,
  TListEmployeeReferenceEventsQueryVariables
>;
export const CreateEventDocument = gql`
  mutation CreateEvent($input: CreateEventMutationInput!) {
    createEvent(input: $input) {
      ...EventFields
    }
  }
  ${EventFieldsFragmentDoc}
`;
export type TCreateEventMutationFn = Apollo.MutationFunction<TCreateEventMutation, TCreateEventMutationVariables>;

/**
 * __useCreateEventMutation__
 *
 * To run a mutation, you first call `useCreateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventMutation, { data, loading, error }] = useCreateEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEventMutation(
  baseOptions?: Apollo.MutationHookOptions<TCreateEventMutation, TCreateEventMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TCreateEventMutation, TCreateEventMutationVariables>(CreateEventDocument, options);
}
export type CreateEventMutationHookResult = ReturnType<typeof useCreateEventMutation>;
export type CreateEventMutationResult = Apollo.MutationResult<TCreateEventMutation>;
export type CreateEventMutationOptions = Apollo.BaseMutationOptions<
  TCreateEventMutation,
  TCreateEventMutationVariables
>;
export const CreateReferenceEventDocument = gql`
  mutation CreateReferenceEvent($input: CreateReferenceEventMutationInput!) {
    createReferenceEvent(input: $input) {
      ...BasicReferenceEvents
    }
  }
  ${BasicReferenceEventsFragmentDoc}
`;
export type TCreateReferenceEventMutationFn = Apollo.MutationFunction<
  TCreateReferenceEventMutation,
  TCreateReferenceEventMutationVariables
>;

/**
 * __useCreateReferenceEventMutation__
 *
 * To run a mutation, you first call `useCreateReferenceEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReferenceEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReferenceEventMutation, { data, loading, error }] = useCreateReferenceEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateReferenceEventMutation(
  baseOptions?: Apollo.MutationHookOptions<TCreateReferenceEventMutation, TCreateReferenceEventMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TCreateReferenceEventMutation, TCreateReferenceEventMutationVariables>(
    CreateReferenceEventDocument,
    options,
  );
}
export type CreateReferenceEventMutationHookResult = ReturnType<typeof useCreateReferenceEventMutation>;
export type CreateReferenceEventMutationResult = Apollo.MutationResult<TCreateReferenceEventMutation>;
export type CreateReferenceEventMutationOptions = Apollo.BaseMutationOptions<
  TCreateReferenceEventMutation,
  TCreateReferenceEventMutationVariables
>;
export const SkipEventDocument = gql`
  mutation SkipEvent($id: ID!, $companyId: ID!, $version: Int!) {
    skipEvent(id: $id, companyId: $companyId, _version: $version) {
      ...EventFields
    }
  }
  ${EventFieldsFragmentDoc}
`;
export type TSkipEventMutationFn = Apollo.MutationFunction<TSkipEventMutation, TSkipEventMutationVariables>;

/**
 * __useSkipEventMutation__
 *
 * To run a mutation, you first call `useSkipEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSkipEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [skipEventMutation, { data, loading, error }] = useSkipEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *      companyId: // value for 'companyId'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useSkipEventMutation(
  baseOptions?: Apollo.MutationHookOptions<TSkipEventMutation, TSkipEventMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TSkipEventMutation, TSkipEventMutationVariables>(SkipEventDocument, options);
}
export type SkipEventMutationHookResult = ReturnType<typeof useSkipEventMutation>;
export type SkipEventMutationResult = Apollo.MutationResult<TSkipEventMutation>;
export type SkipEventMutationOptions = Apollo.BaseMutationOptions<TSkipEventMutation, TSkipEventMutationVariables>;
export const UnskipEventDocument = gql`
  mutation UnskipEvent($id: ID!, $companyId: ID!, $version: Int!) {
    unskipEvent(id: $id, companyId: $companyId, _version: $version) {
      ...EventFields
    }
  }
  ${EventFieldsFragmentDoc}
`;
export type TUnskipEventMutationFn = Apollo.MutationFunction<TUnskipEventMutation, TUnskipEventMutationVariables>;

/**
 * __useUnskipEventMutation__
 *
 * To run a mutation, you first call `useUnskipEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnskipEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unskipEventMutation, { data, loading, error }] = useUnskipEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *      companyId: // value for 'companyId'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useUnskipEventMutation(
  baseOptions?: Apollo.MutationHookOptions<TUnskipEventMutation, TUnskipEventMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TUnskipEventMutation, TUnskipEventMutationVariables>(UnskipEventDocument, options);
}
export type UnskipEventMutationHookResult = ReturnType<typeof useUnskipEventMutation>;
export type UnskipEventMutationResult = Apollo.MutationResult<TUnskipEventMutation>;
export type UnskipEventMutationOptions = Apollo.BaseMutationOptions<
  TUnskipEventMutation,
  TUnskipEventMutationVariables
>;
export const OnAnyEventChangedDocument = gql`
  subscription OnAnyEventChanged {
    onAnyEventChanged {
      ...AnyEventChangedFields
    }
  }
  ${AnyEventChangedFieldsFragmentDoc}
`;

/**
 * __useOnAnyEventChangedSubscription__
 *
 * To run a query within a React component, call `useOnAnyEventChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnAnyEventChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnAnyEventChangedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnAnyEventChangedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<TOnAnyEventChangedSubscription, TOnAnyEventChangedSubscriptionVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<TOnAnyEventChangedSubscription, TOnAnyEventChangedSubscriptionVariables>(
    OnAnyEventChangedDocument,
    options,
  );
}
export type OnAnyEventChangedSubscriptionHookResult = ReturnType<typeof useOnAnyEventChangedSubscription>;
export type OnAnyEventChangedSubscriptionResult = Apollo.SubscriptionResult<TOnAnyEventChangedSubscription>;
export const ListEmployeeAndDependatsGiftsDocument = gql`
  query ListEmployeeAndDependatsGifts(
    $employeeId: ID!
    $companyId: ID!
    $nextToken: String
    $limit: Int
    $order: OrderDirection
  ) {
    listEmployeeAndDependatsGifts(
      employeeId: $employeeId
      companyId: $companyId
      nextToken: $nextToken
      limit: $limit
      order: $order
    ) {
      nextToken
      items {
        ...GiftWithShipmentFields
      }
    }
  }
  ${GiftWithShipmentFieldsFragmentDoc}
`;

/**
 * __useListEmployeeAndDependatsGiftsQuery__
 *
 * To run a query within a React component, call `useListEmployeeAndDependatsGiftsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListEmployeeAndDependatsGiftsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListEmployeeAndDependatsGiftsQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      companyId: // value for 'companyId'
 *      nextToken: // value for 'nextToken'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useListEmployeeAndDependatsGiftsQuery(
  baseOptions: Apollo.QueryHookOptions<
    TListEmployeeAndDependatsGiftsQuery,
    TListEmployeeAndDependatsGiftsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TListEmployeeAndDependatsGiftsQuery, TListEmployeeAndDependatsGiftsQueryVariables>(
    ListEmployeeAndDependatsGiftsDocument,
    options,
  );
}
export function useListEmployeeAndDependatsGiftsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TListEmployeeAndDependatsGiftsQuery,
    TListEmployeeAndDependatsGiftsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TListEmployeeAndDependatsGiftsQuery, TListEmployeeAndDependatsGiftsQueryVariables>(
    ListEmployeeAndDependatsGiftsDocument,
    options,
  );
}
export type ListEmployeeAndDependatsGiftsQueryHookResult = ReturnType<typeof useListEmployeeAndDependatsGiftsQuery>;
export type ListEmployeeAndDependatsGiftsLazyQueryHookResult = ReturnType<
  typeof useListEmployeeAndDependatsGiftsLazyQuery
>;
export type ListEmployeeAndDependatsGiftsQueryResult = Apollo.QueryResult<
  TListEmployeeAndDependatsGiftsQuery,
  TListEmployeeAndDependatsGiftsQueryVariables
>;
export const ListNotDoneGiftsDocument = gql`
  query ListNotDoneGifts($nextToken: String, $limit: Int, $order: OrderDirection) {
    listNotDoneGifts(nextToken: $nextToken, limit: $limit, order: $order) {
      nextToken
      items {
        ...GiftFields
      }
    }
  }
  ${GiftFieldsFragmentDoc}
`;

/**
 * __useListNotDoneGiftsQuery__
 *
 * To run a query within a React component, call `useListNotDoneGiftsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListNotDoneGiftsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListNotDoneGiftsQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useListNotDoneGiftsQuery(
  baseOptions?: Apollo.QueryHookOptions<TListNotDoneGiftsQuery, TListNotDoneGiftsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TListNotDoneGiftsQuery, TListNotDoneGiftsQueryVariables>(ListNotDoneGiftsDocument, options);
}
export function useListNotDoneGiftsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TListNotDoneGiftsQuery, TListNotDoneGiftsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TListNotDoneGiftsQuery, TListNotDoneGiftsQueryVariables>(
    ListNotDoneGiftsDocument,
    options,
  );
}
export type ListNotDoneGiftsQueryHookResult = ReturnType<typeof useListNotDoneGiftsQuery>;
export type ListNotDoneGiftsLazyQueryHookResult = ReturnType<typeof useListNotDoneGiftsLazyQuery>;
export type ListNotDoneGiftsQueryResult = Apollo.QueryResult<TListNotDoneGiftsQuery, TListNotDoneGiftsQueryVariables>;
export const ListRecentlyDoneGiftsDocument = gql`
  query ListRecentlyDoneGifts($nextToken: String, $limit: Int, $order: OrderDirection) {
    listRecentlyDoneGifts(nextToken: $nextToken, limit: $limit, order: $order) {
      nextToken
      items {
        ...GiftFields
      }
    }
  }
  ${GiftFieldsFragmentDoc}
`;

/**
 * __useListRecentlyDoneGiftsQuery__
 *
 * To run a query within a React component, call `useListRecentlyDoneGiftsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListRecentlyDoneGiftsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListRecentlyDoneGiftsQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useListRecentlyDoneGiftsQuery(
  baseOptions?: Apollo.QueryHookOptions<TListRecentlyDoneGiftsQuery, TListRecentlyDoneGiftsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TListRecentlyDoneGiftsQuery, TListRecentlyDoneGiftsQueryVariables>(
    ListRecentlyDoneGiftsDocument,
    options,
  );
}
export function useListRecentlyDoneGiftsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TListRecentlyDoneGiftsQuery, TListRecentlyDoneGiftsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TListRecentlyDoneGiftsQuery, TListRecentlyDoneGiftsQueryVariables>(
    ListRecentlyDoneGiftsDocument,
    options,
  );
}
export type ListRecentlyDoneGiftsQueryHookResult = ReturnType<typeof useListRecentlyDoneGiftsQuery>;
export type ListRecentlyDoneGiftsLazyQueryHookResult = ReturnType<typeof useListRecentlyDoneGiftsLazyQuery>;
export type ListRecentlyDoneGiftsQueryResult = Apollo.QueryResult<
  TListRecentlyDoneGiftsQuery,
  TListRecentlyDoneGiftsQueryVariables
>;
export const ListShipmentGiftsDocument = gql`
  query ListShipmentGifts($shipmentId: ID!, $companyId: ID!, $nextToken: String, $limit: Int, $order: OrderDirection) {
    listShipmentGifts(
      shipmentId: $shipmentId
      companyId: $companyId
      nextToken: $nextToken
      limit: $limit
      order: $order
    ) {
      nextToken
      items {
        ...GiftFields
      }
    }
  }
  ${GiftFieldsFragmentDoc}
`;

/**
 * __useListShipmentGiftsQuery__
 *
 * To run a query within a React component, call `useListShipmentGiftsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListShipmentGiftsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListShipmentGiftsQuery({
 *   variables: {
 *      shipmentId: // value for 'shipmentId'
 *      companyId: // value for 'companyId'
 *      nextToken: // value for 'nextToken'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useListShipmentGiftsQuery(
  baseOptions: Apollo.QueryHookOptions<TListShipmentGiftsQuery, TListShipmentGiftsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TListShipmentGiftsQuery, TListShipmentGiftsQueryVariables>(ListShipmentGiftsDocument, options);
}
export function useListShipmentGiftsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TListShipmentGiftsQuery, TListShipmentGiftsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TListShipmentGiftsQuery, TListShipmentGiftsQueryVariables>(
    ListShipmentGiftsDocument,
    options,
  );
}
export type ListShipmentGiftsQueryHookResult = ReturnType<typeof useListShipmentGiftsQuery>;
export type ListShipmentGiftsLazyQueryHookResult = ReturnType<typeof useListShipmentGiftsLazyQuery>;
export type ListShipmentGiftsQueryResult = Apollo.QueryResult<
  TListShipmentGiftsQuery,
  TListShipmentGiftsQueryVariables
>;
export const GetGiftDocument = gql`
  query GetGift($id: ID!, $companyId: ID!) {
    getGift(id: $id, companyId: $companyId) {
      ...GiftFields
    }
  }
  ${GiftFieldsFragmentDoc}
`;

/**
 * __useGetGiftQuery__
 *
 * To run a query within a React component, call `useGetGiftQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGiftQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGiftQuery({
 *   variables: {
 *      id: // value for 'id'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetGiftQuery(baseOptions: Apollo.QueryHookOptions<TGetGiftQuery, TGetGiftQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TGetGiftQuery, TGetGiftQueryVariables>(GetGiftDocument, options);
}
export function useGetGiftLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TGetGiftQuery, TGetGiftQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TGetGiftQuery, TGetGiftQueryVariables>(GetGiftDocument, options);
}
export type GetGiftQueryHookResult = ReturnType<typeof useGetGiftQuery>;
export type GetGiftLazyQueryHookResult = ReturnType<typeof useGetGiftLazyQuery>;
export type GetGiftQueryResult = Apollo.QueryResult<TGetGiftQuery, TGetGiftQueryVariables>;
export const CreateEventGiftDocument = gql`
  mutation CreateEventGift($eventId: ID!, $companyId: ID!, $eventVersion: Int!, $group: String) {
    createEventGift(eventId: $eventId, companyId: $companyId, _eventVersion: $eventVersion, group: $group) {
      companyId
      event {
        ...BasicEventFields
      }
      gift {
        ...GiftFields
      }
    }
  }
  ${BasicEventFieldsFragmentDoc}
  ${GiftFieldsFragmentDoc}
`;
export type TCreateEventGiftMutationFn = Apollo.MutationFunction<
  TCreateEventGiftMutation,
  TCreateEventGiftMutationVariables
>;

/**
 * __useCreateEventGiftMutation__
 *
 * To run a mutation, you first call `useCreateEventGiftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventGiftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventGiftMutation, { data, loading, error }] = useCreateEventGiftMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      companyId: // value for 'companyId'
 *      eventVersion: // value for 'eventVersion'
 *      group: // value for 'group'
 *   },
 * });
 */
export function useCreateEventGiftMutation(
  baseOptions?: Apollo.MutationHookOptions<TCreateEventGiftMutation, TCreateEventGiftMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TCreateEventGiftMutation, TCreateEventGiftMutationVariables>(
    CreateEventGiftDocument,
    options,
  );
}
export type CreateEventGiftMutationHookResult = ReturnType<typeof useCreateEventGiftMutation>;
export type CreateEventGiftMutationResult = Apollo.MutationResult<TCreateEventGiftMutation>;
export type CreateEventGiftMutationOptions = Apollo.BaseMutationOptions<
  TCreateEventGiftMutation,
  TCreateEventGiftMutationVariables
>;
export const MoveGiftDocument = gql`
  mutation MoveGift($id: ID!, $companyId: ID!, $version: Int!, $input: MoveGiftMutationInput!) {
    moveGift(id: $id, companyId: $companyId, _version: $version, input: $input) {
      ...GiftFields
    }
  }
  ${GiftFieldsFragmentDoc}
`;
export type TMoveGiftMutationFn = Apollo.MutationFunction<TMoveGiftMutation, TMoveGiftMutationVariables>;

/**
 * __useMoveGiftMutation__
 *
 * To run a mutation, you first call `useMoveGiftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveGiftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveGiftMutation, { data, loading, error }] = useMoveGiftMutation({
 *   variables: {
 *      id: // value for 'id'
 *      companyId: // value for 'companyId'
 *      version: // value for 'version'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMoveGiftMutation(
  baseOptions?: Apollo.MutationHookOptions<TMoveGiftMutation, TMoveGiftMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TMoveGiftMutation, TMoveGiftMutationVariables>(MoveGiftDocument, options);
}
export type MoveGiftMutationHookResult = ReturnType<typeof useMoveGiftMutation>;
export type MoveGiftMutationResult = Apollo.MutationResult<TMoveGiftMutation>;
export type MoveGiftMutationOptions = Apollo.BaseMutationOptions<TMoveGiftMutation, TMoveGiftMutationVariables>;
export const UpdateGiftDocument = gql`
  mutation UpdateGift($id: ID!, $companyId: ID!, $version: Int!, $input: UpdateGiftMutationInput!) {
    updateGift(id: $id, companyId: $companyId, _version: $version, input: $input) {
      ...GiftFields
    }
  }
  ${GiftFieldsFragmentDoc}
`;
export type TUpdateGiftMutationFn = Apollo.MutationFunction<TUpdateGiftMutation, TUpdateGiftMutationVariables>;

/**
 * __useUpdateGiftMutation__
 *
 * To run a mutation, you first call `useUpdateGiftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGiftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGiftMutation, { data, loading, error }] = useUpdateGiftMutation({
 *   variables: {
 *      id: // value for 'id'
 *      companyId: // value for 'companyId'
 *      version: // value for 'version'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateGiftMutation(
  baseOptions?: Apollo.MutationHookOptions<TUpdateGiftMutation, TUpdateGiftMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TUpdateGiftMutation, TUpdateGiftMutationVariables>(UpdateGiftDocument, options);
}
export type UpdateGiftMutationHookResult = ReturnType<typeof useUpdateGiftMutation>;
export type UpdateGiftMutationResult = Apollo.MutationResult<TUpdateGiftMutation>;
export type UpdateGiftMutationOptions = Apollo.BaseMutationOptions<TUpdateGiftMutation, TUpdateGiftMutationVariables>;
export const RemoveGiftFromGroupDocument = gql`
  mutation RemoveGiftFromGroup($id: ID!, $companyId: ID!, $version: Int!) {
    removeGiftFromGroup(id: $id, companyId: $companyId, _version: $version) {
      items {
        ...GiftFields
      }
    }
  }
  ${GiftFieldsFragmentDoc}
`;
export type TRemoveGiftFromGroupMutationFn = Apollo.MutationFunction<
  TRemoveGiftFromGroupMutation,
  TRemoveGiftFromGroupMutationVariables
>;

/**
 * __useRemoveGiftFromGroupMutation__
 *
 * To run a mutation, you first call `useRemoveGiftFromGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveGiftFromGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeGiftFromGroupMutation, { data, loading, error }] = useRemoveGiftFromGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      companyId: // value for 'companyId'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useRemoveGiftFromGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<TRemoveGiftFromGroupMutation, TRemoveGiftFromGroupMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TRemoveGiftFromGroupMutation, TRemoveGiftFromGroupMutationVariables>(
    RemoveGiftFromGroupDocument,
    options,
  );
}
export type RemoveGiftFromGroupMutationHookResult = ReturnType<typeof useRemoveGiftFromGroupMutation>;
export type RemoveGiftFromGroupMutationResult = Apollo.MutationResult<TRemoveGiftFromGroupMutation>;
export type RemoveGiftFromGroupMutationOptions = Apollo.BaseMutationOptions<
  TRemoveGiftFromGroupMutation,
  TRemoveGiftFromGroupMutationVariables
>;
export const DeleteGiftDocument = gql`
  mutation DeleteGift($id: ID!, $companyId: ID!, $version: Int!) {
    deleteGift(id: $id, companyId: $companyId, _version: $version) {
      ...BasicGiftFields
    }
  }
  ${BasicGiftFieldsFragmentDoc}
`;
export type TDeleteGiftMutationFn = Apollo.MutationFunction<TDeleteGiftMutation, TDeleteGiftMutationVariables>;

/**
 * __useDeleteGiftMutation__
 *
 * To run a mutation, you first call `useDeleteGiftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGiftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGiftMutation, { data, loading, error }] = useDeleteGiftMutation({
 *   variables: {
 *      id: // value for 'id'
 *      companyId: // value for 'companyId'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useDeleteGiftMutation(
  baseOptions?: Apollo.MutationHookOptions<TDeleteGiftMutation, TDeleteGiftMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TDeleteGiftMutation, TDeleteGiftMutationVariables>(DeleteGiftDocument, options);
}
export type DeleteGiftMutationHookResult = ReturnType<typeof useDeleteGiftMutation>;
export type DeleteGiftMutationResult = Apollo.MutationResult<TDeleteGiftMutation>;
export type DeleteGiftMutationOptions = Apollo.BaseMutationOptions<TDeleteGiftMutation, TDeleteGiftMutationVariables>;
export const NotifyNewHiresDocument = gql`
  mutation NotifyNewHires($events: [NotifyNewHireMutationInput]!) {
    notifyNewHires(events: $events)
  }
`;
export type TNotifyNewHiresMutationFn = Apollo.MutationFunction<
  TNotifyNewHiresMutation,
  TNotifyNewHiresMutationVariables
>;

/**
 * __useNotifyNewHiresMutation__
 *
 * To run a mutation, you first call `useNotifyNewHiresMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotifyNewHiresMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notifyNewHiresMutation, { data, loading, error }] = useNotifyNewHiresMutation({
 *   variables: {
 *      events: // value for 'events'
 *   },
 * });
 */
export function useNotifyNewHiresMutation(
  baseOptions?: Apollo.MutationHookOptions<TNotifyNewHiresMutation, TNotifyNewHiresMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TNotifyNewHiresMutation, TNotifyNewHiresMutationVariables>(NotifyNewHiresDocument, options);
}
export type NotifyNewHiresMutationHookResult = ReturnType<typeof useNotifyNewHiresMutation>;
export type NotifyNewHiresMutationResult = Apollo.MutationResult<TNotifyNewHiresMutation>;
export type NotifyNewHiresMutationOptions = Apollo.BaseMutationOptions<
  TNotifyNewHiresMutation,
  TNotifyNewHiresMutationVariables
>;
export const OnAnyEventGiftCreatedDocument = gql`
  subscription OnAnyEventGiftCreated {
    onAnyEventGiftCreated {
      event {
        ...AnyEventGiftCreatedEventFields
      }
      gift {
        ...AnyEventGiftCreatedGiftFields
      }
    }
  }
  ${AnyEventGiftCreatedEventFieldsFragmentDoc}
  ${AnyEventGiftCreatedGiftFieldsFragmentDoc}
`;

/**
 * __useOnAnyEventGiftCreatedSubscription__
 *
 * To run a query within a React component, call `useOnAnyEventGiftCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnAnyEventGiftCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnAnyEventGiftCreatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnAnyEventGiftCreatedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    TOnAnyEventGiftCreatedSubscription,
    TOnAnyEventGiftCreatedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<TOnAnyEventGiftCreatedSubscription, TOnAnyEventGiftCreatedSubscriptionVariables>(
    OnAnyEventGiftCreatedDocument,
    options,
  );
}
export type OnAnyEventGiftCreatedSubscriptionHookResult = ReturnType<typeof useOnAnyEventGiftCreatedSubscription>;
export type OnAnyEventGiftCreatedSubscriptionResult = Apollo.SubscriptionResult<TOnAnyEventGiftCreatedSubscription>;
export const OnAnyGiftRemovedFromGroupDocument = gql`
  subscription OnAnyGiftRemovedFromGroup {
    onAnyGiftRemovedFromGroup {
      items {
        ...AnyGiftRemovedFromGroupFields
      }
    }
  }
  ${AnyGiftRemovedFromGroupFieldsFragmentDoc}
`;

/**
 * __useOnAnyGiftRemovedFromGroupSubscription__
 *
 * To run a query within a React component, call `useOnAnyGiftRemovedFromGroupSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnAnyGiftRemovedFromGroupSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnAnyGiftRemovedFromGroupSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnAnyGiftRemovedFromGroupSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    TOnAnyGiftRemovedFromGroupSubscription,
    TOnAnyGiftRemovedFromGroupSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    TOnAnyGiftRemovedFromGroupSubscription,
    TOnAnyGiftRemovedFromGroupSubscriptionVariables
  >(OnAnyGiftRemovedFromGroupDocument, options);
}
export type OnAnyGiftRemovedFromGroupSubscriptionHookResult = ReturnType<
  typeof useOnAnyGiftRemovedFromGroupSubscription
>;
export type OnAnyGiftRemovedFromGroupSubscriptionResult =
  Apollo.SubscriptionResult<TOnAnyGiftRemovedFromGroupSubscription>;
export const OnAnyGiftChangedDocument = gql`
  subscription OnAnyGiftChanged {
    onAnyGiftChanged {
      ...AnyGiftChangedFields
    }
  }
  ${AnyGiftChangedFieldsFragmentDoc}
`;

/**
 * __useOnAnyGiftChangedSubscription__
 *
 * To run a query within a React component, call `useOnAnyGiftChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnAnyGiftChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnAnyGiftChangedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnAnyGiftChangedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<TOnAnyGiftChangedSubscription, TOnAnyGiftChangedSubscriptionVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<TOnAnyGiftChangedSubscription, TOnAnyGiftChangedSubscriptionVariables>(
    OnAnyGiftChangedDocument,
    options,
  );
}
export type OnAnyGiftChangedSubscriptionHookResult = ReturnType<typeof useOnAnyGiftChangedSubscription>;
export type OnAnyGiftChangedSubscriptionResult = Apollo.SubscriptionResult<TOnAnyGiftChangedSubscription>;
export const ListAllLogsDocument = gql`
  query ListAllLogs($userType: LogUserTypes!, $nextToken: String, $limit: Int, $order: OrderDirection) {
    listAllLogs(userType: $userType, nextToken: $nextToken, limit: $limit, order: $order) {
      nextToken
      items {
        ...LogFields
      }
    }
  }
  ${LogFieldsFragmentDoc}
`;

/**
 * __useListAllLogsQuery__
 *
 * To run a query within a React component, call `useListAllLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAllLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAllLogsQuery({
 *   variables: {
 *      userType: // value for 'userType'
 *      nextToken: // value for 'nextToken'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useListAllLogsQuery(
  baseOptions: Apollo.QueryHookOptions<TListAllLogsQuery, TListAllLogsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TListAllLogsQuery, TListAllLogsQueryVariables>(ListAllLogsDocument, options);
}
export function useListAllLogsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TListAllLogsQuery, TListAllLogsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TListAllLogsQuery, TListAllLogsQueryVariables>(ListAllLogsDocument, options);
}
export type ListAllLogsQueryHookResult = ReturnType<typeof useListAllLogsQuery>;
export type ListAllLogsLazyQueryHookResult = ReturnType<typeof useListAllLogsLazyQuery>;
export type ListAllLogsQueryResult = Apollo.QueryResult<TListAllLogsQuery, TListAllLogsQueryVariables>;
export const GetOfficeDocument = gql`
  query GetOffice($id: ID!, $companyId: ID!) {
    getOffice(id: $id, companyId: $companyId) {
      ...OfficeWithAddressFields
    }
  }
  ${OfficeWithAddressFieldsFragmentDoc}
`;

/**
 * __useGetOfficeQuery__
 *
 * To run a query within a React component, call `useGetOfficeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOfficeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOfficeQuery({
 *   variables: {
 *      id: // value for 'id'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetOfficeQuery(baseOptions: Apollo.QueryHookOptions<TGetOfficeQuery, TGetOfficeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TGetOfficeQuery, TGetOfficeQueryVariables>(GetOfficeDocument, options);
}
export function useGetOfficeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TGetOfficeQuery, TGetOfficeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TGetOfficeQuery, TGetOfficeQueryVariables>(GetOfficeDocument, options);
}
export type GetOfficeQueryHookResult = ReturnType<typeof useGetOfficeQuery>;
export type GetOfficeLazyQueryHookResult = ReturnType<typeof useGetOfficeLazyQuery>;
export type GetOfficeQueryResult = Apollo.QueryResult<TGetOfficeQuery, TGetOfficeQueryVariables>;
export const ListAllOfficesDocument = gql`
  query ListAllOffices($nextToken: String, $limit: Int, $order: OrderDirection) {
    listAllOffices(nextToken: $nextToken, limit: $limit, order: $order) {
      nextToken
      items {
        ...OfficeWithAddressFields
      }
    }
  }
  ${OfficeWithAddressFieldsFragmentDoc}
`;

/**
 * __useListAllOfficesQuery__
 *
 * To run a query within a React component, call `useListAllOfficesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAllOfficesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAllOfficesQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useListAllOfficesQuery(
  baseOptions?: Apollo.QueryHookOptions<TListAllOfficesQuery, TListAllOfficesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TListAllOfficesQuery, TListAllOfficesQueryVariables>(ListAllOfficesDocument, options);
}
export function useListAllOfficesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TListAllOfficesQuery, TListAllOfficesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TListAllOfficesQuery, TListAllOfficesQueryVariables>(ListAllOfficesDocument, options);
}
export type ListAllOfficesQueryHookResult = ReturnType<typeof useListAllOfficesQuery>;
export type ListAllOfficesLazyQueryHookResult = ReturnType<typeof useListAllOfficesLazyQuery>;
export type ListAllOfficesQueryResult = Apollo.QueryResult<TListAllOfficesQuery, TListAllOfficesQueryVariables>;
export const ListCompanyOfficesDocument = gql`
  query ListCompanyOffices($companyId: ID!, $nextToken: String, $limit: Int, $order: OrderDirection) {
    listOffices(companyId: $companyId, nextToken: $nextToken, limit: $limit, order: $order) {
      nextToken
      items {
        ...OfficeFields
      }
    }
  }
  ${OfficeFieldsFragmentDoc}
`;

/**
 * __useListCompanyOfficesQuery__
 *
 * To run a query within a React component, call `useListCompanyOfficesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCompanyOfficesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCompanyOfficesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      nextToken: // value for 'nextToken'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useListCompanyOfficesQuery(
  baseOptions: Apollo.QueryHookOptions<TListCompanyOfficesQuery, TListCompanyOfficesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TListCompanyOfficesQuery, TListCompanyOfficesQueryVariables>(
    ListCompanyOfficesDocument,
    options,
  );
}
export function useListCompanyOfficesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TListCompanyOfficesQuery, TListCompanyOfficesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TListCompanyOfficesQuery, TListCompanyOfficesQueryVariables>(
    ListCompanyOfficesDocument,
    options,
  );
}
export type ListCompanyOfficesQueryHookResult = ReturnType<typeof useListCompanyOfficesQuery>;
export type ListCompanyOfficesLazyQueryHookResult = ReturnType<typeof useListCompanyOfficesLazyQuery>;
export type ListCompanyOfficesQueryResult = Apollo.QueryResult<
  TListCompanyOfficesQuery,
  TListCompanyOfficesQueryVariables
>;
export const OnAnyOfficeChangedDocument = gql`
  subscription OnAnyOfficeChanged {
    onAnyOfficeChanged {
      ...AnyOfficeChangedFields
    }
  }
  ${AnyOfficeChangedFieldsFragmentDoc}
`;

/**
 * __useOnAnyOfficeChangedSubscription__
 *
 * To run a query within a React component, call `useOnAnyOfficeChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnAnyOfficeChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnAnyOfficeChangedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnAnyOfficeChangedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    TOnAnyOfficeChangedSubscription,
    TOnAnyOfficeChangedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<TOnAnyOfficeChangedSubscription, TOnAnyOfficeChangedSubscriptionVariables>(
    OnAnyOfficeChangedDocument,
    options,
  );
}
export type OnAnyOfficeChangedSubscriptionHookResult = ReturnType<typeof useOnAnyOfficeChangedSubscription>;
export type OnAnyOfficeChangedSubscriptionResult = Apollo.SubscriptionResult<TOnAnyOfficeChangedSubscription>;
export const GetRecipientDocument = gql`
  query GetRecipient($id: ID!, $companyId: ID!) {
    getRecipient(id: $id, companyId: $companyId) {
      ...RecipientFields
    }
  }
  ${RecipientFieldsFragmentDoc}
`;

/**
 * __useGetRecipientQuery__
 *
 * To run a query within a React component, call `useGetRecipientQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecipientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecipientQuery({
 *   variables: {
 *      id: // value for 'id'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetRecipientQuery(
  baseOptions: Apollo.QueryHookOptions<TGetRecipientQuery, TGetRecipientQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TGetRecipientQuery, TGetRecipientQueryVariables>(GetRecipientDocument, options);
}
export function useGetRecipientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TGetRecipientQuery, TGetRecipientQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TGetRecipientQuery, TGetRecipientQueryVariables>(GetRecipientDocument, options);
}
export type GetRecipientQueryHookResult = ReturnType<typeof useGetRecipientQuery>;
export type GetRecipientLazyQueryHookResult = ReturnType<typeof useGetRecipientLazyQuery>;
export type GetRecipientQueryResult = Apollo.QueryResult<TGetRecipientQuery, TGetRecipientQueryVariables>;
export const ListRosterFilesDocument = gql`
  query ListRosterFiles($nextToken: String, $limit: Int, $order: OrderDirection) {
    listRosterFiles(nextToken: $nextToken, limit: $limit, order: $order) {
      nextToken
      items {
        ...RosterFileFields
      }
    }
  }
  ${RosterFileFieldsFragmentDoc}
`;

/**
 * __useListRosterFilesQuery__
 *
 * To run a query within a React component, call `useListRosterFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListRosterFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListRosterFilesQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useListRosterFilesQuery(
  baseOptions?: Apollo.QueryHookOptions<TListRosterFilesQuery, TListRosterFilesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TListRosterFilesQuery, TListRosterFilesQueryVariables>(ListRosterFilesDocument, options);
}
export function useListRosterFilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TListRosterFilesQuery, TListRosterFilesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TListRosterFilesQuery, TListRosterFilesQueryVariables>(ListRosterFilesDocument, options);
}
export type ListRosterFilesQueryHookResult = ReturnType<typeof useListRosterFilesQuery>;
export type ListRosterFilesLazyQueryHookResult = ReturnType<typeof useListRosterFilesLazyQuery>;
export type ListRosterFilesQueryResult = Apollo.QueryResult<TListRosterFilesQuery, TListRosterFilesQueryVariables>;
export const ListRosterFilesByCompanyIdDocument = gql`
  query ListRosterFilesByCompanyId($companyId: ID!, $nextToken: String, $limit: Int, $order: OrderDirection) {
    listRosterFilesByCompanyId(companyId: $companyId, nextToken: $nextToken, limit: $limit, order: $order) {
      nextToken
      items {
        ...RosterFileFields
      }
    }
  }
  ${RosterFileFieldsFragmentDoc}
`;

/**
 * __useListRosterFilesByCompanyIdQuery__
 *
 * To run a query within a React component, call `useListRosterFilesByCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useListRosterFilesByCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListRosterFilesByCompanyIdQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      nextToken: // value for 'nextToken'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useListRosterFilesByCompanyIdQuery(
  baseOptions: Apollo.QueryHookOptions<TListRosterFilesByCompanyIdQuery, TListRosterFilesByCompanyIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TListRosterFilesByCompanyIdQuery, TListRosterFilesByCompanyIdQueryVariables>(
    ListRosterFilesByCompanyIdDocument,
    options,
  );
}
export function useListRosterFilesByCompanyIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TListRosterFilesByCompanyIdQuery,
    TListRosterFilesByCompanyIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TListRosterFilesByCompanyIdQuery, TListRosterFilesByCompanyIdQueryVariables>(
    ListRosterFilesByCompanyIdDocument,
    options,
  );
}
export type ListRosterFilesByCompanyIdQueryHookResult = ReturnType<typeof useListRosterFilesByCompanyIdQuery>;
export type ListRosterFilesByCompanyIdLazyQueryHookResult = ReturnType<typeof useListRosterFilesByCompanyIdLazyQuery>;
export type ListRosterFilesByCompanyIdQueryResult = Apollo.QueryResult<
  TListRosterFilesByCompanyIdQuery,
  TListRosterFilesByCompanyIdQueryVariables
>;
export const CreateRosterFileDocument = gql`
  mutation CreateRosterFile($input: CreateRosterFileInput!) {
    createRosterFile(input: $input) {
      ...RosterFileFields
    }
  }
  ${RosterFileFieldsFragmentDoc}
`;
export type TCreateRosterFileMutationFn = Apollo.MutationFunction<
  TCreateRosterFileMutation,
  TCreateRosterFileMutationVariables
>;

/**
 * __useCreateRosterFileMutation__
 *
 * To run a mutation, you first call `useCreateRosterFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRosterFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRosterFileMutation, { data, loading, error }] = useCreateRosterFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRosterFileMutation(
  baseOptions?: Apollo.MutationHookOptions<TCreateRosterFileMutation, TCreateRosterFileMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TCreateRosterFileMutation, TCreateRosterFileMutationVariables>(
    CreateRosterFileDocument,
    options,
  );
}
export type CreateRosterFileMutationHookResult = ReturnType<typeof useCreateRosterFileMutation>;
export type CreateRosterFileMutationResult = Apollo.MutationResult<TCreateRosterFileMutation>;
export type CreateRosterFileMutationOptions = Apollo.BaseMutationOptions<
  TCreateRosterFileMutation,
  TCreateRosterFileMutationVariables
>;
export const OnAnyRosterFileChangedDocument = gql`
  subscription OnAnyRosterFileChanged {
    onAnyRosterFileChanged {
      ...AnyRosterFileChangedFields
    }
  }
  ${AnyRosterFileChangedFieldsFragmentDoc}
`;

/**
 * __useOnAnyRosterFileChangedSubscription__
 *
 * To run a query within a React component, call `useOnAnyRosterFileChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnAnyRosterFileChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnAnyRosterFileChangedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnAnyRosterFileChangedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    TOnAnyRosterFileChangedSubscription,
    TOnAnyRosterFileChangedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<TOnAnyRosterFileChangedSubscription, TOnAnyRosterFileChangedSubscriptionVariables>(
    OnAnyRosterFileChangedDocument,
    options,
  );
}
export type OnAnyRosterFileChangedSubscriptionHookResult = ReturnType<typeof useOnAnyRosterFileChangedSubscription>;
export type OnAnyRosterFileChangedSubscriptionResult = Apollo.SubscriptionResult<TOnAnyRosterFileChangedSubscription>;
export const ListOpenShipmentsDocument = gql`
  query ListOpenShipments($nextToken: String, $limit: Int, $order: OrderDirection) {
    listOpenShipments(nextToken: $nextToken, limit: $limit, order: $order) {
      nextToken
      items {
        ...ShipmentFields
      }
    }
  }
  ${ShipmentFieldsFragmentDoc}
`;

/**
 * __useListOpenShipmentsQuery__
 *
 * To run a query within a React component, call `useListOpenShipmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListOpenShipmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListOpenShipmentsQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useListOpenShipmentsQuery(
  baseOptions?: Apollo.QueryHookOptions<TListOpenShipmentsQuery, TListOpenShipmentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TListOpenShipmentsQuery, TListOpenShipmentsQueryVariables>(ListOpenShipmentsDocument, options);
}
export function useListOpenShipmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TListOpenShipmentsQuery, TListOpenShipmentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TListOpenShipmentsQuery, TListOpenShipmentsQueryVariables>(
    ListOpenShipmentsDocument,
    options,
  );
}
export type ListOpenShipmentsQueryHookResult = ReturnType<typeof useListOpenShipmentsQuery>;
export type ListOpenShipmentsLazyQueryHookResult = ReturnType<typeof useListOpenShipmentsLazyQuery>;
export type ListOpenShipmentsQueryResult = Apollo.QueryResult<
  TListOpenShipmentsQuery,
  TListOpenShipmentsQueryVariables
>;
export const ListShippedShipmentsDocument = gql`
  query ListShippedShipments($nextToken: String, $limit: Int, $order: OrderDirection) {
    listShippedShipments(nextToken: $nextToken, limit: $limit, order: $order) {
      nextToken
      items {
        ...ShipmentFields
      }
    }
  }
  ${ShipmentFieldsFragmentDoc}
`;

/**
 * __useListShippedShipmentsQuery__
 *
 * To run a query within a React component, call `useListShippedShipmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListShippedShipmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListShippedShipmentsQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useListShippedShipmentsQuery(
  baseOptions?: Apollo.QueryHookOptions<TListShippedShipmentsQuery, TListShippedShipmentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TListShippedShipmentsQuery, TListShippedShipmentsQueryVariables>(
    ListShippedShipmentsDocument,
    options,
  );
}
export function useListShippedShipmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TListShippedShipmentsQuery, TListShippedShipmentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TListShippedShipmentsQuery, TListShippedShipmentsQueryVariables>(
    ListShippedShipmentsDocument,
    options,
  );
}
export type ListShippedShipmentsQueryHookResult = ReturnType<typeof useListShippedShipmentsQuery>;
export type ListShippedShipmentsLazyQueryHookResult = ReturnType<typeof useListShippedShipmentsLazyQuery>;
export type ListShippedShipmentsQueryResult = Apollo.QueryResult<
  TListShippedShipmentsQuery,
  TListShippedShipmentsQueryVariables
>;
export const ListSetForPickupShipmentsDocument = gql`
  query ListSetForPickupShipments($nextToken: String, $limit: Int, $order: OrderDirection) {
    listSetForPickupShipments(nextToken: $nextToken, limit: $limit, order: $order) {
      nextToken
      items {
        ...ShipmentFields
      }
    }
  }
  ${ShipmentFieldsFragmentDoc}
`;

/**
 * __useListSetForPickupShipmentsQuery__
 *
 * To run a query within a React component, call `useListSetForPickupShipmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSetForPickupShipmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSetForPickupShipmentsQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useListSetForPickupShipmentsQuery(
  baseOptions?: Apollo.QueryHookOptions<TListSetForPickupShipmentsQuery, TListSetForPickupShipmentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TListSetForPickupShipmentsQuery, TListSetForPickupShipmentsQueryVariables>(
    ListSetForPickupShipmentsDocument,
    options,
  );
}
export function useListSetForPickupShipmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TListSetForPickupShipmentsQuery, TListSetForPickupShipmentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TListSetForPickupShipmentsQuery, TListSetForPickupShipmentsQueryVariables>(
    ListSetForPickupShipmentsDocument,
    options,
  );
}
export type ListSetForPickupShipmentsQueryHookResult = ReturnType<typeof useListSetForPickupShipmentsQuery>;
export type ListSetForPickupShipmentsLazyQueryHookResult = ReturnType<typeof useListSetForPickupShipmentsLazyQuery>;
export type ListSetForPickupShipmentsQueryResult = Apollo.QueryResult<
  TListSetForPickupShipmentsQuery,
  TListSetForPickupShipmentsQueryVariables
>;
export const ListShipmentsByAddressAndStatusDocument = gql`
  query ListShipmentsByAddressAndStatus(
    $addressId: ID!
    $status: ShipmentStatuses!
    $nextToken: String
    $limit: Int
    $order: OrderDirection
  ) {
    listShipmentsByAddressAndStatus(
      addressId: $addressId
      status: $status
      nextToken: $nextToken
      limit: $limit
      order: $order
    ) {
      nextToken
      items {
        ...ShipmentFields
      }
    }
  }
  ${ShipmentFieldsFragmentDoc}
`;

/**
 * __useListShipmentsByAddressAndStatusQuery__
 *
 * To run a query within a React component, call `useListShipmentsByAddressAndStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useListShipmentsByAddressAndStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListShipmentsByAddressAndStatusQuery({
 *   variables: {
 *      addressId: // value for 'addressId'
 *      status: // value for 'status'
 *      nextToken: // value for 'nextToken'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useListShipmentsByAddressAndStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    TListShipmentsByAddressAndStatusQuery,
    TListShipmentsByAddressAndStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TListShipmentsByAddressAndStatusQuery, TListShipmentsByAddressAndStatusQueryVariables>(
    ListShipmentsByAddressAndStatusDocument,
    options,
  );
}
export function useListShipmentsByAddressAndStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TListShipmentsByAddressAndStatusQuery,
    TListShipmentsByAddressAndStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TListShipmentsByAddressAndStatusQuery, TListShipmentsByAddressAndStatusQueryVariables>(
    ListShipmentsByAddressAndStatusDocument,
    options,
  );
}
export type ListShipmentsByAddressAndStatusQueryHookResult = ReturnType<typeof useListShipmentsByAddressAndStatusQuery>;
export type ListShipmentsByAddressAndStatusLazyQueryHookResult = ReturnType<
  typeof useListShipmentsByAddressAndStatusLazyQuery
>;
export type ListShipmentsByAddressAndStatusQueryResult = Apollo.QueryResult<
  TListShipmentsByAddressAndStatusQuery,
  TListShipmentsByAddressAndStatusQueryVariables
>;
export const GetShipmentDocument = gql`
  query GetShipment($id: ID!, $companyId: ID!) {
    getShipment(id: $id, companyId: $companyId) {
      ...ShipmentFields
    }
  }
  ${ShipmentFieldsFragmentDoc}
`;

/**
 * __useGetShipmentQuery__
 *
 * To run a query within a React component, call `useGetShipmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShipmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShipmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetShipmentQuery(
  baseOptions: Apollo.QueryHookOptions<TGetShipmentQuery, TGetShipmentQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TGetShipmentQuery, TGetShipmentQueryVariables>(GetShipmentDocument, options);
}
export function useGetShipmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TGetShipmentQuery, TGetShipmentQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TGetShipmentQuery, TGetShipmentQueryVariables>(GetShipmentDocument, options);
}
export type GetShipmentQueryHookResult = ReturnType<typeof useGetShipmentQuery>;
export type GetShipmentLazyQueryHookResult = ReturnType<typeof useGetShipmentLazyQuery>;
export type GetShipmentQueryResult = Apollo.QueryResult<TGetShipmentQuery, TGetShipmentQueryVariables>;
export const CreateShipmentWithGiftsDocument = gql`
  mutation CreateShipmentWithGifts($input: CreateShipmentWithGiftsMutationInput!) {
    createShipmentWithGifts(input: $input) {
      ...ShipmentFields
      gifts {
        items {
          ...BasicGiftFields
        }
      }
    }
  }
  ${ShipmentFieldsFragmentDoc}
  ${BasicGiftFieldsFragmentDoc}
`;
export type TCreateShipmentWithGiftsMutationFn = Apollo.MutationFunction<
  TCreateShipmentWithGiftsMutation,
  TCreateShipmentWithGiftsMutationVariables
>;

/**
 * __useCreateShipmentWithGiftsMutation__
 *
 * To run a mutation, you first call `useCreateShipmentWithGiftsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateShipmentWithGiftsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createShipmentWithGiftsMutation, { data, loading, error }] = useCreateShipmentWithGiftsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateShipmentWithGiftsMutation(
  baseOptions?: Apollo.MutationHookOptions<TCreateShipmentWithGiftsMutation, TCreateShipmentWithGiftsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TCreateShipmentWithGiftsMutation, TCreateShipmentWithGiftsMutationVariables>(
    CreateShipmentWithGiftsDocument,
    options,
  );
}
export type CreateShipmentWithGiftsMutationHookResult = ReturnType<typeof useCreateShipmentWithGiftsMutation>;
export type CreateShipmentWithGiftsMutationResult = Apollo.MutationResult<TCreateShipmentWithGiftsMutation>;
export type CreateShipmentWithGiftsMutationOptions = Apollo.BaseMutationOptions<
  TCreateShipmentWithGiftsMutation,
  TCreateShipmentWithGiftsMutationVariables
>;
export const AddGiftsToShipmentDocument = gql`
  mutation AddGiftsToShipment($shipmentId: ID!, $companyId: ID!, $giftsIds: [ID!]!) {
    addGiftsToShipment(shipmentId: $shipmentId, companyId: $companyId, giftsIds: $giftsIds) {
      ...ShipmentFields
      gifts {
        items {
          ...BasicGiftFields
        }
      }
    }
  }
  ${ShipmentFieldsFragmentDoc}
  ${BasicGiftFieldsFragmentDoc}
`;
export type TAddGiftsToShipmentMutationFn = Apollo.MutationFunction<
  TAddGiftsToShipmentMutation,
  TAddGiftsToShipmentMutationVariables
>;

/**
 * __useAddGiftsToShipmentMutation__
 *
 * To run a mutation, you first call `useAddGiftsToShipmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGiftsToShipmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGiftsToShipmentMutation, { data, loading, error }] = useAddGiftsToShipmentMutation({
 *   variables: {
 *      shipmentId: // value for 'shipmentId'
 *      companyId: // value for 'companyId'
 *      giftsIds: // value for 'giftsIds'
 *   },
 * });
 */
export function useAddGiftsToShipmentMutation(
  baseOptions?: Apollo.MutationHookOptions<TAddGiftsToShipmentMutation, TAddGiftsToShipmentMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TAddGiftsToShipmentMutation, TAddGiftsToShipmentMutationVariables>(
    AddGiftsToShipmentDocument,
    options,
  );
}
export type AddGiftsToShipmentMutationHookResult = ReturnType<typeof useAddGiftsToShipmentMutation>;
export type AddGiftsToShipmentMutationResult = Apollo.MutationResult<TAddGiftsToShipmentMutation>;
export type AddGiftsToShipmentMutationOptions = Apollo.BaseMutationOptions<
  TAddGiftsToShipmentMutation,
  TAddGiftsToShipmentMutationVariables
>;
export const UpdateShipmentDocument = gql`
  mutation UpdateShipment($id: ID!, $companyId: ID!, $version: Int!, $input: UpdateShipmentMutationInput!) {
    updateShipment(id: $id, companyId: $companyId, _version: $version, input: $input) {
      ...ShipmentBasicFields
    }
  }
  ${ShipmentBasicFieldsFragmentDoc}
`;
export type TUpdateShipmentMutationFn = Apollo.MutationFunction<
  TUpdateShipmentMutation,
  TUpdateShipmentMutationVariables
>;

/**
 * __useUpdateShipmentMutation__
 *
 * To run a mutation, you first call `useUpdateShipmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShipmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShipmentMutation, { data, loading, error }] = useUpdateShipmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      companyId: // value for 'companyId'
 *      version: // value for 'version'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateShipmentMutation(
  baseOptions?: Apollo.MutationHookOptions<TUpdateShipmentMutation, TUpdateShipmentMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TUpdateShipmentMutation, TUpdateShipmentMutationVariables>(UpdateShipmentDocument, options);
}
export type UpdateShipmentMutationHookResult = ReturnType<typeof useUpdateShipmentMutation>;
export type UpdateShipmentMutationResult = Apollo.MutationResult<TUpdateShipmentMutation>;
export type UpdateShipmentMutationOptions = Apollo.BaseMutationOptions<
  TUpdateShipmentMutation,
  TUpdateShipmentMutationVariables
>;
export const SetShipmentForPickupDocument = gql`
  mutation SetShipmentForPickup($id: ID!, $companyId: ID!, $version: Int!) {
    setShipmentForPickup(id: $id, companyId: $companyId, _version: $version) {
      ...ShipmentBasicFields
    }
  }
  ${ShipmentBasicFieldsFragmentDoc}
`;
export type TSetShipmentForPickupMutationFn = Apollo.MutationFunction<
  TSetShipmentForPickupMutation,
  TSetShipmentForPickupMutationVariables
>;

/**
 * __useSetShipmentForPickupMutation__
 *
 * To run a mutation, you first call `useSetShipmentForPickupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetShipmentForPickupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setShipmentForPickupMutation, { data, loading, error }] = useSetShipmentForPickupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      companyId: // value for 'companyId'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useSetShipmentForPickupMutation(
  baseOptions?: Apollo.MutationHookOptions<TSetShipmentForPickupMutation, TSetShipmentForPickupMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TSetShipmentForPickupMutation, TSetShipmentForPickupMutationVariables>(
    SetShipmentForPickupDocument,
    options,
  );
}
export type SetShipmentForPickupMutationHookResult = ReturnType<typeof useSetShipmentForPickupMutation>;
export type SetShipmentForPickupMutationResult = Apollo.MutationResult<TSetShipmentForPickupMutation>;
export type SetShipmentForPickupMutationOptions = Apollo.BaseMutationOptions<
  TSetShipmentForPickupMutation,
  TSetShipmentForPickupMutationVariables
>;
export const SetShipmentAsPickedUpDocument = gql`
  mutation SetShipmentAsPickedUp($id: ID!, $companyId: ID!, $version: Int!) {
    setShipmentAsPickedUp(id: $id, companyId: $companyId, _version: $version) {
      ...ShipmentBasicFields
    }
  }
  ${ShipmentBasicFieldsFragmentDoc}
`;
export type TSetShipmentAsPickedUpMutationFn = Apollo.MutationFunction<
  TSetShipmentAsPickedUpMutation,
  TSetShipmentAsPickedUpMutationVariables
>;

/**
 * __useSetShipmentAsPickedUpMutation__
 *
 * To run a mutation, you first call `useSetShipmentAsPickedUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetShipmentAsPickedUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setShipmentAsPickedUpMutation, { data, loading, error }] = useSetShipmentAsPickedUpMutation({
 *   variables: {
 *      id: // value for 'id'
 *      companyId: // value for 'companyId'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useSetShipmentAsPickedUpMutation(
  baseOptions?: Apollo.MutationHookOptions<TSetShipmentAsPickedUpMutation, TSetShipmentAsPickedUpMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TSetShipmentAsPickedUpMutation, TSetShipmentAsPickedUpMutationVariables>(
    SetShipmentAsPickedUpDocument,
    options,
  );
}
export type SetShipmentAsPickedUpMutationHookResult = ReturnType<typeof useSetShipmentAsPickedUpMutation>;
export type SetShipmentAsPickedUpMutationResult = Apollo.MutationResult<TSetShipmentAsPickedUpMutation>;
export type SetShipmentAsPickedUpMutationOptions = Apollo.BaseMutationOptions<
  TSetShipmentAsPickedUpMutation,
  TSetShipmentAsPickedUpMutationVariables
>;
export const CancelShipmentForPickupDocument = gql`
  mutation CancelShipmentForPickup($id: ID!, $companyId: ID!, $version: Int!) {
    cancelShipmentForPickup(id: $id, companyId: $companyId, _version: $version) {
      ...ShipmentBasicFields
    }
  }
  ${ShipmentBasicFieldsFragmentDoc}
`;
export type TCancelShipmentForPickupMutationFn = Apollo.MutationFunction<
  TCancelShipmentForPickupMutation,
  TCancelShipmentForPickupMutationVariables
>;

/**
 * __useCancelShipmentForPickupMutation__
 *
 * To run a mutation, you first call `useCancelShipmentForPickupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelShipmentForPickupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelShipmentForPickupMutation, { data, loading, error }] = useCancelShipmentForPickupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      companyId: // value for 'companyId'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useCancelShipmentForPickupMutation(
  baseOptions?: Apollo.MutationHookOptions<TCancelShipmentForPickupMutation, TCancelShipmentForPickupMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TCancelShipmentForPickupMutation, TCancelShipmentForPickupMutationVariables>(
    CancelShipmentForPickupDocument,
    options,
  );
}
export type CancelShipmentForPickupMutationHookResult = ReturnType<typeof useCancelShipmentForPickupMutation>;
export type CancelShipmentForPickupMutationResult = Apollo.MutationResult<TCancelShipmentForPickupMutation>;
export type CancelShipmentForPickupMutationOptions = Apollo.BaseMutationOptions<
  TCancelShipmentForPickupMutation,
  TCancelShipmentForPickupMutationVariables
>;
export const SetShipmentAsReadyToShipDocument = gql`
  mutation SetShipmentAsReadyToShip(
    $id: ID!
    $companyId: ID!
    $version: Int!
    $input: SetShipmentAsReadyToShipMutationInput!
  ) {
    setShipmentAsReadyToShip(id: $id, companyId: $companyId, _version: $version, input: $input) {
      ...ShipmentBasicFields
    }
  }
  ${ShipmentBasicFieldsFragmentDoc}
`;
export type TSetShipmentAsReadyToShipMutationFn = Apollo.MutationFunction<
  TSetShipmentAsReadyToShipMutation,
  TSetShipmentAsReadyToShipMutationVariables
>;

/**
 * __useSetShipmentAsReadyToShipMutation__
 *
 * To run a mutation, you first call `useSetShipmentAsReadyToShipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetShipmentAsReadyToShipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setShipmentAsReadyToShipMutation, { data, loading, error }] = useSetShipmentAsReadyToShipMutation({
 *   variables: {
 *      id: // value for 'id'
 *      companyId: // value for 'companyId'
 *      version: // value for 'version'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetShipmentAsReadyToShipMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TSetShipmentAsReadyToShipMutation,
    TSetShipmentAsReadyToShipMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TSetShipmentAsReadyToShipMutation, TSetShipmentAsReadyToShipMutationVariables>(
    SetShipmentAsReadyToShipDocument,
    options,
  );
}
export type SetShipmentAsReadyToShipMutationHookResult = ReturnType<typeof useSetShipmentAsReadyToShipMutation>;
export type SetShipmentAsReadyToShipMutationResult = Apollo.MutationResult<TSetShipmentAsReadyToShipMutation>;
export type SetShipmentAsReadyToShipMutationOptions = Apollo.BaseMutationOptions<
  TSetShipmentAsReadyToShipMutation,
  TSetShipmentAsReadyToShipMutationVariables
>;
export const PrintShipmentLabelDocument = gql`
  mutation PrintShipmentLabel($id: ID!, $companyId: ID!, $version: Int!, $input: PrintShipmentLabelMutationInput!) {
    printShipmentLabel(id: $id, companyId: $companyId, _version: $version, input: $input) {
      ...ShipmentBasicFields
    }
  }
  ${ShipmentBasicFieldsFragmentDoc}
`;
export type TPrintShipmentLabelMutationFn = Apollo.MutationFunction<
  TPrintShipmentLabelMutation,
  TPrintShipmentLabelMutationVariables
>;

/**
 * __usePrintShipmentLabelMutation__
 *
 * To run a mutation, you first call `usePrintShipmentLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePrintShipmentLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [printShipmentLabelMutation, { data, loading, error }] = usePrintShipmentLabelMutation({
 *   variables: {
 *      id: // value for 'id'
 *      companyId: // value for 'companyId'
 *      version: // value for 'version'
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePrintShipmentLabelMutation(
  baseOptions?: Apollo.MutationHookOptions<TPrintShipmentLabelMutation, TPrintShipmentLabelMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TPrintShipmentLabelMutation, TPrintShipmentLabelMutationVariables>(
    PrintShipmentLabelDocument,
    options,
  );
}
export type PrintShipmentLabelMutationHookResult = ReturnType<typeof usePrintShipmentLabelMutation>;
export type PrintShipmentLabelMutationResult = Apollo.MutationResult<TPrintShipmentLabelMutation>;
export type PrintShipmentLabelMutationOptions = Apollo.BaseMutationOptions<
  TPrintShipmentLabelMutation,
  TPrintShipmentLabelMutationVariables
>;
export const SetShipmentAsShippedDocument = gql`
  mutation SetShipmentAsShipped($id: ID!, $companyId: ID!, $version: Int!) {
    setShipmentAsShipped(id: $id, companyId: $companyId, _version: $version) {
      ...ShipmentFields
    }
  }
  ${ShipmentFieldsFragmentDoc}
`;
export type TSetShipmentAsShippedMutationFn = Apollo.MutationFunction<
  TSetShipmentAsShippedMutation,
  TSetShipmentAsShippedMutationVariables
>;

/**
 * __useSetShipmentAsShippedMutation__
 *
 * To run a mutation, you first call `useSetShipmentAsShippedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetShipmentAsShippedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setShipmentAsShippedMutation, { data, loading, error }] = useSetShipmentAsShippedMutation({
 *   variables: {
 *      id: // value for 'id'
 *      companyId: // value for 'companyId'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useSetShipmentAsShippedMutation(
  baseOptions?: Apollo.MutationHookOptions<TSetShipmentAsShippedMutation, TSetShipmentAsShippedMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TSetShipmentAsShippedMutation, TSetShipmentAsShippedMutationVariables>(
    SetShipmentAsShippedDocument,
    options,
  );
}
export type SetShipmentAsShippedMutationHookResult = ReturnType<typeof useSetShipmentAsShippedMutation>;
export type SetShipmentAsShippedMutationResult = Apollo.MutationResult<TSetShipmentAsShippedMutation>;
export type SetShipmentAsShippedMutationOptions = Apollo.BaseMutationOptions<
  TSetShipmentAsShippedMutation,
  TSetShipmentAsShippedMutationVariables
>;
export const DeleteShipmentDocument = gql`
  mutation DeleteShipment($id: ID!, $companyId: ID!, $version: Int!) {
    deleteShipment(id: $id, companyId: $companyId, _version: $version) {
      ...ShipmentBasicFields
    }
  }
  ${ShipmentBasicFieldsFragmentDoc}
`;
export type TDeleteShipmentMutationFn = Apollo.MutationFunction<
  TDeleteShipmentMutation,
  TDeleteShipmentMutationVariables
>;

/**
 * __useDeleteShipmentMutation__
 *
 * To run a mutation, you first call `useDeleteShipmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteShipmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteShipmentMutation, { data, loading, error }] = useDeleteShipmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      companyId: // value for 'companyId'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useDeleteShipmentMutation(
  baseOptions?: Apollo.MutationHookOptions<TDeleteShipmentMutation, TDeleteShipmentMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TDeleteShipmentMutation, TDeleteShipmentMutationVariables>(DeleteShipmentDocument, options);
}
export type DeleteShipmentMutationHookResult = ReturnType<typeof useDeleteShipmentMutation>;
export type DeleteShipmentMutationResult = Apollo.MutationResult<TDeleteShipmentMutation>;
export type DeleteShipmentMutationOptions = Apollo.BaseMutationOptions<
  TDeleteShipmentMutation,
  TDeleteShipmentMutationVariables
>;
export const RecreateShipmentDocument = gql`
  mutation RecreateShipment($id: ID!, $companyId: ID!) {
    recreateShipment(id: $id, companyId: $companyId) {
      companyId
      items {
        ...BasicGiftFields
      }
    }
  }
  ${BasicGiftFieldsFragmentDoc}
`;
export type TRecreateShipmentMutationFn = Apollo.MutationFunction<
  TRecreateShipmentMutation,
  TRecreateShipmentMutationVariables
>;

/**
 * __useRecreateShipmentMutation__
 *
 * To run a mutation, you first call `useRecreateShipmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecreateShipmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recreateShipmentMutation, { data, loading, error }] = useRecreateShipmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useRecreateShipmentMutation(
  baseOptions?: Apollo.MutationHookOptions<TRecreateShipmentMutation, TRecreateShipmentMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TRecreateShipmentMutation, TRecreateShipmentMutationVariables>(
    RecreateShipmentDocument,
    options,
  );
}
export type RecreateShipmentMutationHookResult = ReturnType<typeof useRecreateShipmentMutation>;
export type RecreateShipmentMutationResult = Apollo.MutationResult<TRecreateShipmentMutation>;
export type RecreateShipmentMutationOptions = Apollo.BaseMutationOptions<
  TRecreateShipmentMutation,
  TRecreateShipmentMutationVariables
>;
export const ReshipShipmentDocument = gql`
  mutation ReshipShipment($id: ID!, $companyId: ID!, $version: Int!) {
    reshipShipment(id: $id, companyId: $companyId, _version: $version) {
      ...ShipmentBasicFields
    }
  }
  ${ShipmentBasicFieldsFragmentDoc}
`;
export type TReshipShipmentMutationFn = Apollo.MutationFunction<
  TReshipShipmentMutation,
  TReshipShipmentMutationVariables
>;

/**
 * __useReshipShipmentMutation__
 *
 * To run a mutation, you first call `useReshipShipmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReshipShipmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reshipShipmentMutation, { data, loading, error }] = useReshipShipmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      companyId: // value for 'companyId'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useReshipShipmentMutation(
  baseOptions?: Apollo.MutationHookOptions<TReshipShipmentMutation, TReshipShipmentMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TReshipShipmentMutation, TReshipShipmentMutationVariables>(ReshipShipmentDocument, options);
}
export type ReshipShipmentMutationHookResult = ReturnType<typeof useReshipShipmentMutation>;
export type ReshipShipmentMutationResult = Apollo.MutationResult<TReshipShipmentMutation>;
export type ReshipShipmentMutationOptions = Apollo.BaseMutationOptions<
  TReshipShipmentMutation,
  TReshipShipmentMutationVariables
>;
export const ResyncShipmentWithShipStationDocument = gql`
  mutation ResyncShipmentWithShipStation($id: ID!, $companyId: ID!, $version: Int!) {
    resyncShipmentWithShipStation(id: $id, companyId: $companyId, _version: $version) {
      ...ShipmentBasicFields
    }
  }
  ${ShipmentBasicFieldsFragmentDoc}
`;
export type TResyncShipmentWithShipStationMutationFn = Apollo.MutationFunction<
  TResyncShipmentWithShipStationMutation,
  TResyncShipmentWithShipStationMutationVariables
>;

/**
 * __useResyncShipmentWithShipStationMutation__
 *
 * To run a mutation, you first call `useResyncShipmentWithShipStationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResyncShipmentWithShipStationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resyncShipmentWithShipStationMutation, { data, loading, error }] = useResyncShipmentWithShipStationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      companyId: // value for 'companyId'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useResyncShipmentWithShipStationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TResyncShipmentWithShipStationMutation,
    TResyncShipmentWithShipStationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TResyncShipmentWithShipStationMutation, TResyncShipmentWithShipStationMutationVariables>(
    ResyncShipmentWithShipStationDocument,
    options,
  );
}
export type ResyncShipmentWithShipStationMutationHookResult = ReturnType<
  typeof useResyncShipmentWithShipStationMutation
>;
export type ResyncShipmentWithShipStationMutationResult = Apollo.MutationResult<TResyncShipmentWithShipStationMutation>;
export type ResyncShipmentWithShipStationMutationOptions = Apollo.BaseMutationOptions<
  TResyncShipmentWithShipStationMutation,
  TResyncShipmentWithShipStationMutationVariables
>;
export const OnAnyShipmentChangedDocument = gql`
  subscription OnAnyShipmentChanged {
    onAnyShipmentChanged {
      ...AnyShipmentChangedFields
    }
  }
  ${AnyShipmentChangedFieldsFragmentDoc}
`;

/**
 * __useOnAnyShipmentChangedSubscription__
 *
 * To run a query within a React component, call `useOnAnyShipmentChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnAnyShipmentChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnAnyShipmentChangedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnAnyShipmentChangedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    TOnAnyShipmentChangedSubscription,
    TOnAnyShipmentChangedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<TOnAnyShipmentChangedSubscription, TOnAnyShipmentChangedSubscriptionVariables>(
    OnAnyShipmentChangedDocument,
    options,
  );
}
export type OnAnyShipmentChangedSubscriptionHookResult = ReturnType<typeof useOnAnyShipmentChangedSubscription>;
export type OnAnyShipmentChangedSubscriptionResult = Apollo.SubscriptionResult<TOnAnyShipmentChangedSubscription>;
export const OnAnyShipmentRecreatedDocument = gql`
  subscription OnAnyShipmentRecreated {
    onAnyShipmentRecreated {
      companyId
      items {
        ...BasicGiftFields
      }
    }
  }
  ${BasicGiftFieldsFragmentDoc}
`;

/**
 * __useOnAnyShipmentRecreatedSubscription__
 *
 * To run a query within a React component, call `useOnAnyShipmentRecreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnAnyShipmentRecreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnAnyShipmentRecreatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnAnyShipmentRecreatedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    TOnAnyShipmentRecreatedSubscription,
    TOnAnyShipmentRecreatedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<TOnAnyShipmentRecreatedSubscription, TOnAnyShipmentRecreatedSubscriptionVariables>(
    OnAnyShipmentRecreatedDocument,
    options,
  );
}
export type OnAnyShipmentRecreatedSubscriptionHookResult = ReturnType<typeof useOnAnyShipmentRecreatedSubscription>;
export type OnAnyShipmentRecreatedSubscriptionResult = Apollo.SubscriptionResult<TOnAnyShipmentRecreatedSubscription>;
export const ListUsersDocument = gql`
  query ListUsers($companyId: ID!, $nextToken: String, $limit: Int, $order: OrderDirection) {
    listUsers(companyId: $companyId, nextToken: $nextToken, limit: $limit, order: $order) {
      nextToken
      items {
        ...UserFields
      }
    }
  }
  ${UserFieldsFragmentDoc}
`;

/**
 * __useListUsersQuery__
 *
 * To run a query within a React component, call `useListUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUsersQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      nextToken: // value for 'nextToken'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useListUsersQuery(baseOptions: Apollo.QueryHookOptions<TListUsersQuery, TListUsersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TListUsersQuery, TListUsersQueryVariables>(ListUsersDocument, options);
}
export function useListUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TListUsersQuery, TListUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TListUsersQuery, TListUsersQueryVariables>(ListUsersDocument, options);
}
export type ListUsersQueryHookResult = ReturnType<typeof useListUsersQuery>;
export type ListUsersLazyQueryHookResult = ReturnType<typeof useListUsersLazyQuery>;
export type ListUsersQueryResult = Apollo.QueryResult<TListUsersQuery, TListUsersQueryVariables>;
export const ListUsersIncludingCsUsersDocument = gql`
  query ListUsersIncludingCSUsers($companyId: ID!, $nextToken: String, $limit: Int, $order: OrderDirection) {
    listUsersIncludingCSUsers(companyId: $companyId, nextToken: $nextToken, limit: $limit, order: $order) {
      nextToken
      items {
        ...UserFields
      }
    }
  }
  ${UserFieldsFragmentDoc}
`;

/**
 * __useListUsersIncludingCsUsersQuery__
 *
 * To run a query within a React component, call `useListUsersIncludingCsUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUsersIncludingCsUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUsersIncludingCsUsersQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      nextToken: // value for 'nextToken'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useListUsersIncludingCsUsersQuery(
  baseOptions: Apollo.QueryHookOptions<TListUsersIncludingCsUsersQuery, TListUsersIncludingCsUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TListUsersIncludingCsUsersQuery, TListUsersIncludingCsUsersQueryVariables>(
    ListUsersIncludingCsUsersDocument,
    options,
  );
}
export function useListUsersIncludingCsUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TListUsersIncludingCsUsersQuery, TListUsersIncludingCsUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TListUsersIncludingCsUsersQuery, TListUsersIncludingCsUsersQueryVariables>(
    ListUsersIncludingCsUsersDocument,
    options,
  );
}
export type ListUsersIncludingCsUsersQueryHookResult = ReturnType<typeof useListUsersIncludingCsUsersQuery>;
export type ListUsersIncludingCsUsersLazyQueryHookResult = ReturnType<typeof useListUsersIncludingCsUsersLazyQuery>;
export type ListUsersIncludingCsUsersQueryResult = Apollo.QueryResult<
  TListUsersIncludingCsUsersQuery,
  TListUsersIncludingCsUsersQueryVariables
>;
export const CreateUserDocument = gql`
  mutation CreateUser($input: CreateUserMutationInput!) {
    createUser(input: $input) {
      ...UserFields
    }
  }
  ${UserFieldsFragmentDoc}
`;
export type TCreateUserMutationFn = Apollo.MutationFunction<TCreateUserMutation, TCreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<TCreateUserMutation, TCreateUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TCreateUserMutation, TCreateUserMutationVariables>(CreateUserDocument, options);
}
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<TCreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<TCreateUserMutation, TCreateUserMutationVariables>;
export const DeleteUserDocument = gql`
  mutation DeleteUser($email: AWSEmail!, $companyId: ID!, $version: Int!) {
    deleteUser(email: $email, companyId: $companyId, _version: $version) {
      ...UserFields
    }
  }
  ${UserFieldsFragmentDoc}
`;
export type TDeleteUserMutationFn = Apollo.MutationFunction<TDeleteUserMutation, TDeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      companyId: // value for 'companyId'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useDeleteUserMutation(
  baseOptions?: Apollo.MutationHookOptions<TDeleteUserMutation, TDeleteUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TDeleteUserMutation, TDeleteUserMutationVariables>(DeleteUserDocument, options);
}
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<TDeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<TDeleteUserMutation, TDeleteUserMutationVariables>;
export const ListAdminCognitoUsersDocument = gql`
  query ListAdminCognitoUsers($userPoolId: String!) {
    listAdminCognitoUsers(userPoolId: $userPoolId)
  }
`;

/**
 * __useListAdminCognitoUsersQuery__
 *
 * To run a query within a React component, call `useListAdminCognitoUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAdminCognitoUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAdminCognitoUsersQuery({
 *   variables: {
 *      userPoolId: // value for 'userPoolId'
 *   },
 * });
 */
export function useListAdminCognitoUsersQuery(
  baseOptions: Apollo.QueryHookOptions<TListAdminCognitoUsersQuery, TListAdminCognitoUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TListAdminCognitoUsersQuery, TListAdminCognitoUsersQueryVariables>(
    ListAdminCognitoUsersDocument,
    options,
  );
}
export function useListAdminCognitoUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TListAdminCognitoUsersQuery, TListAdminCognitoUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TListAdminCognitoUsersQuery, TListAdminCognitoUsersQueryVariables>(
    ListAdminCognitoUsersDocument,
    options,
  );
}
export type ListAdminCognitoUsersQueryHookResult = ReturnType<typeof useListAdminCognitoUsersQuery>;
export type ListAdminCognitoUsersLazyQueryHookResult = ReturnType<typeof useListAdminCognitoUsersLazyQuery>;
export type ListAdminCognitoUsersQueryResult = Apollo.QueryResult<
  TListAdminCognitoUsersQuery,
  TListAdminCognitoUsersQueryVariables
>;
export const OnCompanyUserChangedDocument = gql`
  subscription OnCompanyUserChanged($companyId: ID!) {
    onCompanyUserChanged(companyId: $companyId) {
      ...CompanyUserChangedFields
    }
  }
  ${CompanyUserChangedFieldsFragmentDoc}
`;

/**
 * __useOnCompanyUserChangedSubscription__
 *
 * To run a query within a React component, call `useOnCompanyUserChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnCompanyUserChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnCompanyUserChangedSubscription({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useOnCompanyUserChangedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    TOnCompanyUserChangedSubscription,
    TOnCompanyUserChangedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<TOnCompanyUserChangedSubscription, TOnCompanyUserChangedSubscriptionVariables>(
    OnCompanyUserChangedDocument,
    options,
  );
}
export type OnCompanyUserChangedSubscriptionHookResult = ReturnType<typeof useOnCompanyUserChangedSubscription>;
export type OnCompanyUserChangedSubscriptionResult = Apollo.SubscriptionResult<TOnCompanyUserChangedSubscription>;
