import { Icon, LoadingPage, Select, Switch, TextField, Tooltip } from '@chocolate-soup-inc/cs-frontend-components';
import { useEffect, useMemo, useState } from 'react';
import * as XLSX from 'xlsx';
import _ from 'lodash';

import styles from '../EmployeesImport.module.scss';
import { useFragmentOrFetchCompany } from '../../../../entities/companies/queries';
import clsx from 'clsx';
import { normalizeString } from '../../../../entities/shared/utils';
import { useQueryLatestRosterFileByCompanyId } from '../../../../entities/rosterFiles/queries';

const basicRecipientAttributes = {
  firstName: 'First Name',
  birthDate: 'Birth Date',
  foodPreferences: 'Allergies & Dietary Restrictions',
};

const basicPersonAttributes = {
  ...basicRecipientAttributes,
  preferredFirstName: 'Preferred First Name',
  lastName: 'Last Name',
};

const employeeAttributes = {
  ...basicPersonAttributes,
  externalId: 'External ID',
  officeId: 'Office',
  hireDate: 'Hire Date',
  email: 'Email',
  phoneNumber: 'Phone Number',
  title: 'Title',
  organization: 'Organization',
  tShirtSize: 'T-Shirt Size',
  sweaterSize: 'Sweater Size',
  donateBirthdayGift: 'Donate Birthday Gift',
  donateWorkAnniversaryGift: 'Donate Work Anniversary Gift',
  'address.address1': 'Address 1',
  'address.address2': 'Address 2',
  'address.city': 'City',
  'address.state': 'State',
  'address.country': 'Country',
  'address.zipCode': 'Postal Code',
};

const partnerAttributes = {
  ...basicPersonAttributes,
  donateBirthdayGift: 'Donate Birthday Gift',
};
const childAttributes = basicPersonAttributes;
const petAttributes = {
  ...basicRecipientAttributes,
  petType: 'Type',
};

type TMapping = Record<number, string | undefined>;
type THeaderMapping = Record<string, string | undefined>;

type TSecondStepProps = {
  companyId: string;
  file: File;
  mapping: TMapping;
  setMapping: React.Dispatch<React.SetStateAction<TMapping>>;
  setHeaderMapping: React.Dispatch<React.SetStateAction<THeaderMapping>>;
};

export const SecondStep = (props: TSecondStepProps) => {
  const { companyId, file, mapping, setMapping, setHeaderMapping } = props;

  const [headers, setHeaders] = useState<string[]>();
  const [numberOfPartners, setNumberOfPartners] = useState<number>(0);
  const [numberOfChildren, setNumberOfChildren] = useState<number>(0);
  const [numberOfPets, setNumberOfPets] = useState<number>(0);

  const { data: company } = useFragmentOrFetchCompany({
    id: companyId,
  });

  const { data: dataImportRoster } = useQueryLatestRosterFileByCompanyId(companyId);

  const lastSuccessfulImport = useMemo(() => {
    return [JSON.parse(dataImportRoster?.mapping ?? 'null'), JSON.parse(dataImportRoster?.headerMapping ?? 'null')];
  }, [dataImportRoster]);

  useEffect(() => {
    // Always update the headerMapping when mapping changes
    setHeaderMapping((prev) => {
      const newHeaderMapping = headers?.reduce((acc, header, index) => {
        const normalHeader = normalizeString(header);
        acc[normalHeader] = mapping[index];
        return acc;
      }, {} as THeaderMapping);
      return { ...prev, ...newHeaderMapping };
    });
  }, [mapping, headers, setHeaderMapping]);

  useEffect(() => {
    if (file) {
      file.arrayBuffer().then((data) => {
        const workbook = XLSX.read(data);
        const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
        const parsedData = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });

        setHeaders(parsedData[0] as string[]);
      });
    }
  }, [file]);

  const mappingOptions: Record<string, string> = useMemo(() => {
    let options = Object.entries(employeeAttributes).reduce((agg: Record<string, string>, [key, value]) => {
      agg[key] = `Employee ${value}`;
      return agg;
    }, {});

    for (let i = 0; i < numberOfPartners; i += 1) {
      options = {
        ...options,
        ...Object.entries(partnerAttributes).reduce((agg: Record<string, string>, [key, value]) => {
          agg[`partners.${i}.${key}`] = `Partner ${i + 1} ${value}`;
          return agg;
        }, {}),
      };
    }

    for (let i = 0; i < numberOfChildren; i += 1) {
      options = {
        ...options,
        ...Object.entries(childAttributes).reduce((agg: Record<string, string>, [key, value]) => {
          agg[`children.${i}.${key}`] = `Child ${i + 1} ${value}`;
          return agg;
        }, {}),
      };
    }

    for (let i = 0; i < numberOfPets; i += 1) {
      options = {
        ...options,
        ...Object.entries(petAttributes).reduce((agg: Record<string, string>, [key, value]) => {
          agg[`pets.${i}.${key}`] = `Pet ${i + 1} ${value}`;
          return agg;
        }, {}),
      };
    }

    return options;
  }, [numberOfChildren, numberOfPartners, numberOfPets]);

  const alternativesDictionary = useMemo(
    () => ({
      'external id': ['number', 'id'],
      'allergies dietary restrictions': ['food preferences', 'food allergies', 'food restrictions'],
      'birth date': ['date of birth', 'birthday'],
      'hire date': ['start date', 'hiring date', 'hired at'],
      'address 1': ['address', 'home address'],
      state: ['province'],
      'first name': ['name'],
      'donate birthday gift': ['charity', 'birthday charity', 'charity birthday'],
      'donate work anniversary gift': ['anniversary charity', 'charity anniversary'],
    }),
    [],
  );

  const mappingReadableHeaders: Record<string, string> = useMemo(
    () =>
      Object.entries(mappingOptions).reduce((acc: Record<string, string>, [key, value]) => {
        const normalValue = normalizeString(value);
        acc[normalValue] = key;
        // Add alternatives for easier matching with the Excel's column names
        for (const [header, alts] of Object.entries(alternativesDictionary)) {
          if (normalValue.includes(header)) {
            alts.map((alt) => {
              acc[normalValue.replaceAll(header, alt)] = key;
            });
          }
          // Strip the options and add variant without "employee " for easier matching with the Excel's column names
          if (normalValue.includes('employee ')) {
            const stripValue = normalValue.replaceAll('employee ', '');
            acc[stripValue] = key;
            if (stripValue.includes(header)) {
              alts.map((alt) => {
                acc[alt] = key;
              });
            }
          }
          // Check the first [any] dependant and add alternatives without the number
          if (normalValue.includes(' 1 ')) {
            const stripValue = normalValue.replaceAll(' 1 ', ' ');
            acc[stripValue] = key;
            if (stripValue.includes(header)) {
              alts.map((alt) => {
                acc[stripValue.replaceAll(header, alt)] = key;
              });
            }
          }
        }
        return acc;
      }, {}),
    [mappingOptions, alternativesDictionary],
  );

  const autoMappingHandler = (v: boolean | undefined) => {
    let newMapping = {};
    // Return early if switch is off
    if (!v) {
      setMapping(newMapping);
      return;
    }
    // Destruct the mappings
    const [lastImportMapping, lastImportHeaderMapping] = lastSuccessfulImport;
    // First write using the previous mapping
    if (lastImportMapping) {
      newMapping = { ...newMapping, ...lastImportMapping };
    }
    // Then overwrite any previous values using the dictionary
    if (mappingReadableHeaders) {
      const existingMappedValues = headers?.map((header) => {
        const normalHeader = normalizeString(header);
        return mappingReadableHeaders[normalHeader];
      });
      newMapping = { ...newMapping, ...existingMappedValues };
    }
    // Last overwrite any previous values using the dictionary
    if (lastImportHeaderMapping) {
      const existingMappedValues = headers?.reduce((acc, header, index) => {
        const normalHeader = normalizeString(header);
        acc[index] = lastImportHeaderMapping[normalHeader];
        return acc;
      }, {} as TMapping);
      newMapping = { ...newMapping, ...existingMappedValues };
    }
    // We'll have to check this thoroughly but overall works as intended
    // We do it this way due to the under-the-hood asynchronous nature of the state setters
    setMapping((prev) => ({ ...prev, ...newMapping }));
  };

  if (headers == null) return <LoadingPage />;

  return (
    <div className={styles.secondStep}>
      {(company?.significantOtherBirthdayActivated ||
        company?.childBirthdayActivated ||
        company?.petBirthdayActivated) && (
        <>
          <h2 className={styles.subTitle}>Relationship Settings</h2>
          <p className={styles.paragraph}>
            Set the number of partners, children and pets present on the roster being imported.
          </p>
          <div className={styles.mappingSettingsContainer}>
            {company?.significantOtherBirthdayActivated && (
              <TextField
                clearDisabled={true}
                label='Partners'
                name='numberOfPartners'
                type='number'
                multiline={false}
                onChange={(v) => {
                  const intValue = v ? parseInt(v) : 0;
                  setNumberOfPartners(intValue >= 0 ? intValue : 0);
                }}
                value={numberOfPartners?.toString()}
              />
            )}
            {company?.childBirthdayActivated && (
              <TextField
                clearDisabled={true}
                label='Children'
                name='numberOfChildren'
                type='number'
                multiline={false}
                onChange={(v) => {
                  const intValue = v ? parseInt(v) : 0;
                  setNumberOfChildren(intValue >= 0 ? intValue : 0);
                }}
                value={numberOfChildren?.toString()}
              />
            )}
            {company?.petBirthdayActivated && (
              <TextField
                clearDisabled={true}
                label='Pets'
                name='numberOfPets'
                type='number'
                multiline={false}
                onChange={(v) => {
                  const intValue = v ? parseInt(v) : 0;
                  setNumberOfPets(intValue >= 0 ? intValue : 0);
                }}
                value={numberOfPets?.toString()}
              />
            )}
          </div>
        </>
      )}
      <h2 className={styles.subTitle}>Mappings</h2>
      <div>
        <div className={clsx(styles.formToggle)}>
          <Tooltip message={'If the import file has an invalid column header, please manually select the option'}>
            <Icon icon='help' />
          </Tooltip>
          <div className={clsx(styles.flexGrow)}>
            <Switch name='autoMapImport' label='Auto Map Columns' onChange={autoMappingHandler} />
          </div>
        </div>
        {headers.map((header, index) => {
          return (
            <div className={styles.headerMappingRow} key={index}>
              <span className={styles.headerMappingRowText}>
                Column {index + 1}: {header}
              </span>
              <Icon icon='arrow_forward' />
              <Select
                autoComplete='off'
                includeEmptyOption={true}
                name={`mapping[${index}]`}
                multiple={false}
                onChange={(v) => {
                  setMapping((currentMapping) => {
                    if (v == null) {
                      delete currentMapping[index];
                    } else {
                      currentMapping[index] = v as string;
                    }
                    return _.cloneDeep(currentMapping);
                  });
                }}
                options={Object.entries(mappingOptions)
                  .filter(([k]) => {
                    if (mapping[index] === k) return true;
                    return !Object.values(mapping).includes(k);
                  })
                  .map(([k, v]) => ({
                    label: v,
                    value: k,
                  }))}
                variant='outlined'
                value={mapping[index] ?? null}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
