import Joi from 'joi';

export const normalizeString = (str: string) => {
  return str
    .replace(/[^a-zA-Z0-9 ]/g, '')
    .replace(/\s+/g, ' ')
    .trim()
    .toLowerCase();
};

export const AWSDateSchema = Joi.date()
  .empty(Joi.valid(null, ''))
  .custom((value, helpers) => {
    if (value != null) {
      if (value instanceof Date) {
        return value.toISOString().split('T')[0];
      } else {
        return helpers.error('awsDate.notDate');
      }
    }

    return value;
  })
  .messages({
    'awsDate.notDate': 'The {{#label}} is not a valid date.',
  });

export const AWSDateTimeSchema = Joi.date()
  .empty(Joi.valid(null, ''))
  .custom((value, helpers) => {
    if (value != null) {
      if (value instanceof Date) {
        return value.toISOString();
      } else {
        return helpers.error('awsDate.notDate');
      }
    }

    return value;
  })
  .messages({
    'awsDate.notDate': 'The {{#label}} is not a valid date.',
  });
