import {
  ErrorPage,
  IconButton,
  InfoCardInner,
  Link,
  LoadingPage,
  Switch,
  TInfoCardSectionColumnProps,
  Tooltip,
} from '@chocolate-soup-inc/cs-frontend-components';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { TGetCompanyQuery, TWorkAnniversaryGiftVariations } from '../../../generated/graphql';
import { CompanyModal } from '../CompanyModal/CompanyModal';
import styles from './CompanyDetails.module.scss';
import { useCallback, useEffect, useMemo } from 'react';
import { EDIT_PATH } from '../../../routes/paths';
import { useFragmentOrFetchCompany } from '../../../entities/companies/queries';
import { useFilter } from '../../../contexts/filters';

type TCompany = Exclude<TGetCompanyQuery['getCompany'], undefined | null>;

export const CompanyDetails = () => {
  const { filtersModalMode } = useFilter();
  useEffect(() => {
    filtersModalMode();
  }, []); // eslint-disable-line

  const navigate = useNavigate();
  const { companyId } = useParams();

  const {
    data: company,
    loading,
    error,
  } = useFragmentOrFetchCompany({
    id: companyId as string,
  });

  const giftConfigurationColumns = useMemo(() => {
    const cols: TInfoCardSectionColumnProps<TCompany>[] = [
      {
        label: 'Employee Birthday',
        render: (data) => {
          return <Switch name='employeeBirthdayActivated' readOnly={true} value={data?.employeeBirthdayActivated} />;
        },
      },
      {
        label: 'Employee Work Anniversary',
        render: (data) => {
          return (
            <Switch
              name='employeeWorkAnniversaryActivated'
              readOnly={true}
              value={data?.employeeWorkAnniversaryActivated}
            />
          );
        },
      },
      {
        label: 'Employee New Hire Kit',
        render: (data) => {
          return (
            <Switch name='employeeNewHireKitActivated' readOnly={true} value={data?.employeeNewHireKitActivated} />
          );
        },
      },
      {
        label: 'Significant Other Birthday',
        render: (data) => {
          return (
            <Switch
              name='significantOtherBirthdayActivated'
              readOnly={true}
              value={data?.significantOtherBirthdayActivated}
            />
          );
        },
      },
      {
        label: 'Child Birthday',
        render: (data) => {
          return <Switch name='childBirthdayActivated' readOnly={true} value={data?.childBirthdayActivated} />;
        },
      },
      {
        label: 'Pet Birthday',
        render: (data) => {
          return <Switch name='petBirthdayActivated' readOnly={true} value={data?.petBirthdayActivated} />;
        },
      },
    ];

    if (company?.employeeNewHireKitActivated) {
      cols.push({
        label: 'Custom Onboarding Brick',
        render: (data) => {
          return (
            <Switch name='hasCustomOnboardBrick' readOnly={true} value={data?.hasCustomOnboardBrick || undefined} />
          );
        },
      });
    }

    if (company?.employeeWorkAnniversaryActivated) {
      cols.push({
        label: 'Work Anniversary Variation',
        render: (data) => {
          return data?.workAnniversaryGiftVariation;
        },
      });
    }

    if (company?.specialNote) {
      cols.push({
        label: 'Gift Special Notes',
        render: (data) => {
          return data?.specialNote;
        },
      });
    }

    if (company?.swag) {
      cols.push({
        label: 'New Hire Notes',
        render: (data) => {
          return data?.swag;
        },
      });
    }

    if (
      company?.employeeWorkAnniversaryActivated &&
      company.workAnniversaryGiftVariation === TWorkAnniversaryGiftVariations.Custom
    ) {
      cols.push({
        label: 'Custom Work Anniversary Notes',
        render: (data) => data?.customWorkAnniversaryNotes,
      });
    }

    return cols;
  }, [
    company?.employeeNewHireKitActivated,
    company?.employeeWorkAnniversaryActivated,
    company?.workAnniversaryGiftVariation,
    company?.swag,
    company?.specialNote,
  ]);

  const goToEditCompany = useCallback(() => {
    navigate(generatePath(`../${EDIT_PATH}`));
  }, [navigate]);

  const topComponent = useMemo(() => {
    if (company != null) {
      return (
        <div className={styles.topComponent}>
          <div className={styles.topTitleContainer}>
            <h2 className={styles.topTitle}>{company.name}</h2>
            <div className={styles.topIcons}>
              <Tooltip message='Edit Company'>
                <IconButton
                  disabled={!company?.fullyConfigured}
                  icon='edit'
                  onClick={goToEditCompany}
                  variant='outlined'
                />
              </Tooltip>
            </div>
          </div>
        </div>
      );
    }
  }, [company, goToEditCompany]);

  if (error) return <ErrorPage error={error} />;

  return (
    <CompanyModal headline='Company Details'>
      {(loading || !company) && <LoadingPage />}
      {!loading && company && (
        <InfoCardInner<TCompany>
          data={company}
          sections={[
            {
              topComponent: () => topComponent,
              columns: [
                {
                  label: 'Delivery Method',
                  accessor: 'deliveryMethod',
                },
                {
                  label: 'Subdomain',
                  accessor: 'subdomain',
                },
                {
                  label: 'Full Domain',
                  render: (data) => {
                    if (company.fullyConfigured) {
                      const url = `https://${data?.subdomain}.${
                        process.env.REACT_APP_BASE_DOMAIN || 'chocolatesoup.ca'
                      }`;

                      return <Link href={url} label={url} />;
                    } else {
                      return <span>Infrastructure configuring...</span>;
                    }
                  },
                },
                {
                  label: 'Email Domains',
                  render: (data) => {
                    return (
                      <div className={styles.emailDomains}>
                        {data?.emailDomains?.map((emailDomain) => {
                          return <span key={emailDomain}>{emailDomain}</span>;
                        })}
                      </div>
                    );
                  },
                },
                {
                  label: 'Google Login Enabled',
                  render: (data) => {
                    return data?.googleLoginEnabled ? 'Enabled' : 'Disabled';
                  },
                },
                {
                  label: 'Lego Color',
                  accessor: 'legoColor',
                },
                {
                  label: 'Tissue Color',
                  accessor: 'tissueColor',
                },
              ],
            },
            {
              title: 'Gift Configuration',
              columns: giftConfigurationColumns,
            },
            {
              title: 'Contact Information',
              columns: [
                { label: 'Contact Name', render: () => company.contact?.name },
                { label: 'Contact Email', render: () => company.contact?.email },
                { label: 'Contact Phone Number', render: () => company.contact?.phoneNumber },
              ],
            },
            ...(company.address
              ? [
                  {
                    title: 'Address',
                    columns: [
                      {
                        label: 'Address 1',
                        render: () => company.address?.address1,
                      },
                      {
                        label: 'Address 2',
                        render: () => company.address?.address2,
                      },
                      {
                        label: 'City',
                        render: () => company.address?.city,
                      },
                      {
                        label: 'State',
                        render: () => company.address?.state,
                      },
                      {
                        label: 'Country',
                        render: () => company.address?.country,
                      },
                      {
                        label: 'Postal Code',
                        render: () => company.address?.zipCode,
                      },
                    ],
                  },
                ]
              : []),
            {
              title: 'Billing',
              columns: [
                {
                  label: 'Subscription Price per User',
                  accessor: 'subscriptionPricePerUser',
                },
                {
                  label: 'Subscription Price Currency',
                  accessor: 'subscriptionPriceCurrency',
                },
              ],
            },
          ]}
        />
      )}
    </CompanyModal>
  );
};
