import { TMaterialIcons } from '@chocolate-soup-inc/cs-frontend-components';
import { TAddressParentTypes, TGiftFieldsFragment } from '../../generated/graphql';
import { TGiftType } from '../gifts/queries';

export const getReadableAddressParentType = (addressType?: TAddressParentTypes) => {
  if (addressType == null) return 'Deleted';

  switch (addressType) {
    case TAddressParentTypes.Company:
    case TAddressParentTypes.Office:
      return 'Office';
    case TAddressParentTypes.Employee:
      return 'Home';
    default:
      return undefined;
  }
};

export const getReadableDeliveryMethodType = (gift: TGiftType<TGiftFieldsFragment>) => {
  const addressType = gift?.address?.parentType;
  if (addressType == null) return 'Deleted';

  switch (addressType) {
    case TAddressParentTypes.Company:
      return 'Main Office';
    case TAddressParentTypes.Office:
      return gift?.employee?.office ? gift?.employee?.office?.name : 'Office';
    case TAddressParentTypes.Employee:
      return 'Home';
    default:
      return '';
  }
};

export const getReadableAddressProperty = (gift: any, key: string) => {
  const address = gift?.address;
  if (!address || !key) return 'Not found.';
  return address.useGoogleAddress ? address?.addressFromGoogle[key] : address[key];
};

export const getIconFromAddressParentType = (addressType?: TAddressParentTypes): TMaterialIcons | undefined => {
  const readableType = getReadableAddressParentType(addressType);
  if (readableType == null) return undefined;

  if (readableType === 'Home') {
    return 'home';
  } else if (readableType === 'Office') {
    return 'domain';
  } else {
    return 'close';
  }
};
