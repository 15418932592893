import { Modal } from '@chocolate-soup-inc/cs-frontend-components';
import { useCallback, useMemo, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { EMPLOYEES_PATH, IMPORT_LIST_PATH } from '../../../routes/paths';
import { FirstStep } from './steps/FirstStep';
import { SecondStep } from './steps/SecondStep';
import { ThirdStep } from './steps/ThirdStep';
import { Storage } from '@chocolate-soup-inc/cs-api-consumer-utils';
import { ReviewStep } from './steps/ReviewStep';
import _ from 'lodash';
import { serializeError } from 'serialize-error';
import { toast } from 'react-toastify';
import { useCreateRosterFileMutation } from '../../../generated/graphql';

// import styles from './EmployeesImport.module.scss';

// const testMapping = {
//   0: 'externalId',
//   1: 'address.address1',
//   2: 'address.city',
//   3: 'address.state',
//   4: 'address.zipCode',
//   5: 'address.country',
//   6: 'firstName',
//   7: 'lastName',
//   9: 'hireDate',
//   10: 'birthDate',
//   11: 'foodPreferences',
//   12: 'partners.0.firstName',
//   13: 'partners.0.lastName',
//   15: 'partners.0.birthDate',
//   16: 'partners.0.foodPreferences',
//   17: 'children.0.firstName',
//   18: 'children.0.lastName',
//   20: 'children.0.birthDate',
//   21: 'children.0.foodPreferences',
//   22: 'children.1.firstName',
//   23: 'children.1.lastName',
//   25: 'children.1.birthDate',
//   26: 'children.1.foodPreferences',
//   27: 'children.2.firstName',
//   28: 'children.2.lastName',
//   30: 'children.2.birthDate',
//   31: 'children.2.foodPreferences',
//   32: 'children.3.firstName',
//   33: 'children.3.lastName',
//   35: 'children.3.birthDate',
//   36: 'children.3.foodPreferences',
//   37: 'children.4.firstName',
//   38: 'children.4.lastName',
//   40: 'children.4.birthDate',
//   41: 'children.4.foodPreferences',
//   42: 'children.5.firstName',
//   43: 'children.5.lastName',
//   45: 'children.5.birthDate',
//   46: 'children.5.foodPreferences',
//   47: 'pets.0.firstName',
//   48: 'pets.0.birthDate',
//   49: 'pets.0.petType',
//   50: 'pets.1.firstName',
//   51: 'pets.1.birthDate',
//   52: 'pets.1.petType',
//   53: 'pets.2.firstName',
//   54: 'pets.2.birthDate',
//   55: 'pets.2.petType',
//   56: 'pets.3.firstName',
//   57: 'pets.3.birthDate',
//   58: 'pets.3.petType',
// };

export type TStep = 1 | 2 | 3 | 4;

export const EmployeesImport = () => {
  const navigate = useNavigate();

  const [step, setStep] = useState<TStep>(1);
  const [file, setFile] = useState<File>();
  const [companyId, setCompanyId] = useState<string | undefined>();
  const [mapping, setMapping] = useState<Record<number, string | undefined>>({});
  const [headerMapping, setHeaderMapping] = useState<Record<string, string | undefined>>({});
  const [officeMapping, setOfficeMapping] = useState<Record<any, string>>({});
  const [emptyImport, setEmptyImport] = useState<boolean>(false);
  const [changelog, setChangelog] = useState<Record<string, any>>({});

  const nextDisabled = useMemo(() => {
    if (step === 1) {
      return companyId == null || file == null;
    } else if (step === 2) {
      return mapping == null || Object.keys(mapping).length < 1;
    } else if (step === 3) {
      return officeMapping == null || Object.keys(officeMapping).length < 1;
    }
  }, [companyId, file, mapping, officeMapping, step]);

  const onNextClick = useCallback(() => {
    if (step === 1) {
      setStep(2);
    } else if (step === 2) {
      if (Object.values(mapping).includes('officeId')) {
        setStep(3);
      } else {
        setStep(4);
      }
    } else if (step === 3) {
      setStep(4);
    }
  }, [mapping, step]);

  const onBackClick = useCallback(() => {
    if (step === 4) {
      if (Object.values(mapping).includes('officeId')) {
        setStep(3);
      } else {
        setHeaderMapping({});
        setMapping({});
        setStep(2);
      }
    } else if (step === 3) {
      setHeaderMapping({});
      setMapping({});
      setStep(2);
    } else if (step === 2) {
      setStep(1);
    }
  }, [mapping, step]);

  const [importLoading, setImportLoading] = useState<boolean>(false);

  const [createRosterFile, { loading: createRosterFileLoading }] = useCreateRosterFileMutation();

  const onImportClick = useCallback(() => {
    if (
      companyId != null &&
      mapping != null &&
      _.compact(Object.values(mapping)).length > 0 &&
      file != null &&
      changelog != null
    ) {
      setImportLoading(true);
      const date = new Date().toISOString();

      Storage.put(`${companyId}/${date}/${file.name}`, file, {
        bucket: process.env.REACT_APP_IMPORT_EMPLOYEES_ROSTERS_BUCKET_NAME,
        contentType: file.type,
      })
        .then((result) => {
          const jsonBlob = new Blob([JSON.stringify(changelog)], { type: 'application/json' });
          Storage.put(`${companyId}/${date}/changelog.json`, jsonBlob, {
            bucket: process.env.REACT_APP_IMPORT_EMPLOYEES_ROSTERS_BUCKET_NAME,
            contentType: 'application/json',
          })
            .then((changelog) => {
              const createRosterInput = {
                companyId,
                mapping: JSON.stringify(_.pickBy(mapping, _.identity)),
                headerMapping: JSON.stringify(_.pickBy(headerMapping, _.identity)),
                officeMapping: JSON.stringify(officeMapping),
                changelogFile: {
                  bucket: process.env.REACT_APP_IMPORT_EMPLOYEES_ROSTERS_BUCKET_NAME as string,
                  region: process.env.REACT_APP_REGION as string,
                  key: changelog.key,
                },
                file: {
                  bucket: process.env.REACT_APP_IMPORT_EMPLOYEES_ROSTERS_BUCKET_NAME as string,
                  region: process.env.REACT_APP_REGION as string,
                  key: result.key,
                },
              };

              createRosterFile({
                variables: {
                  input: createRosterInput,
                },
              })
                .then(() => {
                  navigate(generatePath(`${EMPLOYEES_PATH}/${IMPORT_LIST_PATH}`));
                })
                .catch((error) => {
                  console.error(serializeError(error));
                  toast.error('There was an error saving the file to the backend.');
                });
            })
            .catch((error) => {
              console.error(serializeError(error));
              toast.error('There was an error saving the changelog file to the backend.');
            });
        })
        .catch((error) => {
          console.error(serializeError(error));
          toast.error('There was an error uploading the file.');
        })
        .finally(() => {
          setImportLoading(false);
        });
    }
  }, [companyId, createRosterFile, file, mapping, headerMapping, navigate, officeMapping, changelog]);

  const size = useMemo(() => {
    if (step > 1) return 'large';

    return 'small';
  }, [step]);

  const closeModal = useCallback(() => {
    navigate(generatePath(EMPLOYEES_PATH));
  }, [navigate]);

  return (
    <Modal
      cancelLabel='Back'
      closeModal={closeModal}
      confirmDisabled={step < 4 ? nextDisabled : emptyImport}
      confirmLabel={step < 4 ? 'Next' : 'Import'}
      confirmLoading={importLoading || createRosterFileLoading}
      headline='Import Employees'
      onCancelClick={onBackClick}
      onConfirmClick={step < 4 ? onNextClick : onImportClick}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      showCancelButton={step > 1}
      showConfirmButton={true}
      size={size}
    >
      {step === 1 && <FirstStep companyId={companyId} file={file} setCompanyId={setCompanyId} setFile={setFile} />}
      {step === 2 && companyId && file && (
        <SecondStep
          companyId={companyId}
          file={file}
          mapping={mapping}
          setMapping={setMapping}
          setHeaderMapping={setHeaderMapping}
        />
      )}
      {step === 3 && companyId && file && (
        <ThirdStep
          companyId={companyId}
          file={file}
          mapping={mapping}
          officeMapping={officeMapping}
          setOfficeMapping={setOfficeMapping}
        />
      )}
      {step === 4 && companyId && file && (
        <ReviewStep
          companyId={companyId}
          file={file}
          mapping={mapping}
          officeMapping={officeMapping}
          setEmptyImport={setEmptyImport}
          setChangelog={setChangelog}
        />
      )}
    </Modal>
  );
};
